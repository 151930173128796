import $http from "./index";
import qs from "qs";

import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;

///积分商城
export const ListPointsMall = (data) => {
    return $http.get(
        `${base}/app/business/pointsmall/back/pagelist`,
        {
            params: data,
            timeout: 100000,
        }
    );
};
//新增
export const CreatePointsMall = (data) => {
    return $http.post(
        `${base}/app/business/pointsmall/back/new`,
        data
    );
};
///修改
export const updatePointsMall = (data) => {
    return $http.post(
        `${base}/app/business/pointsmall/back/update`,
        data
    );
};
//根据id 获取一条商品的信息
export const PointsMallById = (id) => {
    return $http.get(
        `${base}/app/business/pointsmall/back/detail?id=`+id,
        {
            timeout: 1000,
        }
    );
};

//根据id 删除一条商品分类的信息
export const DeltePointMall = (data) => {
    return $http.get(
        `${base}/app/business/pointsmall/back/delete`,
        {
            params: data,
            timeout: 1000,
        }
    );
};

//获取积分分类表名字
export const GetAllListAsync = (data) => {
    return $http.get(
        `${base}/app/business/pointsexchangetype/getall`,
        {
            params: data,
            timeout: 100000,
        }
    );
};

//多张图片新增（详情）
export const setPointsMallPic = (data) => {
    return $http.post(
        `${base}/app/business/pointsmall/back/setdescpic`,
        data
    );
};

//获取详情页图片
export const getPointImg = (data) => {
    return $http.get(
        `${base}/app/business/pointsmall/back/getdetailimg`,
        {
            params: data,
            timeout: 1000,
        }
    );
};

//获取兑换明细页面
export const ListExchangeDetails = (data) => {
    return $http.get(
        `${base}/app/business/exchangedetails/back/pagelist`,
        {
            params: data,
            timeout: 100000,
        }
    );
};
//查询优惠券
export const getCouponsRule = (data) => {
    return $http.post(
        `${base}/app/info/crmInterface/getCouponsRule`,
        data
    );
};
export const ExchangeLength = () => {
    return $http.get(
        `${base}/app/business/exchangedetails/back/GetExchangeLength`,
        {
            timeout: 1000,
        }
    );
    };