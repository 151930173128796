<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/TaskManage' }">返回</el-breadcrumb-item>
        <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <div>
        <!-- 1.筛选条件 -->
        <div class="select-box">
<!--            <div>-->
<!--                <span>商品名称:</span>-->
<!--                <el-input-->
<!--                        v-model="option.pointsMall"-->
<!--                        clearable-->
<!--                        placeholder="请输入商品名称"-->
<!--                ></el-input>-->
<!--            </div>-->
            <!--<div>
                <span>会员名称:</span>
                <el-input
                        v-model="option.redemptionName"
                        clearable
                        placeholder="请输入会员名称"
                ></el-input>
            </div>-->
<!--            <div>-->
<!--                <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>-->
<!--            </div>-->
        </div>
        <!--2. table]  -->
        <el-table border stripe :data="data.table" style="width:100%">
            <el-table-column
                    type="index" :index="indexMethod"
                    width="50" style="text-align: center;">
            </el-table-column>
            <el-table-column prop="memberId" label="会员ID"  > </el-table-column>
            <el-table-column prop="collectionTime" label="领取时间" :formatter="formatCollectionTime" > </el-table-column>
            <el-table-column  prop="points" label="积分"> </el-table-column>
            <el-table-column prop="coupons" label="优惠券(张)" :formatter="formatCoupons"></el-table-column>
        </el-table>
        <!-- 3.分页 -->
        <el-pagination
                class="fenye"
                @current-change="handleCurrentChange"
                :current-page="option.page"
                :page-size="10"
                prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
                next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
                background
                layout="total, prev, pager, next, jumper"
                :total="data.total"
        >
        </el-pagination>
    </div>
</template>

<!-- 本页的js -->
<script>
    import { ref, reactive, onMounted } from "vue";
    import { getTaskCollectionList} from "../http/api";
    import {FullTimeFormat} from "../utils/common";
    import {useRoute,useRouter} from "vue-router";

    export default {
        methods: {
            formatCollectionTime(row) {
                return FullTimeFormat(row.collectionTime);
            },
            formatCoupons(row) {
                if(row.coupons != null && row.coupons != ""){
                    if(row.coupons.indexOf(",") > 0){
                        var cnt  = row.coupons.split(",").length;
                        return cnt;
                    } else {
                        return 1;
                    }
                } else {
                    return 0;
                }
            },
        },
        name: "TaskCollectionDetails",
        setup() {
            const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的

            const route = useRoute()
            const router = useRouter()
            //params
            let taskId=route.params.id;
            onMounted(async () => { // 在onMounted生命周期钩子中调用GetAllListAsync方法
                if(!taskId){
                    router.push({name:"TaskManage"})
                }
            });
            //声明数据table表格的内容//
            let data = reactive({
                table: [],
                pages: 0,
                total: 0,
                ListData:[],
            });

            //声明查询条件
            let option = reactive({
                taskId: taskId,
                page: 1,
                pageSize: 10,
            });
            let indexMethod =(index) => {
                return index+(option.page-1)*option.pageSize+1;
            }
            let getTaskCollectionListFun = async (obj) => {
                let arr = await getTaskCollectionList(obj);
                data.total = arr.totalCount;
                data.table = arr.items;
            };
            //初始化时，获取初始化数据
            getTaskCollectionListFun({ Page: 1, PageSize: 10 ,taskId: taskId});

            //点击页码获取数据
            let handleCurrentChange = (index) => {
                option.page = index;
                getTaskCollectionListFun(option);
            };
            //查询方法,此处需要根据条件到后台进行筛选
            let getTabList = async () => {
                await getTaskCollectionListFun(option);
            };

            return {
                data,
                handleCurrentChange,
                getTabList,
                option,
                ruleFormRef,
                indexMethod
            };
        },
    };
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
    .select-box {
        display: flex;
        align-items: center;
        width: 120%;
        margin-bottom: 25px;
        > div {
            width: 15%;
            display: flex;
            align-items: center;
            span {
                width: 110px;
            }
            margin-right: 25px;
        }
    }
    .fenye {
        margin-top: 20px;
    }
</style>
