<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/BannerManagement' }">广告管理</el-breadcrumb-item>
        <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <div>
        <!-- 1.筛选条件 -->
        <div class="select-box">
            <!--<div>
                <span>用户名称:</span>
                <el-input v-model="option.userName" clearable placeholder="请输入用户名称"></el-input>
            </div>-->
            <div>
                <span>推荐人会员编号:</span>
                <el-input v-model="option.referenceMemberId" clearable placeholder="请输入推荐人会员编号"></el-input>
            </div>
            <div>
                <span>好友手机号:</span>
                <el-input v-model="option.friendPhone" clearable placeholder="请输入好友手机号"></el-input>
            </div>
            <div>
                <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
            </div>
        </div>
        <!--2. table]  -->
        <el-table border stripe :data="data.table" style="width:100%">
            <el-table-column
                    type="index" :index="indexMethod"
                    width="50" style="text-align: center;">
            </el-table-column>
            <el-table-column prop="referenceMemberId" label="推荐人会员编号"> </el-table-column>
            <el-table-column  prop="referenceName" label="推荐人微信用户名称"> </el-table-column>
            <el-table-column prop="recommendType" label="类型">
              <template v-slot:default="scope">
                <span v-if="scope.row.recommendType === '0'">邀请注册</span>
                <span v-else>首单消费</span>
              </template>
            </el-table-column>
            <el-table-column label="推荐人获取积分">
                    <template #default="scope">
                        <span>{{(scope.row["referenceCoupon"] != null && scope.row["referenceCoupon"].trim().length > 0) ? null:scope.row["referencePoints"]}}</span>
                    </template>
            </el-table-column>
            <el-table-column  prop="referenceCoupon" label="推荐人获取优惠券"> </el-table-column>
            <el-table-column prop="friendMemberId" label="好友会员编号"> </el-table-column>
            <el-table-column  prop="friendName" label="好友微信用户名称"> </el-table-column>
            <el-table-column prop="friendPhone" label="好友手机号"> </el-table-column>
            <el-table-column prop="creationTime" label="推荐时间" :formatter="formatCreationTime" width="160"></el-table-column>
        </el-table>
        <!-- 3.分页 -->
        <el-pagination
                class="fenye"
                @current-change="handleCurrentChange"
                :current-page="option.page"
                :page-size="10"
                prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
                next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
                background
                layout="total, prev, pager, next, jumper"
                :total="data.total"
        >
        </el-pagination>
    </div>
</template>

<!-- 本页的js -->
<script>
    import { ref, reactive, onMounted } from "vue";
    import {FullTimeFormat} from "../utils/common";
    import {useRoute,useRouter} from "vue-router";
    import {getRecommendFriendList} from "../http/bannermanagement";
    export default {
        methods: {
            formatCreationTime(row) {
                return FullTimeFormat(row.creationTime);
            },
        },
        name: "RecommendFriend",
        setup() {
            const route = useRoute()
            const router = useRouter()
            //params
            let bannerManagementId=route.params.bannerManagementId;
            onMounted(async () => { // 在onMounted生命周期钩子中调用GetAllListAsync方法
                if(bannerManagementId == null || bannerManagementId.trim().length == 0){
                    router.push({name:"BannerManagement"})
                }
            });
            //声明数据table表格的内容//
            let data = reactive({
                table: [],
                pages: 0,
                total: 0,
                ListData:[]
            });
            //声明查询条件
            let option = reactive({
                /*userName: "",*/
                referenceMemberId:"",
                friendPhone:"",
                bannerManagementId: bannerManagementId,
                page: 1,
                pageSize: 10,
            });
            let indexMethod =(index) => {
                return index+(option.page-1)*option.pageSize+1;
            }
            let getRecommendFriendListFun = async (obj) => {
                let arr = await getRecommendFriendList(obj);
                data.total = arr.totalCount;
                data.table = arr.items;
            };
            //初始化时，获取初始化数据
            getRecommendFriendListFun({ Page: 1, PageSize: 10 ,bannerManagementId: bannerManagementId});

            //点击页码获取数据
            let handleCurrentChange = (index) => {
                option.page = index;
                getRecommendFriendListFun(option);
            };
            //查询方法,此处需要根据条件到后台进行筛选
            let getTabList = async () => {
                await getRecommendFriendListFun(option);
            };
            return {
                data,
                handleCurrentChange,
                getTabList,
                option,
                indexMethod
            };
        },
    };
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
    .select-box {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;
        > div {
            width: 30%;
            display: flex;
            align-items: center;
            span {
                width: 170px;
            }
            margin-right: 25px;
        }
    }
    .fenye {
        margin-top: 20px;
    }
</style>
