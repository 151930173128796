<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span style="width:180px">总任务名称:</span>
        <el-input v-model="option.totalTaskName"   clearable placeholder="搜索名称" />
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">新增总任务</el-button>
<!--        <el-button icon="el-icon-document-add" @click="testClick()" type="primary">测试CRM111</el-button>-->
      </div>
    </div>

    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column type="index" :index="indexMethod"   width="50" style="text-align: center;"></el-table-column>
      <el-table-column prop="totalTaskName" label="任务名称"> </el-table-column>
      <el-table-column prop="isActive" label="状态"> </el-table-column>
      <el-table-column prop="taskStartDate" label="活动开始时间"> </el-table-column>
      <el-table-column prop="taskEndDate" label="活动结束时间"> </el-table-column>
<!--      <el-table-column prop="autoStartDate" label="自启动时间"> </el-table-column>-->
      <el-table-column fixed="right" label="操作" width="350"> <!--操作固定列 -->
        <template #default="scope">
          <el-button @click="updateClick(scope.row)" type="primary" size="small">编辑</el-button>
          <el-button v-if="scope.row.isActive == '启用'" size="mini" type="danger" @click="handleActive(scope.$index, scope.row)">禁用</el-button>
          <el-button v-else size="mini" type="primary" @click="handleActive(scope.$index, scope.row)">启用</el-button>
          <el-button @click="ViewchangeDetil(scope.row)" type="primary" size="small">子任务配置</el-button>
          <el-button @click="viewCollectionDetail(scope.row)" type="primary" size="small">领取记录</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
            class="fenye"
            @current-change="handleCurrentChange"
            :current-page="option.page"
            :page-size="10"
            prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
            next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
            background
            layout="total, prev, pager, next, jumper"
            :total="data.total"
    >
    </el-pagination>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog
            width="52%"
            title="总任务管理信息"
            v-model="ruleForm.dialogFormVisible"
            :show-close=true>
      <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleFormRef"
              label-width="100px"
              class="demo-ruleForm"
      >
        <el-form-item label="总任务名称" required>
          <el-col :span="11">
            <el-form-item prop="totalTaskName">
              <el-input v-model="ruleForm.totalTaskName" clearable  placeholder="总任务名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="开始时间" required>
          <el-col :span="11">
            <el-form-item prop="taskStartDate">
              <el-date-picker
                      v-model="ruleForm.taskStartDate"
                      type="datetime"
                      :default-value="new Date()"
                      placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="结束时间" required>
          <el-col :span="11">
            <el-form-item prop="taskEndDate">
              <el-date-picker
                      v-model="ruleForm.taskEndDate"
                      type="datetime"
                      :default-value="new Date()"
                      placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="奖品积分">
          <el-col :span="8">
            <el-form-item prop="points">
              <el-input type="number" min="0"
                        oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                      v-model="ruleForm.points" clearable  placeholder="奖品积分" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="优惠券">
          <el-col :span="8">
            <el-form-item prop="coupons">
              <el-select
                      v-model="ruleForm.couponsList"
                      multiple
                      filterable
                      placeholder="请选择优惠券"
                      @click="getCouponsListClick"
              >
                <el-option v-for="item in data.couponsList"
                           :key="item.coupon_id"
                           :label="item.coupon_name"
                           :value="item.coupon_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
  import { reactive,ref,onMounted} from "vue";
  import {
    getTotalTaskList,
    getCouponsList,
    updateTotalTask,
    addTotalTask,
    getMemberInfo,
    updateTotalTaskActive,
    getTotalTaskById, delBanner
  } from "../http/api";
  import { ElMessage } from "element-plus";
  import {FullTimeFormat} from '../utils/common'
  import { putToOSS,  getFileNameUUID,getFileExtensions } from '@/http/oss'
  import { useRouter } from "vue-router";
  export default {
    name: "TotalTaskManage",
    setup() {
      const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
      const router = useRouter();
      let ViewchangeDetil = (obj) => {
              router.push({name: "TaskManage", params: {id: obj.id,taskStartDate:obj.taskStartDate,taskEndDate:obj.taskEndDate}})
          };
          onMounted(async () => { // 在onMounted生命周期钩子中调用GetAllListAsync方法
      });
      let viewCollectionDetail = async (obj) => {
        router.push({name: "TaskCollectionDetails", params: {id: obj.id}})
      };
      //删除事件
      let deleteClick = async (obj) => {
        //console.log(obj.id);
        let data = {"id": obj.id};
        var delResult = await delBanner(data);
        //console.log(delResult);
        queryTotalTaskListFun(option);
        if(delResult){
          ElMessage.success({
            message: "删除成功！",
            duration: 2000,
            type: "success",
          });
        }else{
          ElMessage.error({
            message: "有相关联的订单，删除失败！",
            duration: 2000
          });
        }
      };
      //删除上传的图片
      let imageClose = ()=>{
        ruleForm.imageUrl="";
        ruleForm.imageDiv = false;
      }
      //修改的表单参数//////////////////////////////////////////////////
      let ruleForm = reactive({
        dialogFormVisible: false,
        id: "",
        bannerTitle:"",
        DisplayDate:"",
        cityId:"",
        shopId:"",
        fileList: [],
        bannerSort:"0",
        bannerMemo:"",
        addOrUpdate:0, //0新增，1更新
        imageDiv:false,
        imageUrl:"",
      });

      //点击新增按钮
      let AddClick = async () => {
        ruleForm.addOrUpdate=0;
        ruleForm.dialogFormVisible = true; //显示出表单
        //ruleFormRef.value.resetFields();
        ruleForm.isActive = true
        ruleForm.totalTaskName=''
        ruleForm.taskStartDate=null
        ruleForm.taskEndDate=null
        ruleForm.points = '';
        ruleForm.coupons = '';
        // ruleForm.imageUrl = '';
      };

      let getCouponsListClick = async  () => {
        let params={
          "taskStartDate":ruleForm.taskStartDate,
          "taskEndDate":ruleForm.taskEndDate
        }
        data.couponsList = await  getCouponsList(params);
        console.log("获取优惠券。。。。", params ,data.couponsList)
      }


      let handleActive = async (index, obj) => {
        ruleForm.addOrUpdate=1;
        let isActiveFlag ;
        if (obj.isActive == '启用'){
          isActiveFlag = false
        } else {
          isActiveFlag = true
        }
        let data={
          "id":obj.id,
          "isActive":isActiveFlag
        }
        await updateTotalTaskActive(data);
        queryTotalTaskListFun(option);
        ruleFormRef.value.resetFields();
      }

      //点击编辑按钮,弹框，默认把原来的数据显示出来
      let updateClick = async (obj) => {
        ruleForm.addOrUpdate=1;
        ruleForm.id = obj.id;
        ruleForm.couponsList = [];
        //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
        let tasks = await getTotalTaskById({ id: obj.id });
        ruleForm.dialogFormVisible = true; //显示出表单
        ruleForm.totalTaskName = tasks.totalTaskName;
        ruleForm.taskStartDate = tasks.taskStartDate;
        ruleForm.taskEndDate = tasks.taskEndDate;
        if(tasks.coupons != null && tasks.coupons != '') {
          let couponsListStr =  tasks.coupons.split(',');
          let arr = [];
          for(var i = 0; i < couponsListStr.length; i++) {
            arr.push(parseInt(couponsListStr[i]))
            if(arr.length > 0){
              ruleForm.couponsList = arr;
            }
          }
        }

        await getCouponsListClick()
        ruleForm.coupons = tasks.coupons;
        ruleForm.points = tasks.points;

      };

      //修改的表单验证
      let rules = reactive({
        totalTaskName: [{ required: false, message: "请输入总任务名称" }],
        taskStartDate: [{ required: true, message: "请选择开始时间" }],
        taskEndDate: [{ required: true, message: "请选择结束时间" }]
      });

      //提交表单,修改信息
      let submitForm = async () => {
        let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
        ruleFormRef.value.validate((valid) => {
          if (!valid) {
            con = true;
            //console.log("下面的不执行了");
            return false;
          }
        });
        if (con) {
          return false;
        }
        //更新并且重新加载数据
        if(ruleForm.addOrUpdate==1){ //更新
          let data={
            "id":ruleForm.id,
            "taskStartDate": ruleForm.taskStartDate,
            "taskEndDate": ruleForm.taskEndDate,
            "totalTaskName": ruleForm.totalTaskName,
            "points":  ruleForm.points|| 0,
            "isActive":true,
            // "imageUrl": ruleForm.imageUrl,
            "coupons": ruleForm.couponsList.toString()
            // "coupons": ruleForm.coupons
            // "isActive":ruleForm.isActive
          }

          let taskStartDate = new Date(Date.parse(ruleForm.taskStartDate))
          let taskEndDate = new Date(Date.parse(ruleForm.taskEndDate))
          if(taskStartDate > taskEndDate) {
            ElMessage.error({
              message: "活动开始时间不能大于结束时间！",
              duration: 2000
            });
            return
          } else {
            await updateTotalTask(data);
          }

        }else {//新增
          let data={
            "totalTaskName": ruleForm.totalTaskName,
            "taskStartDate": ruleForm.taskStartDate,
            "taskEndDate": ruleForm.taskEndDate,
            "points":  ruleForm.points || 0,
            "imageUrl": '',
            "coupons": ruleForm.coupons.toString(),
            "isActive":true
          }

          let taskStartDate = new Date(Date.parse(ruleForm.taskStartDate))
          let taskEndDate = new Date(Date.parse(ruleForm.taskEndDate))
          if(taskStartDate > taskEndDate) {
            ElMessage.error({
              message: "活动开始时间不能大于结束时间！",
              duration: 2000
            });
            return
          } else {
            await addTotalTask(data);
          }

          ruleForm.imageUrl = '';
          ruleForm.imageDiv = false;
          ruleForm.fileList = []
        }
        queryTotalTaskListFun(option);
        ruleFormRef.value.resetFields();
        //setTimeout(function () {
        ruleForm.dialogFormVisible = false;
        //}, 500);
      };

      //表单重置
      let resetForm = (formName) => {
        //console.log( ruleFormRef.value);
        ///////ruleFormRef.value.resetFields();

        //setTimeout(function () {
        ruleForm.dialogFormVisible = false;
        //}, 500);
      };


      //声明数据
      let data = reactive({
        table: [],
        pages: 0,
        total: 0,
        srcList:[""],
        couponsSelectList:[],
        couponsList:[]
      });

      //声明查询条件
      let option = reactive({
        totalTaskName:'',
        page: 1,
        coupons:'',
        pageSize: 10,
      });

      let indexMethod =(index) => {
        return index+(option.page-1)*option.pageSize+1;
      }

      //大图
      let bigImg=(url)=> {
        data.srcList[0] = url;
      };
      //获取任务列表方法
      let queryTotalTaskListFun = async (obj) => {
        let arr = await getTotalTaskList(obj);
        data.total = arr.totalCount;
        //格式化日期
        arr.items.filter(item => {
          item.taskStartDate = FullTimeFormat(item.taskStartDate);
          item.taskEndDate = FullTimeFormat(item.taskEndDate);
          item.isActive = item.isActive == true? '启用':'禁用';
        })

        //获取的时间格式化，也可以用上面的格式化方法
        // for (let index = 0; index < arr.items.length; index++) {
        //   arr.items[index].startDay = arr.items[index].startDay.substring(0, 10);
        //   arr.items[index].endDay = arr.items[index].endDay.substring(0, 10);
        // }
        data.table = arr.items;
      };

      //初始化时，获取初始化数据
      queryTotalTaskListFun(option);

      //点击页码获取数据
      let handleCurrentChange = (index) => {
        //console.log(index + "index");
        //接口是接收的guid,这样写不报错
        if (option.totalTaskName == "") {
          option.totalTaskName = null;
        }
        option.page = index;
        queryTotalTaskListFun(option);
      };

      //查询方法,此处需要根据条件到后台进行筛选
      let getTabList = async () => {
        //console.log(option);
        // if (option.cityId == "") {
        //   option.cityId = null;
        // }

        queryTotalTaskListFun(option);
      };
      let testClick = async() =>{
        var data = {
              "telephone_no": "13075365625",
              "customer_name": "CRM接口测试",
              "customer_nickname": "crm昵称",
              "source_platform": "1000",
              "platform_register_no": "",
              "appid": "wx40033c8afc3c539c",
              "openid": "oUtjTtyKqRXJsaJOBOvVITWMKq1g",
              "unionid": "ohvXs5xTz_Ou_8K2rLKkonuIweNU",
              "recommender_no": "2020070619191",
              "belong_agent_no": "admin",
              "belong_site_no": "MR001",
              "register_date": "2023-12-20",
              "birthday": "1993-09-11",
              "gender": "10",
              "province_name": "山东",
              "city_name": "济南市",
              "district_name": "历城区",
              "address": "世纪大道1111号",
              "id_no": "320282199311236628",
              "remark": "",
              "customer_type": "",
              "old_customer_no": "1674458553022287872",// 1674458553022287872 领取   // 00031r009822 任务
              "coupon_no":"2023122700001941"// 2023122700001821
            }
        var result =  await getMemberInfo(data);
        console.log("接口返回结果", result)
      };



      let beforeUpload=(file) =>{
        // 限制上传类型
        const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
        //限制的上限为2M
        const max2M = file.size / 1024 / 1024 < 2;
        if (!fileExtensions) {
          ElMessage.error({
            message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
            duration: 2000
          });
        }
        if (!max2M) {
          ElMessage.error({
            message: "上传文件大小不能超过 2MB!",
            duration: 2000
          });
        }
        return fileExtensions && max2M;
      };
      let handleUpload=(option)=> {
        // 生成的文件名，保留文件后缀名，进行拼接
        let objName ="ShopBannerImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
        // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
        putToOSS(`flieName/${objName}`, option.file).then(res => {
          //console.log(res,'res')
          // 上传成功之后，转换真实的地址
          //signatureUrl(`flieName/${objName}`).then(res => {
          //console.log(res)
          ruleForm.imageUrl=res.url;
          console.log('上传之后获取图片路径', res.url )
          ruleForm.imageDiv =true;
          //})
        })
      };
      let submitUpload=()=>{
        this.$refs.upload.submit();
      };
      let handleRemove=(file, fileList)=> {
        console.log(file, fileList);
      };
      let handlePreview=(file)=>{
        console.log(file);
      };
      return {
        data,
        handleCurrentChange,
        getTabList,
        option,
        updateClick,
        ViewchangeDetil,
        viewCollectionDetail,
        ruleForm,
        ruleFormRef,
        rules,
        submitForm,
        resetForm,
        AddClick,
        testClick,
        getCouponsListClick,
        deleteClick,
        imageClose,
        getFileExtensions,
        beforeUpload,
        handleUpload,
        submitUpload,
        handleRemove,
        handlePreview,
        bigImg,
        handleActive,
        indexMethod
      };
    },
  };
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
  .box{

  }
  .el-image-viewer__close {
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color:rgba(255, 255, 255, 0)!important;
  }
  .select-box {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    > div {
      width: 15%;
      display: flex;
      align-items: center;
      span {
        width: 50px;
      }
      margin-right: 25px;
    }
  }
  .fenye {
    margin-top: 20px;
  }
</style>
