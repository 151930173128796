import $http from "./index";

import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;

export const getNumberCardList = (data) => {
    return $http.post(
        `${base}/app/info/numbercard/back/pagelist`,
        data
    );
};

