<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/LotteryRuleConfig' }">返回</el-breadcrumb-item>
    <el-breadcrumb-item></el-breadcrumb-item>
  </el-breadcrumb>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>奖品名称:</span>
        <el-input
            v-model="option.prizeName"
            clearable
            placeholder="请输入奖品名称"
        ></el-input>
      </div>
      <div>
        <span>奖品类型:</span>
        <el-select v-model="option.prizeType" placeholder="请选择奖品类型" clearable>
          <el-option label="积分" :value="'0'"></el-option>
          <el-option label="优惠券" :value="'1'"></el-option>
          <el-option label="谢谢参与" :value="'2'"></el-option>
        </el-select>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
        >查 询</el-button
        >
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">
          新增
        </el-button>
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page]  -->
    <el-table :row-style="setRowStyle" border stripe :data="data.table" style="width:100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column
          type="index" :index="indexMethod"
          width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="shopID" label="shopID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column  prop="prizeName" label="奖品名称"> </el-table-column>
      <el-table-column label="图片" prop="prizeIcon" align="center" width="100px">
        <template v-slot="scope">
          <el-image @click="bigImg(scope.row.prizeIcon)" style="width: 50px; height: 50px; margin-bottom:-4px"
                    :src=scope.row.prizeIcon
                    :preview-src-list="data.srcList">
          </el-image>
          <el-popover placement="top-" tstartrigger="click">
            <a :href="scope.row.prizeIcon" target="_blank" title="查看最大化图片">
              <img :src="scope.row.prizeIcon" style="width: 300px;height: 300px">
            </a>
            <img :src="scope.row.prizeIcon" style="width: 50px;height: 50px; cursor:pointer">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="prizeType" label="奖品类型">
        <template v-slot:default="scope">
          <span v-if="scope.row.prizeType === '0'">积分</span>
          <span v-else-if="scope.row.prizeType === '1'">优惠券</span>
          <span v-else>谢谢参与</span>
        </template>
      </el-table-column>
      <el-table-column  prop="pointsNum" label="积分数量" v-if="false"> </el-table-column>
      <el-table-column  prop="couponDetail" label="优惠券明细" v-if="false"> </el-table-column>
      <el-table-column  prop="prizeNumber" label="奖品数量"> </el-table-column>
      <el-table-column prop="winningProbability" label="中奖概率">
        <template v-slot="scope">
          {{ scope.row.winningProbability }}%
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
              @click="UpdateClick(scope.row)"
              size="small"
              icon="el-icon-edit"
              type="primary"
          >修改</el-button>
          <el-popconfirm
              title="您确定要删除吗？"
              confirm-button-text='是'
              cancel-button-text='否'
              iconColor="red"
              @confirm="deleteClick(scope.row)"
          >
            <template #reference>
              <el-button size="small" icon="el-icon-delete" type="danger">
                删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 3.分页 -->
    <el-pagination
        class="fenye"
        @current-change="handleCurrentChange"
        :current-page="option.page"
        :page-size="10"
        prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
        next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
        background
        layout="total, prev, pager, next, jumper"
        :total="data.total"
    >
    </el-pagination>
    <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="42%" title="奖品配置" :show-close=true v-model="ruleForm.dialogFormVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="抽奖活动ID" prop="sweepstakesInformationId"  required style="display: none;">
          <el-input v-model="ruleForm.sweepstakesInformationId" placeholder="请输入奖品名称" maxlength="15" disabled></el-input>
        </el-form-item>
        <el-form-item label="奖品名称" prop="prizeName"  required>
          <el-input v-model="ruleForm.prizeName" placeholder="请输入奖品名称" maxlength="7"></el-input>
        </el-form-item>
        <el-form-item label="奖品类型" prop="prizeType" required>
          <el-select v-model="ruleForm.prizeType" placeholder="请选择奖品类型">
            <el-option label="积分" :value="'0'"></el-option>
            <el-option label="优惠券" :value="'1'"></el-option>
            <el-option label="谢谢参与" :value="'2'"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="积分数量" prop="pointsNum" v-if="ruleForm.prizeType === '0'">
          <el-input v-model="ruleForm.pointsNum" placeholder="请输入积分数量" :maxlength="8" @input="onPointsNumInput" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"></el-input>
        </el-form-item>

        <el-form-item label="优惠券明细" prop="couponDetail" v-if="ruleForm.prizeType === '1'">
          <el-select v-model="ruleForm.couponDetail" placeholder="请选择优惠券" ref="couponDetailSelect" style="width: 220px;" >
            <el-option v-for="item in data.couponDetailData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id.toString()" />
          </el-select>
        </el-form-item>
        <el-form-item label="奖品数量" prop="prizeNumber" required>
          <el-input
              v-model="ruleForm.prizeNumber"
              placeholder="请输入奖品数量"
              :maxlength="8"
              oninput="this.value=this.value.replace(/[^0-9]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="中奖概率(%)" prop="winningProbability"  required>
          <el-input v-model="ruleForm.winningProbability" placeholder="请输入中奖概率(0.01--100)" maxlength="15" :rules="[{ validator: validateWinningProbability }]"></el-input>
        </el-form-item>
        <el-upload ref="myupload"
                   action=""
                   :file-list="ruleForm.fileList"
                   :on-preview="handlePreview"
                   :on-remove="handleRemove"
                   :beforeUpload="beforeUpload"
                   :http-request="handleUpload"
                   :multiple="false"
                   :limit=1
                   accept=".png,.jpg"
        >
          <el-button size="small" type="primary">上传图片</el-button>
        </el-upload>
        <el-col>
          <p style="color: red;padding-bottom: 20px">* 图片格式 宽：50px；高：50px；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
        </el-col>
        <div v-if="ruleForm.imageDiv">
          <el-image :src="ruleForm.prizeIcon" :fit="fit"></el-image>
          <el-button @click="imageClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted ,watch} from "vue";
import {
  prizeconfiglist,
  prizeconfigid,
  prizeconfigupdate,
  getCouponsLists,
  prizeconfigadd, prizeconfigdelete,
  GetAllprizeconfig, //获取全部数据
} from "../http/api";
import {ElMessage} from "element-plus";
import {FullTimeFormat} from "../utils/common";
import { putToOSS, getFileNameUUID,getFileExtensions } from '@/http/oss';
import {useRoute, useRouter} from "vue-router";
export default {
  methods: {

    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    }
  },
  name: "Category",
  setup() {
    const route = useRoute()
    const router = useRouter()
    //params
    let LotteryRuleConfigID=route.params.id;
    if(!LotteryRuleConfigID){
      router.push({name: "LotteryRuleConfig"})
    }
    // 去除百分号
    function removePercentage(value) {
      return value.replace(/%/g, '');
    }
    // 新增或更新时的中奖概率验证
    let validateTotalWinningProbability = async (currentWinningProbability, isUpdate, currentId) => {
      try {
        const response = await GetAllprizeconfig();
        // Separate data based on sweepstakesInformationId
        const groupedData = {};
        response.forEach(prize => {
          if (!groupedData[prize.sweepstakesInformationId]) {
            groupedData[prize.sweepstakesInformationId] = [];
          }
          groupedData[prize.sweepstakesInformationId].push(prize);
        });
        // Calculate total winning probability for the current sweepstakesInformationId
        const currentSweepstakesData = groupedData[ruleForm.sweepstakesInformationId] || [];
        let totalWinningProbability = currentSweepstakesData.reduce((total, prize) => {
          return total + prize.winningProbability;
        }, 0);
        // If it's an update operation, subtract the current prize's winning probability
        if (isUpdate) {
          const currentPrize = currentSweepstakesData.find(prize => prize.id === currentId);
          totalWinningProbability -= currentPrize.winningProbability;
        }
        // Add the current winning probability
        totalWinningProbability += parseFloat(currentWinningProbability);
        // Check if the total is within the allowed range (<= 100)
        if (totalWinningProbability > 100.0) {
          console.error("当前抽奖信息Id的总中奖概率不能超过100%！");
          return false;
        }
        // Validation passed
        return true;
      } catch (error) {
        console.error("错误信息:", error);
        return false;
      }
    };
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    onMounted(() => {
    });
    //声明数据table表格的内容
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      couponDetailData: [],
      srcList:[""]
    });
    //声明查询条件
    let option = reactive({
      prizeName: "",
      prizeType: "", // 添加奖品类型属性
      lotteryId: LotteryRuleConfigID,
      page: 1,
      pageSize: 10,
    });
    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    }
    let deleteClick = async (obj) => {
      let data = {"id": obj.id};
      let delResult = await prizeconfigdelete(data);
      getCategoryListFun(option);
      if(delResult){
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      }else{
        ElMessage.error({
          message: "删除失败！",
          duration: 2000
        });
      }
    };
    //删除上传的图片
    let imageClose = ()=>{
      ruleForm.prizeIcon="";
      ruleForm.imageDiv = false;
    }
    let getCategoryListFun = async (obj) => {
      let arr = await prizeconfiglist(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    getCategoryListFun({ Page: 1, PageSize: 10, lotteryId: LotteryRuleConfigID });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getCategoryListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await getCategoryListFun(option);
    };

    let getVipListDataFun = async (obj) => {
      let couponDetail = await getCouponsLists(obj);
      console.log("1234567",couponDetail.data.coupon_rule_list)
      data.couponDetailData = couponDetail.data.coupon_rule_list;
    };
    getVipListDataFun({});

    //修改的表单参数
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      sweepstakesInformationId: LotteryRuleConfigID,
      prizeName: "",
      prizeType: "",
      pointsNum: 0,
      couponDetail: "",
      prizeNumber:"",
      winningProbability:"",
      prizeIcon:"",
      addOrUpdate:0 //0新增，1更新
    });
    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let Category = await prizeconfigid(obj.id);
      //console.log(Category);
      ruleForm.sweepstakesInformationId = Category.sweepstakesInformationId;
      ruleForm.prizeName = Category.prizeName;
      ruleForm.prizeType = Category.prizeType;
      ruleForm.pointsNum = Category.pointsNum;
      ruleForm.couponDetail = Category.couponDetail;
      ruleForm.prizeNumber = Category.prizeNumber;
      ruleForm.winningProbability = `${Category.winningProbability}`;
      ruleForm.prizeIcon = Category.prizeIcon;
      ruleForm.dialogFormVisible = true; //显示出表单
    };
    //点击新增按钮
    let AddClick = async () => {
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleFormRef.value.resetFields();
      ruleForm.addOrUpdate=0;
      ruleForm.sweepstakesInformationId = LotteryRuleConfigID;
      ruleForm.prizeName = "";
      ruleForm.prizeType = "";
      ruleForm.pointsNum = 0;
      ruleForm.couponDetail = "";
      ruleForm.prizeNumber = "";
      ruleForm.winningProbability = "";
      ruleForm.prizeIcon = "";
      //清空照片和文件
      ruleForm.imageDiv = false;
      ruleForm.fileList = [];
    };
    //修改的表单验证
    let rules = reactive({
      sweepstakesInformationId: [
        { required: true, message: "请输入抽奖活动信息表ID" }
      ],
      prizeName: [
        { required: true, message: "请输入分类名称" }
      ],
      prizeNumber: [
        { required: true, message: "请输入分类数量" }
      ],
      prizeType: [
        { required: true, message: "请选择奖品类型" }
      ],
      winningProbability: [
        { required: true, message: "请输入中奖概率" },
        { pattern: /^(\d{1,2}(\.\d{1,2})?|100)$/, message: "请输入有效的百分比" },
      ],
      prizeIcon: [{ required: true, message: "请输入序号" }]
    });
    // 监听 prizeType 的变化，根据选择情况设置其他字段值
    watch(() => ruleForm.prizeType, (newValue) => {
      if (newValue === '0') { // 积分类型
        ruleForm.couponDetail = ''; // 清空优惠券字段
        rules.pointsNum = [{ required: true, message: "请输入积分数量" }];
        delete rules.couponDetail; // 移除优惠券的验证规则
      } else if (newValue === '1') { // 优惠券类型
        ruleForm.pointsNum = 0; // 设置积分为0
        rules.couponDetail = [{ required: true, message: "请选择优惠券" }];
        delete rules.pointsNum; // 移除积分数量的验证规则
      } else if (newValue === '2') { // 谢谢参与类型
        ruleForm.couponDetail = ''; // 清空优惠券字段
        ruleForm.pointsNum = 0; // 设置积分为0
        delete rules.pointsNum; // 移除积分数量的验证规则
        delete rules.couponDetail; // 移除优惠券的验证规则
      } else { // 其他情况
        // 其他逻辑，根据实际需要处理
      }
    });

    //提交表单,新增或修改项目信息
    let submitForm = async (formName) => {
      let con = false;
      // 在提交表单之前去除百分号
      ruleForm.winningProbability = removePercentage(ruleForm.winningProbability);
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          return false;
        }
      });
      if (con) {
        return false;
      }
      // 在提交之前验证总中奖概率
      const isValid = await validateTotalWinningProbability(ruleForm.winningProbability, ruleForm.addOrUpdate === 1, ruleForm.id);
      if (!isValid) {
        ElMessage.error({
          message: "总中奖概率不能超过100%！",
          duration: 2000
        });
        return;
      }
      try{
        if(ruleForm.addOrUpdate==1){//更新
          let data={
            "sweepstakesInformationId":ruleForm.sweepstakesInformationId,
            "prizeName":ruleForm.prizeName,
            "prizeType":ruleForm.prizeType,
            "pointsNum":ruleForm.pointsNum,
            "couponDetail": ruleForm.couponDetail,
            "prizeNumber":ruleForm.prizeNumber,
            "winningProbability":ruleForm.winningProbability,
            "prizeIcon":ruleForm.prizeIcon,
            "ID":ruleForm.id
          };
          await prizeconfigupdate(data);
          ElMessage.success({
            message: "修改成功！",
            duration: 2000,
            type: "success",
          });
        }else{//新增
          let data= {
            "sweepstakesInformationId": ruleForm.sweepstakesInformationId,
            "prizeName": ruleForm.prizeName,
            "prizeType": ruleForm.prizeType,
            "pointsNum": ruleForm.pointsNum,
            "couponDetail": ruleForm.couponDetail,
            "prizeNumber": ruleForm.prizeNumber,
            "winningProbability": ruleForm.winningProbability,
            "prizeIcon": ruleForm.prizeIcon,
          };
          await prizeconfigadd(data);
          ElMessage.success({
            message: "新增成功！",
            duration: 2000,
            type: "success",
          });
        }
        await getCategoryListFun(option);//重新加载数据
        ruleForm.dialogFormVisible = false;
      }
      catch (error) {
        ElMessage.error({
          message: "操作失败，请重试！", // 可以根据实际情况修改失败消息
          duration: 2000
        });
        console.error(error); // 输出错误信息到控制台，方便调试
      }
    };
    //表单重置
    let resetForm = (formName) => {
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };
    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
    };
    //大图
    let bigImg=(url)=> {
      data.srcList[0] = url;
    };
    let beforeUpload=(file) =>{
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000
        });
      }
      return fileExtensions && max2M;
    };
    let handleUpload=(option)=> {
      let objName ="GoodsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
      putToOSS(`flieName/${objName}`, option.file).then(res => {
        ruleForm.prizeIcon=res.url;
        ruleForm.imageDiv =true;
      })
    };
    let submitUpload=()=>{
      this.$refs.upload.submit();
    };
    let handleRemove=(file, fileList)=> {
      console.log(file, fileList);
    };
    let handlePreview=(file)=>{
      console.log(file);
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      deleteClick,
      ruleForm,
      btnSubmit,
      imageClose,
      rules,
      beforeUpload,
      submitForm,
      resetForm,
      ruleFormRef,
      AddClick,
      submitUpload,
      handleRemove,
      handlePreview,
      indexMethod,
      bigImg,
      handleUpload,
    };
  },
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
