<template>
    <div>
        <!-- 1.筛选条件 -->
        <div class="select-box">
            <div>
                <span>广告位置:</span>
                <el-select v-model="option.bannerPosition" clearable placeholder="请选择广告位置">
                    <el-option v-for="item in data.bannerPositionListData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
            </div>
            <div>
                <span>广告类型:</span>
                <el-select v-model="option.bannerType"  clearable placeholder="请选择广告类型">
                    <el-option v-for="item in data.bannerTypeListData" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div>
                <span>活动状态:</span>
                <el-select v-model="option.status"  clearable placeholder="请选择活动状态">
                    <el-option v-for="item in data.statusListData" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div>
                <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
                <el-button icon="el-icon-document-add" @click="AddClick" type="primary">新增广告</el-button>
                <el-button @click="setMessage" type="primary">配置提示信息</el-button>
            </div>
        </div>

        <!--2. table表格的内容 data.table[option.page] -->
        <el-table border stripe :data="data.table" style="width: 100%">
            <el-table-column prop="id" label="ID" v-if="false"> </el-table-column> <!--隐藏列-->
            <el-table-column
                    type="index" :index="indexMethod"
                    width="50" style="text-align: center;">
            </el-table-column>
            <el-table-column prop="bannerName" label="广告名称"> </el-table-column>
            <el-table-column label="广告位置">
                <template #default="scope">
                    <span>{{scope.row["bannerPosition"] == 1 ?'开屏广告' : scope.row["bannerPosition"] == 2 ? '四屏广告':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="bannerCity" label="适用城市"> </el-table-column>
            <el-table-column label="广告类型">
                <template #default="scope">
                    {{scope.row["bannerType"] == 1 ?'领券' : scope.row["bannerType"] == 2 ? '报名' : scope.row["bannerType"] == 3 ? '推荐好友': scope.row["bannerType"] == 4? '门店预约':''}}
                </template>
            </el-table-column>
            <el-table-column label="活动状态">
                <template #default="scope">
                    {{scope.row["status"] == 1 ?'启用' : scope.row["status"] == 2 ? '禁用': ''}}
                </template>
            </el-table-column>
            <el-table-column prop="creationTime" label="创建时间"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="440"> <!--操作固定列 -->
                <template #default="scope">
                    <el-button size="small" icon="el-icon-edit" @click="UpdateClick(scope.row)" type="primary">编辑</el-button>
                    <el-popconfirm
                            title="您确定要删除吗？"
                            confirm-button-text='是'
                            cancel-button-text='否'
                            iconColor="red"
                            @confirm="deleteClick(scope.row)"
                    >
                        <template #reference>
                            <el-button size="small" icon="el-icon-delete" type="danger">
                                删除</el-button>
                        </template>
                    </el-popconfirm>
                    <!--<el-button size="small" icon="el-icon-close" type="primary" @click="closeStatus(scope.row)">关闭活动</el-button>-->
                    <el-button size="small" icon="el-icon-close" type="primary" @click="closeStatus(scope.row)">{{scope.row["status"] == 1 ?'禁用' : scope.row["status"] == 2 ? '启用': ''}}</el-button>
                    <el-button @click="ViewDetail(scope.row)" type="primary" size="small" v-if="scope.row.bannerType == 1 || scope.row.bannerType == 2 || scope.row.bannerType == 3">查看{{scope.row["bannerType"] == 1 ?'领券' : scope.row["bannerType"] == 2 ? '报名' : scope.row["bannerType"] == 3 ? '推荐好友': ''}}</el-button>
                    <el-button size="small" type="primary" v-if="scope.row.bannerType == 1" @click="countDetail(scope.row)">券数量</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 3.分页 -->
        <el-pagination
                class="fenye"
                @current-change="handleCurrentChange"
                :current-page="option.page"
                :page-size="10"
                prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
                next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
                background
                layout="total, prev, pager, next, jumper"
                :total="data.total"
        >
        </el-pagination>
        <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
        <el-dialog width="65%" title="广告管理信息" @close="resetForm('ruleForm')" v-model="ruleForm.dialogFormVisible" :show-close=true>
            <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="180px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="bannerName" label="广告名称">
                            <el-input v-model="ruleForm.bannerName" clearable  placeholder="请填写广告名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="bannerCity" label="适用城市">
                            <el-select v-model="ruleForm.bannerCity" multiple filterable placeholder="请选择适用城市" @change="bannerCityChange">
                                <el-option
                                        v-for="item in data.cityListData"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="bannerType" label="广告类型">
                            <el-select v-model="ruleForm.bannerType" clearable placeholder="请选择广告类型" @change="positionChange">
                                <el-option v-for="item in data.bannerTypeListData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="分享标题"  required prop="shareTitle" label-width="170px" v-if="ruleForm.bannerType==3">
                            <el-input v-model="ruleForm.shareTitle" clearable  placeholder="请输入分享标题" :maxlength="50" autocomplete="off" style="width: 220px"></el-input>
                        </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row style="margin-bottom: 20px;">
                    <el-form-item label="分享图片" prop="shareImage" label-width="170px"  v-if="ruleForm.bannerType==3">
                        <el-upload ref="myupload"
                                   action=""
                                   :file-list="ruleForm.fileLists"
                                   :on-preview="handlePreviews"
                                   :on-remove="handleRemoves"
                                   :beforeUpload="beforeUpload"
                                   :http-request="handleUploads"
                                   :multiple="false"
                                   :limit=1
                                   accept=".png,.jpg"
                        >
                            <el-button size="small" type="primary">上传分享图片</el-button>
                        </el-upload>
                        <el-col>
                            <p style="color: red;padding-bottom: 20px">* 图片格式 宽：655px；高：517px；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
                        </el-col>
                        <!--看上传的图片-->
                        <div v-if="ruleForm.imageDivs">
                            <el-image :src="ruleForm.shareImage" :fit="fit" style="width: 500px"></el-image>
                            <el-button @click="imageClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
                        </div>
                    </el-form-item>
                </el-row>
                <el-row style="margin-bottom: 20px;">
                    <el-col :span="16">
                        <el-form-item label="分享描述" prop="shareDescription" label-width="170px" v-if="ruleForm.bannerType==3">
                            <el-input
                                    v-model="ruleForm.shareDescription"
                                    type="textarea"
                                    :rows="6"
                                    clearable
                                    placeholder="分享描述"
                                    autocomplete="off"
                                    :maxlength="500"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                    <el-row>
                    <el-col :span="12">
                        <el-form-item prop="bannerPosition" label="广告位置">
                            <el-select v-model="ruleForm.bannerPosition" clearable placeholder="请选择广告位置" @focus="positionFocus">
                                <el-option v-for="item in reData.bannerPositionListData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="taskTime" label="活动时间">
                            <el-date-picker
                                    v-model="ruleForm.taskTime"
                                    type="datetimerange"
                                    value-format="YYYY-MM-DD HH:mm:ss"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    placeholder="选择日期"
                                    :disabled-date="taskTimeOptions"
                                    @change="taskChange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <!--<el-form-item prop="bannerStartTime" label="自动启用时间">
                          <el-date-picker
                            v-model="ruleForm.bannerStartTime"
                            type="datetime"
                            value-format="YYYY-MM-DDTHH:mm:ss"
                            placeholder="选择日期"
                            :disabled-date="baPickerOptions"
                            @focus="timeFocus"
                            @change="timeChange">
                          </el-date-picker>
                        </el-form-item>-->
                        <el-form-item label="是否启用" prop="status">
                            <el-radio-group v-model="ruleForm.status">
                                <el-radio label="1">启用</el-radio>
                                <el-radio label="2">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="bannerImgURL" label="上传广告图">
                          <el-upload :disabled="ruleForm.upload"
                            action=""
                            :file-list="ruleForm.fileList"
                            :multiple="false"
                            :limit=1
                            accept=".png,.jpg,.jpeg"
                            list-type="picture-card"
                            :beforeUpload="beforeUpload"
                            :on-preview="handlePreview"
                            :http-request="handleUpload">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                        <el-dialog v-model="reData.dialogVisible">
                           <!-- <img width="100%" :src="ruleForm.bannerImgURL" alt="">-->
                            <el-image :src="ruleForm.bannerImgURL"></el-image>
                        </el-dialog>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.img">
                        <el-image style="width: 50%; height: 50%" :src="ruleForm.bannerImgURL" fit="contain" @click="handlePreview"></el-image>
                        <span @click="handleImgDelete">
                        <i class="el-icon-delete"></i>
                        </span>
                    </el-col>
                    <el-col>
                        <p style="color: red;padding-left: 100px ;padding-bottom: 20px">* 图片格式 宽：1024px；高：1536px；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="bannerImgURLKp" label="上传背景图">
                            <el-upload :disabled="ruleForm.uploadKp"
                                       action=""
                                       :file-list="ruleForm.fileListKp"
                                       :multiple="false"
                                       :limit=1
                                       accept=".png,.jpg,.jpeg"
                                       list-type="picture-card"
                                       :beforeUpload="beforeUpload"
                                       :on-preview="handlePreviewKp"
                                       :http-request="handleUploadKp">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog v-model="reData.dialogVisibleKp">
                                <el-image :src="ruleForm.bannerImgURLKp"></el-image>
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.imgKp">
                        <el-image style="width: 50%; height: 50%" :src="ruleForm.bannerImgURLKp" fit="contain" @click="handlePreviewKp"></el-image>
                        <span @click="handleImgDeleteKp">
                        <i class="el-icon-delete"></i>
                        </span>
                    </el-col>
                    <el-col>
                        <p style="color: red;padding-left: 100px ;padding-bottom: 20px">* 图片格式 宽：130px；高：49px；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
                    </el-col>
                </el-row>
                <div v-if="ruleForm.bannerType==1">
                   <el-row>领券规则设置</el-row>
                  <el-row>
                      <el-col :span="12">
                          <el-form-item prop="peopleDayMaxCount" label="每人每天领取最大数量">
                              <el-input type="number" v-model="ruleForm.peopleDayMaxCount" clearable  placeholder="如果是0就不限制抽奖次数" @input="((val)=>{isNumber(val, 1)})"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="12">
                          <el-form-item prop="dayMaxCount" label="每天最多领券数量">
                              <el-input type="number" v-model="ruleForm.dayMaxCount" clearable  placeholder="如果是0就不限制抽奖次数" @input="((val)=>{isNumber(val, 2)})"></el-input>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12"></el-col>
                     <el-col :span="12">
                         <el-form-item prop="peopleMaxCount" label="每人最多领券数量">
                             <el-input type="number" v-model="ruleForm.peopleMaxCount" clearable  placeholder="如果是0就不限制抽奖次数" @input="((val)=>{isNumber(val, 3)})"></el-input>
                         </el-form-item>
                     </el-col>
                  </el-row>
                    <div v-if="reData.vipManageFlag">
                        <template v-for="(item,index) in reData.vipManageList" :key="item.id">
                            <el-row v-if="index%2==0">
                                <el-col :span="12">
                                    <el-form-item prop="couponLeft" :label="item.vipName">
                                        <el-select v-model="ruleForm.coupon[index]" multiple filterable placeholder="请选择可领用券" @focus="couponFocus">
                                            <el-option
                                                    v-for="item in reData.couponListData"
                                                    :key="item.coupon_id"
                                                    :label="item.coupon_name"
                                                    :value="item.coupon_id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="index+1<reData.vipManageList.length">
                                    <el-form-item prop="couponRight" :label="reData.vipManageList[index+1].vipName">
                                        <el-select v-model="ruleForm.coupon[index+1]" multiple filterable placeholder="请选择可领用券" @focus="couponFocus">
                                            <el-option
                                                    v-for="item in reData.couponListData"
                                                    :key="item.coupon_id"
                                                    :label="item.coupon_name"
                                                    :value="item.coupon_id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </template>
                    </div>
                </div>
                <div v-if="ruleForm.bannerType==2">
                    <el-row>报名规则设置</el-row>
                    <el-row>
                        <el-form-item prop="peopleMaxCount">
                            <el-checkbox :indeterminate="reData.isIndeterminate" v-model="reData.checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                             <el-checkbox-group v-model="ruleForm.applicationTasks" @change="handleCheckedCitiesChange">
                              <el-checkbox v-for="item in reData.applicationTasks" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-row>
                </div>
                <div v-if="ruleForm.bannerType==3">
                    <el-row>推荐好友规则设置</el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="注册">
                                <el-radio-group v-model="reData.radioOne">
                                    <el-radio label="1">积分</el-radio>
                                    <el-radio label="2">优惠券</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item v-if="reData.radioOne=='1'" label="积分">
                                <el-input type="number" v-model="ruleForm.accumulatePointsOne" clearable  placeholder="请填写积分" @input="((val)=>{isNumber(val, 4)})"></el-input>
                            </el-form-item>
                            <el-form-item prop="couponInformationOne" v-if="reData.radioOne=='2'" label="优惠券">
                                <el-select v-model="ruleForm.couponInformationOne" multiple filterable placeholder="请选择优惠券" @focus="couponFocus">
                                    <el-option
                                            v-for="item in reData.couponListData"
                                            :key="item.coupon_id"
                                            :label="item.coupon_name"
                                            :value="item.coupon_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item prop="inviteNumber" label="注册人数">
                                <el-input v-model="ruleForm.inviteNumber " :maxlength="8" @input="((val)=>{isNumber(val, 6)})" clearable  placeholder="请输入注册人数"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="首单消费">
                                <el-radio-group v-model="reData.radioTwo">
                                    <el-radio label="1">积分</el-radio>
                                    <el-radio label="2">优惠券</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item v-if="reData.radioTwo=='1'" label="积分">
                                <el-input type="number" v-model="ruleForm.accumulatePointsTwo" clearable  placeholder="请填写积分" @input="((val)=>{isNumber(val, 5)})"></el-input>
                            </el-form-item>
                            <el-form-item prop="couponInformationTwo" v-if="reData.radioTwo=='2'" label="优惠券">
                                <el-select v-model="ruleForm.couponInformationTwo" multiple filterable placeholder="请选择优惠券" @focus="couponFocus">
                                    <el-option
                                            v-for="item in reData.couponListData"
                                            :key="item.coupon_id"
                                            :label="item.coupon_name"
                                            :value="item.coupon_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-row>
<!--                    <el-form-item label="广告详情"  prop="bannerDetails">-->
<!--                        <QuillEditor ref="quillEditorRef" v-model="ruleForm.bannerDetails"></QuillEditor>-->
                            <el-col :span="12">
                                <el-form-item prop="bannerDetails" label="广告详情">
                                    <el-upload :disabled="ruleForm.uploadDetail"
                                               action=""
                                               :file-list="ruleForm.fileListDetail"
                                               :multiple="false"
                                               :limit=1
                                               accept=".png,.jpg,.jpeg"
                                               list-type="picture-card"
                                               :beforeUpload="beforeUpload"
                                               :on-preview="handlePreviewDetail"
                                               :http-request="handleUploadDetail">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog v-model="reData.dialogVisibleDetail">
                                        <el-image :src="ruleForm.bannerDetails"></el-image>
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-if="ruleForm.addOrUpdate == 1 && ruleForm.imgDetail">
                                <el-image style="width: 50%; height: 50%" :src="ruleForm.bannerDetails" fit="contain" @click="handlePreviewDetail"></el-image>
                                <span @click="handleImgDeleteDetail">
                        <i class="el-icon-delete"></i>
                        </span>
                            </el-col>
                            <el-col>
                                <p style="color: red;padding-left: 100px ;padding-bottom: 20px">* 图片格式 宽：1280px；高：不限；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
                            </el-col>
<!--                    </el-form-item>-->
                </el-row>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
            </template>
        </el-dialog>
        <el-dialog width="65%" title="配置提示信息" @close="resetFormMessage('ruleFormMessage')" v-model="ruleFormMessage.dialogFormVisibleMessage" :show-close=true>
            <el-form :model="ruleFormMessage" ref="ruleFormRefMessage" label-width="180px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="evalContent" label="提示信息">
                            <el-input type="textarea" autosize v-model="ruleFormMessage.evalContent" clearable  placeholder="请配置提示信息"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetFormMessage('ruleFormMessage')">取 消</el-button>
          <el-button type="primary" @click="submitFormMessage('ruleFormMessage')">确 定</el-button>
        </span>
            </template>
        </el-dialog>
        <!--配置优惠券-->
        <el-dialog width="65%" title="券数量" @close="resetFormVip()" v-model="reDataForm.dialogFormVisibleVip" :show-close=true>
            <el-form :model="reDataForm" ref="ruleFormRefMessage" label-width="180px" class="demo-ruleForm">
                <el-table border stripe :data="reDataForm.tableVip" style="width: 100%">
                    <el-table-column prop="vipManageName" label="会员等级"> </el-table-column>
                    <el-table-column prop="couponInformationName" label="优惠券"> </el-table-column>
                    <el-table-column prop="couponCount" label="数量">
                        <template #default="scope">
                            <el-form-item :prop="'tableVip.' + scope.$index + '.couponCount'" label-width="0px">
                                <el-input type="number" clearable v-model="scope.row.couponCount" placeholder="请输入" @change="((val)=>{validatePositiveInteger(val,scope.$index)})"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="fenye"
                        @current-change="handleCurrentChangeVip"
                        :current-page="optionVip.page"
                        :page-size="5"
                        prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
                        next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
                        background
                        layout="total, prev, pager, next, jumper"
                        :total="reDataForm.totalvip"
                >
                </el-pagination>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetFormVip()">取 消</el-button>
          <el-button type="primary" @click="submitFormVip()">确 定</el-button>
        </span>
            </template>
        </el-dialog>
    </div>
</template>

<!-- 本页的js -->
<script>
    import {nextTick, reactive, ref} from "vue";
    import {
        getBannerManagementList,
        delBannerManagement,
        setStatusAsync,
        getCityTimeList,
        addBannerManagement,
        addBannerVipCoupon,
        getBannerManagementById,
        getVipCouponById,
        getVipCouponByIdK,
        delVipCoupon,
        updateBannerManagement,
        updateVipCoupon,
        getvipmanage,
        getCouponsRule,
        updateVipCouponCount
    } from "../http/bannermanagement";
    import { putToOSS, getFileNameUUID,getFileExtensions } from '@/http/oss'
    import { ElMessage } from "element-plus";
    import QuillEditor from "../components/QuillEditor";
    import {FullTimeFormat} from "../utils/common";
    import {getCityList,UpdateEvalContent,getEvalContent} from "../http/api";
    import { useRouter } from "vue-router";
    export default {
        components: {
            QuillEditor
        },
        name: "BannerManagement",
        setup() {
            const quillEditorRef = ref(null);
            const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
            const ruleFormRefMessage = ref(null);//表单不能用this.$,取一个重名的
            //跳转到分类设置页面
            const router = useRouter();
            //声明数据table表格的内容///////////////////////////////////
            let data = reactive({
                table: [],
                cityListData:[],
                pages: 0,
                total: 0,
                bannerPositionListData:[
                    //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
                    {id:1,name:'开屏广告'},
                    {id:2,name:'四屏广告'},
                    /*{id:3,name:'三屏广告B'},
                    {id:4,name:'三屏广告C'}*/
                ],
                bannerTypeListData:[
                    //广告类型（1：领券，2：报名，3：推荐好友）
                    {id:1,name:'领券'},
                    {id:2,name:'报名'},
                    {id:3,name:'推荐好友'},
                    {id:4,name:'门店预约'}
                ],
                statusListData:[
                    //活动状态（1：未开始，2：进行中，3：已结束）
                    {id:1,name:'未开始'},
                    {id:2,name:'进行中'},
                    {id:3,name:'已结束'}
                ],
                optionsCity:[]
            });
            let reData = reactive({
                bannerPositionListData:[
                    //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
                    {id:1,name:'开屏广告'},
                    {id:2,name:'四屏广告'},
                    /*{id:3,name:'三屏广告B'},
                    {id:4,name:'三屏广告C'}*/
                ],
                vipManageList:[],
                //自动启用时间是否禁用
                timeFlag:false,
                pickerOptions:[],
                dialogVisible:false,
                dialogVisibleKp:false,
                dialogVisibleDetail:false,
                vipManageFlag:true,
                isIndeterminate:false,
                applicationTasks:[
                    {id:"1",name:"姓名"},
                    {id:"2",name:"电话"},
                    {id:"3",name:"城市"},
                    {id:"4",name:"地址"}
                ],
                checkAll:false,
                radioOne:null,
                radioTwo:null,
                /*couponListData:[
                    {id:"1",name:"食品券"},
                    {id:"2",name:"银两券"},
                    {id:"3",name:"饮料券"},
                    {id:"4",name:"中文券"},
                    {id:"5",name:"英语券"}
                ]*/
                couponListData:[],
            });
            let reDataForm = reactive({
                dialogFormVisibleVip:false,
                tableVip:[],
                totalvip:0
            });

            //声明查询条件
            let option = reactive({
                bannerPosition: null,
                bannerType:null,
                status:null,
                page: 1,
                pageSize: 10
            });

            let indexMethod =(index) => {
                return index+(option.page-1)*option.pageSize+1;
            }
            //图片回显
            let handleImgDelete = () =>{
                ruleForm.img = false;
                ruleForm.bannerImgURL = null;
                ruleForm.upload = false;
            }
            //开屏广告图片回显
            let handleImgDeleteKp = () =>{
                ruleForm.imgKp = false;
                ruleForm.bannerImgURLKp = null;
                ruleForm.uploadKp = false;
            }
            //广告详情图片回显
            let handleImgDeleteDetail = () =>{
                ruleForm.imgDetail = false;
                ruleForm.bannerDetails = null;
                ruleForm.uploadDetail = false;
            }
            //获取项目列表方法
            let getBannerManagementListFun = async (obj) => {
                if (option.bannerPosition == "") {
                    option.bannerPosition = null;
                    obj.bannerPosition = null;
                }
                if (option.bannerType == "") {
                    option.bannerType = null;
                    obj.bannerType = null;
                }
                if (option.status == "") {
                    option.status = null;
                    obj.status = null;
                }
                let arr = await getBannerManagementList(obj);
                /*arr.items.filter(item => {
                    item.isDefault = item.isDefault == true? '是':'否'
                })*/
               //格式化日期
                arr.items.filter(item => {
                    item.creationTime = FullTimeFormat(item.creationTime);
                })
                data.total = arr.totalCount;
                data.table = arr.items;
            };

            //初始化时，获取初始化数据
            getBannerManagementListFun(option);

            //点击页码获取数据
            let handleCurrentChange = (index) => {
                option.page = index;
                getBannerManagementListFun(option);
            };
            let handleCurrentChangeVip = (index) => {
                optionVip.page = index;
                getCounponName(optionVip);
            };
            //查询方法,此处需要根据条件到后台进行筛选
            let getTabList = async () => {
                getBannerManagementListFun(option);
            };

            //表单参数//////////////////////////////////////////////////
            let ruleForm = reactive({
                id:null,
                dialogFormVisible: false,
                addOrUpdate:0, //0新增，1更新
                bannerType:null,
                bannerName:"",
                bannerCity:[],
                bannerPosition:null,
                bannerStartTime:null,
                taskTime:null,
                bannerImgURL:null,
                bannerImgURLKp:null,
                applicationTasks:[],
                peopleDayMaxCount:null,
                dayMaxCount:null,
                peopleMaxCount:null,
                coupon:[],
                accumulatePointsOne:null,
                inviteNumber:null,
                accumulatePointsTwo:null,
                couponInformationOne:[],
                couponInformationTwo:[],
                fileList:[],
                fileLists:[],
                bannerDetails:null,
                img:true,
                imgKp:true,
                imgDetail:true,
                upload:false,
                uploadKp:false,
                uploadDetail:false,
                fileListKp:[],
                fileListDetail:[],
                status:null,
                shareImage:"",
            });
            let ruleFormMessage = reactive({
                id:null,
                dialogFormVisibleMessage: false,
                evalContent:null,
            });
            let submitFormVip = async () => {
                await updateVipCouponCount({
                    vipCount:reDataForm.tableVip
                });
                resetFormVip();
            };
            //提交表单
            let submitForm = async (formName) => {
                let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
                ruleFormRef.value.validate((valid) => {
                    if (!valid) {
                        con = true;
                        return false;
                    }
                });
                if (con) {
                    return false;
                }

                let bannerMagId = null;
                //修改
                if(ruleForm.addOrUpdate==1){
                    bannerMagId = ruleForm.id
                }
                //启用状态进行校验
                if(ruleForm.status == "1"){
                    //提交验证（//规则设置判断城市和时间是否重复，如果重复需要有提示信息（select 城市和时间，））广告唯一性
                    let bannerCity = ruleForm.bannerCity.join("，");
                    let cityTime = {
                        bannerCity: bannerCity,
                        bannerType:ruleForm.bannerType,
                        taskStartTime:new Date(ruleForm.taskTime[0]),
                        taskEndTime:new Date(ruleForm.taskTime[1]),
                        id:bannerMagId
                    };
                    let cityTimeResult = await getCityTimeList(cityTime);
                    if(cityTimeResult.code != "200"){
                        ElMessage.error({
                            message: cityTimeResult.message,
                            duration: 2000
                        });
                        return false;
                    }
                }

                // let content = quillEditorRef.value.entity.content;
                // ruleForm.bannerDetails = content;
                //活动状态
                let status = null;
                if(Date.now() < new Date(ruleForm.taskTime[0])){
                    status = 1;
                }else if(Date.now() > new Date(ruleForm.taskTime[1])){
                    status = 3;
                }else{
                    status = 2;
                }
                if(ruleForm.bannerStartTime==null || ruleForm.bannerStartTime==""){
                    ruleForm.bannerStartTime = ruleForm.taskTime[0];
                }
                if(ruleForm.peopleDayMaxCount==null){
                    ruleForm.peopleDayMaxCount = 0;
                }else if(ruleForm.bannerType != 1){
                    ruleForm.peopleDayMaxCount = 0;
                }
                if(ruleForm.dayMaxCount==null){
                    ruleForm.dayMaxCount=0;
                }else if(ruleForm.bannerType != 1){
                    ruleForm.dayMaxCount = 0;
                }
                if(ruleForm.peopleMaxCount==null){
                    ruleForm.peopleMaxCount=0;
                }else if(ruleForm.bannerType != 1){
                    ruleForm.peopleMaxCount = 0;
                }
                if(ruleForm.accumulatePointsOne==null){
                    ruleForm.accumulatePointsOne=0;
                }else if(ruleForm.bannerType != 3){
                    ruleForm.accumulatePointsOne = 0;
                }
                if(ruleForm.inviteNumber==null){
                    ruleForm.inviteNumber=0;
                }else if(ruleForm.bannerType != 3){
                    ruleForm.inviteNumber = 0;
                }
                if(ruleForm.accumulatePointsTwo==null){
                    ruleForm.accumulatePointsTwo=0;
                }else if(ruleForm.bannerType != 3){
                    ruleForm.accumulatePointsTwo = 0;
                }
                let applicationTasks = null;
                let couponInformation = null;
                let consumptionCoupon = null;
                if(ruleForm.bannerType == 2 && ruleForm.applicationTasks != null && ruleForm.applicationTasks.length >0){
                    applicationTasks = ruleForm.applicationTasks.join(',');
                }
                if(ruleForm.bannerType ==3){
                    if(ruleForm.couponInformationOne != null && ruleForm.couponInformationOne.length >0 && reData.radioOne == "2"){
                        couponInformation = ruleForm.couponInformationOne.join(',');
                    }
                    if(ruleForm.couponInformationTwo != null && ruleForm.couponInformationTwo.length >0 && reData.radioTwo == "2"){
                        consumptionCoupon = ruleForm.couponInformationTwo.join(',');
                    }
                }
                if(ruleForm.bannerType != 1){
                    //删除领券
                    await delVipCoupon({"bannerManagementId":ruleForm.id});
                }
                if(ruleForm.addOrUpdate==1){ //更新
                    let data={
                        "id":ruleForm.id,
                        "bannerName": ruleForm.bannerName,
                        "bannerPosition": ruleForm.bannerPosition,
                        "bannerCity": ruleForm.bannerCity.join('，'),
                        "bannerType": ruleForm.bannerType,
                        "status":Number(ruleForm.status),
                        "taskStartTime": ruleForm.taskTime[0],
                        "taskEndTime": ruleForm.taskTime[1],
                        "bannerStartTime":new Date(ruleForm.bannerStartTime),
                        "bannerImgURL": ruleForm.bannerImgURL,
                        "bannerImgURLKp": ruleForm.bannerImgURLKp,
                        "peopleDayMaxCount":ruleForm.peopleDayMaxCount,
                        "dayMaxCount":ruleForm.dayMaxCount,
                        "peopleMaxCount":ruleForm.peopleMaxCount,
                        "applicationTaskItem": applicationTasks,
                        "accumulatePoints":ruleForm.accumulatePointsOne,
                        "inviteNumber":ruleForm.inviteNumber,
                        "couponInformation":couponInformation,
                        "consumptionPoints":ruleForm.accumulatePointsTwo,
                        "consumptionCoupon":consumptionCoupon,
                        "bannerDetails": ruleForm.bannerDetails,
                        "shareTitle": ruleForm.shareTitle,
                        "shareImage": ruleForm.shareImage ,
                        "shareDescription": ruleForm.shareDescription
                    }
                    await updateBannerManagement(data);
                    if(ruleForm.id !=null || ruleForm.id != ""){
                        if(ruleForm.bannerType == 1){
                            //领券类型，并且券信息不为空时进行插入
                            let vipCouponMap = {};
                            if(reData.vipManageList != null && reData.vipManageList.length >0){
                                for(let i=0;i<reData.vipManageList.length;i++){
                                    let vipId = reData.vipManageList[i].id;
                                    if(ruleForm.coupon[i] != null && ruleForm.coupon[i].length > 0){
                                        vipCouponMap[vipId] = ruleForm.coupon[i];
                                    }
                                }
                            }
                                let typeData = {
                                    "bannerManagementId": ruleForm.id,
                                    "vipCouponMap": vipCouponMap
                                }
                                await updateVipCoupon(typeData);
                        }
                    }
                }else {//新增
                    let data= {
                        "bannerName": ruleForm.bannerName,
                        "bannerPosition": ruleForm.bannerPosition,
                        "bannerCity": ruleForm.bannerCity.join('，'),
                        "bannerType": ruleForm.bannerType,
                        "status":Number(ruleForm.status),
                        "taskStartTime": ruleForm.taskTime[0],
                        "taskEndTime": ruleForm.taskTime[1],
                        "bannerStartTime":new Date(ruleForm.bannerStartTime),
                        "bannerImgURL": ruleForm.bannerImgURL,
                        "bannerImgURLKp": ruleForm.bannerImgURLKp,
                        "peopleDayMaxCount":ruleForm.peopleDayMaxCount,
                        "dayMaxCount":ruleForm.dayMaxCount,
                        "peopleMaxCount":ruleForm.peopleMaxCount,
                        "applicationTaskItem": applicationTasks,
                        "accumulatePoints":ruleForm.accumulatePointsOne,
                        "inviteNumber":ruleForm.inviteNumber,
                        "couponInformation":couponInformation,
                        "consumptionPoints":ruleForm.accumulatePointsTwo,
                        "consumptionCoupon":consumptionCoupon,
                        "bannerDetails": ruleForm.bannerDetails,
                        "shareTitle": ruleForm.shareTitle,
                        "shareImage": ruleForm.shareImage ,
                        "shareDescription": ruleForm.shareDescription
                    }
                   let bannerId = await addBannerManagement(data);
                    if(bannerId != null && (bannerId.id !=null || bannerId.id != "")){
                        if(ruleForm.bannerType == 1) {
                            //领券类型，并且券信息不为空时进行插入
                            let vipCouponMap = {};
                            if (reData.vipManageList != null && reData.vipManageList.length > 0) {
                                for (let i = 0; i < reData.vipManageList.length; i++) {
                                    let vipId = reData.vipManageList[i].id;
                                    if (ruleForm.coupon[i] != null && ruleForm.coupon[i].length > 0) {
                                        vipCouponMap[vipId] = ruleForm.coupon[i];
                                    }
                                }
                            }
                            if (vipCouponMap != null && vipCouponMap != {}) {
                                let typeData = {
                                    "bannerManagementId": bannerId.id,
                                    "vipCouponMap": vipCouponMap
                                }
                                await addBannerVipCoupon(typeData);
                            }
                        }
                    }else{
                        ElMessage.error({
                            message: "提交失败！",
                            duration: 2000
                        });
                    }
                }
                getBannerManagementListFun(option);
                resetForm();
            };
            let submitFormMessage = async (formName) => {
                await UpdateEvalContent({"evalContent":ruleFormMessage.evalContent, "evalType":12});
                resetFormMessage();
            }
            //获取城市列表方法
            let getCityListDataFun = async (obj) => {
                let cityList = await getCityList(obj);
                data.cityListData = cityList;
                data.cityListData.push({id:'0',name:'全国'});
            };
            getCityListDataFun({ });
            //点击新增按钮
            let AddClick = async () => {
                ruleForm.dialogFormVisible = true; //显示出表单
                ruleForm.addOrUpdate = 0;
                ruleForm.taskTime = null;
                ruleForm.upload = false;
                ruleForm.imageDivs = false;
            };
            //配置提示信息
            let setMessage = async () => {
                ruleFormMessage.dialogFormVisibleMessage = true;
                //数据回显
                let message = await getEvalContent({evalType: 12});
                if(message != null && message.length > 0){
                    ruleFormMessage.evalContent = message[0].evalContent
                }
            };
            //修改的表单验证
            let rules = reactive({
                bannerName: [
                    { required: true, message: "请填写广告名称" }
                ],
                bannerCity: [
                    { required: true, message: "请选择适用城市" }
                ],
                shareTitle:[ { required: true, message: "请填写分享标题" }],
                bannerType: [{ required: true, message: "请选择广告类型" }],
                bannerPosition: [{ required: true, message: "请选择广告位置" }],
                taskTime:[{ required: true, message: "请选择活动时间" },],
                bannerImgURL:[{ required: true, message: "请上传广告图" },],
                bannerImgURLKp:[{ required: true, message: "请上传背景图" },],
                status:[{ required: true, message: "请选择是否启用" },]
            });
            let validatePositiveInteger = (value,index) => {
                const reg = /^\d+$/; // 正整数的正则表达式
                if (!reg.test(value)) {
                   reDataForm.tableVip[index].couponCount = 0;
                    ElMessage.error({
                        message: "请输入非负整数！",
                        duration: 2000
                    });
                }
            };
            //点击修改按钮,弹框，默认把原来的数据显示出来
            let UpdateClick = async (obj) => {
                ruleForm.addOrUpdate=1;
                ruleForm.dialogFormVisible = true; //显示出表单
                ruleForm.upload = true;
                ruleForm.img = true;
                ruleForm.imgKp = true;
                ruleForm.imgDetail = true;
                ruleForm.id = obj.id;
                //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
                let bannerManagement = await getBannerManagementById({ id: obj.id });
                /*if(obj.bannerType != 4){
                reData.bannerPositionListData = [
                    //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
                    {id:1,name:'开屏广告'},
                    {id:2,name:'三屏广告A'},
                    {id:3,name:'三屏广告B'},
                    {id:4,name:'三屏广告C'}
                ];}else{
                    reData.bannerPositionListData = [
                        //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
                        {id:1,name:'开屏广告'}
                        ]
                }*/
                reData.timeFlag = false;
                reData.dialogVisible = false;
                reData.dialogVisibleKp = false;
                reData.dialogVisibleDetail = false;
                ruleForm.bannerType=bannerManagement.bannerType;
                ruleForm.bannerName=bannerManagement.bannerName;
                ruleForm.bannerCity=bannerManagement.bannerCity.split('，');
                ruleForm.bannerPosition=bannerManagement.bannerPosition;
                ruleForm.taskTime = [];
                ruleForm.taskTime.push(bannerManagement.taskStartTime);
                ruleForm.taskTime.push(bannerManagement.taskEndTime);
                reData.pickerOptions = ruleForm.taskTime;
                ruleForm.bannerStartTime = bannerManagement.bannerStartTime;
                ruleForm.status = String(bannerManagement.status);
                ruleForm.bannerImgURL = bannerManagement.bannerImgURL;
                ruleForm.bannerImgURLKp = bannerManagement.bannerImgURLKp;
                ruleForm.bannerDetails = bannerManagement.bannerDetails;
                ruleForm.shareTitle = bannerManagement.shareTitle;
                ruleForm.shareImage = bannerManagement.shareImage;
                ruleForm.shareDescription = bannerManagement.shareDescription
                // nextTick(() => {
                //     quillEditorRef.value.$refs['quill-editor'].setHTML(bannerManagement.bannerDetails);
                // })
                if(ruleForm.bannerType != 1){
                    ruleForm.peopleDayMaxCount = null;
                    ruleForm.dayMaxCount = null;
                    ruleForm.peopleMaxCount = null;
                    ruleForm.coupon = [];
                }else{
                    ruleForm.peopleDayMaxCount = bannerManagement.peopleDayMaxCount;
                    ruleForm.dayMaxCount = bannerManagement.dayMaxCount;
                    ruleForm.peopleMaxCount = bannerManagement.peopleMaxCount;
                    //getCouponsRuleListFun({get_type:10,start_date:"2023-01-31 00:00:00",end_date:"2024-01-31 00:00:00"});
                    getCouponsRuleListFun({"get_type":10,"start_date":ruleForm.taskTime[0].replace('T', ' '),"end_date":ruleForm.taskTime[1].replace('T', ' ')});
                }
                if(ruleForm.bannerType == 3){
                    getCouponsRuleListFun({"get_type":10,"start_date":ruleForm.taskTime[0].replace('T', ' '),"end_date":ruleForm.taskTime[1].replace('T', ' ')});
                }
                if(bannerManagement.applicationTaskItem != null && bannerManagement.applicationTaskItem != ""){
                    ruleForm.applicationTasks = bannerManagement.applicationTaskItem.split(',');
                }else{
                    ruleForm.applicationTasks = [];
                }
                reData.isIndeterminate = false;
                if(ruleForm.applicationTasks != null && ruleForm.applicationTasks.length >0) {
                    if(ruleForm.applicationTasks.length == reData.applicationTasks.length){
                        reData.checkAll = true;
                    }
                }else{
                    reData.checkAll = false;
                }
                if(ruleForm.bannerType ==3){
                    if(bannerManagement.couponInformation != null && bannerManagement.couponInformation != ""){
                        reData.radioOne = "2";
                        ruleForm.couponInformationOne = bannerManagement.couponInformation.split(',').map(Number);
                        ruleForm.accumulatePointsOne = null;
                    }else{
                        reData.radioOne = "1";
                        ruleForm.accumulatePointsOne = bannerManagement.accumulatePoints;
                        ruleForm.couponInformationOne = [];
                    }
                    if(bannerManagement.consumptionCoupon != null && bannerManagement.consumptionCoupon != ""){
                        reData.radioTwo = "2";
                        ruleForm.couponInformationTwo = bannerManagement.consumptionCoupon.split(',').map(Number);
                        ruleForm.accumulatePointsTwo = null;
                    }else{
                        reData.radioTwo = "1";
                        ruleForm.accumulatePointsTwo = bannerManagement.consumptionPoints;
                        ruleForm.couponInformationTwo = [];
                    }
                    if(bannerManagement.inviteNumber != null && bannerManagement.inviteNumber != ""){

                        ruleForm.inviteNumber = bannerManagement.inviteNumber;
                    }
                }else{
                    ruleForm.accumulatePointsOne = null;
                    ruleForm.inviteNumber = null;
                    ruleForm.accumulatePointsTwo = null;
                    reData.radioOne = null;
                    reData.radioTwo = null;
                    ruleForm.couponInformationOne = [];
                    ruleForm.accumulatePointsOne = null;
                }
            };
            let imageClose = ()=>{
                ruleForm.eventBg="";
                ruleForm.shareImage="";
                ruleForm.imageDiv = false;
                ruleForm.imageDivs = false;
                ruleForm.imageDivmp3 = false;
            }
            //删除事件
            let deleteClick = async (obj) => {
                let data = {"id": obj.id};
                let delResult = await delBannerManagement(data);
                getBannerManagementListFun(option);
                if(delResult){
                    ElMessage.success({
                        message: "删除成功！",
                        duration: 2000,
                        type: "success",
                    });
                }else{
                    ElMessage.error({
                        message: "删除失败！",
                        duration: 2000
                    });
                }
            };
            //广告位置获取焦点时进行校验，先填写广告类型再填写广告位置
            let positionFocus = () => {
                /*if(ruleForm.bannerType == null || ruleForm.bannerType == ""){
                    ElMessage.error({
                        message: "请先选择广告类型！",
                        duration: 2000
                    });
                    reData.bannerPositionListData = [];
                }*/
            };
            //自动启用时间获取焦点时进行校验，先填写活动时间再填写自动启用时间
            let timeFocus = () => {
                if(ruleForm.taskTime == null){
                    ElMessage.error({
                        message: "请先选择活动时间！",
                        duration: 2000
                    });
                    reData.timeFlag = true;
                }else{
                    reData.timeFlag = false;
                }
            };
            //如果活动时间为空则清空自动启用时间
            let timeChange = (value) => {
                if(reData.timeFlag){
                    ruleForm.bannerStartTime = null;
                }else{
                    if(value != null){
                        if(new Date(value).getTime() < new Date(ruleForm.taskTime[0]).getTime() || new Date(value).getTime() > new Date(ruleForm.taskTime[1]).getTime()){
                            ruleForm.bannerStartTime = null;
                            ElMessage.error({
                                message: "自动启用时间不能在活动时间范围外！",
                                duration: 2000
                            });
                        }
                    }
                }
            };
            //活动时间改变时，进行自动启用时间的范围限制
            let taskChange = (value) => {
                ////校验（活动时间不能小于当前时间，自动启用时间不能在时间范围外，活动时间的开始时间和结束时间不能相等）,,,number的e
                if(value != null){
                    ruleForm.bannerStartTime = null;
                    if(new Date(value[0]).getTime() > Date.now()) {
                        reData.pickerOptions = value;
                        baPickerOptions();
                    }else{
                        ruleForm.taskTime = [];
                        ElMessage.error({
                            message: "活动时间不能小于当前时间！",
                            duration: 2000
                        });
                    }
                    if(new Date(value[0]).getTime() == new Date(value[1]).getTime()){
                        ruleForm.taskTime = [];
                        ElMessage.error({
                            message: "活动时间的开始时间和结束时间不能相等！",
                            duration: 2000
                        });
                    }
                }
                if(ruleForm.taskTime != null && ruleForm.taskTime.length >0){
                   getCouponsRuleListFun({get_type:10,start_date:ruleForm.taskTime[0].replace('T', ' '),end_date:ruleForm.taskTime[1].replace('T', ' ')});
                    //getCouponsRuleListFun({get_type:10,start_date:"2023-01-31 00:00:00",end_date:"2024-01-31 00:00:00"});
                }
            };
            //广告类型值改变时时进行广告位置的赋值
            let positionChange = (value) => {
               /*if(value !=null && value != ""){
                if(value == 4){
                    reData.bannerPositionListData=[
                        {id:1,name:'开屏广告'}
                        ];
                    ruleForm.bannerPosition = null;
                }else{
                    reData.bannerPositionListData=[
                        {id:1,name:'开屏广告'},
                        {id:2,name:'三屏广告A'},
                        {id:3,name:'三屏广告B'},
                        {id:4,name:'三屏广告C'}
                    ];
                }}*/
            };
            //关闭活动,将活动状态变为已结束
            let closeStatus = async (obj) => {
                if(obj != null){
               /*if(obj.status==3){
                   ElMessage.error({
                       message: "活动状态是已结束，不需要点击关闭活动按钮！",
                       duration: 2000
                   });
               }else{
                   let result = await setStatusAsync({"id": obj.id});
                   getBannerManagementListFun(option);
                   if (result.code=="200"){
                       ElMessage.success({
                           message: result.message,
                           duration: 2000
                       });
                   }else {
                       ElMessage.error({
                           message: result.message,
                           duration: 2000
                       });
                   }
               }*/
                     //将启用状态改为禁用状态（直接关闭）
                  if(obj.status == "2"){
                     //将禁用状态改为启用状态（需要判断时间冲突）
                          //提交验证（//规则设置判断城市和时间是否重复，如果重复需要有提示信息（select 城市和时间，））广告唯一性
                          let cityTime = {
                              bannerCity: obj.cityIds,
                              bannerType:obj.bannerType,
                              taskStartTime:new Date(obj.taskStartTime),
                              taskEndTime:new Date(obj.taskEndTime),
                              id:obj.id
                          };
                          let cityTimeResult = await getCityTimeList(cityTime);
                          if(cityTimeResult.code != "200"){
                              ElMessage.error({
                                  message: cityTimeResult.message,
                                  duration: 2000
                              });
                              return false;
                          }
                      }
                 //改变状态（禁用变启用。启用变禁用）
                    let result = await setStatusAsync({"id": obj.id});
                    getBannerManagementListFun(option);
                    if (result.code=="200"){
                        ElMessage.success({
                            message: result.message,
                            duration: 2000
                        });
                    }else {
                        ElMessage.error({
                            message: result.message,
                            duration: 2000
                        });
                    }
                }
            };
            //定义自动启用时间的范围
            let baPickerOptions= (time) => {
                return new Date(time).getTime() > new Date(reData.pickerOptions[1]).getTime() || new Date(time).getTime() < new Date(reData.pickerOptions[0]).getTime()
            };
            //定义活动开始时间的范围
            let taskTimeOptions= (time) => {
                return new Date(time).getTime() < Date.now() - 8.64e7;
            };

            let beforeUpload=(file) =>{
                // 限制上传类型
                const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
                //限制的上限为2M
                const max2M = file.size / 1024 / 1024 < 2;
                if (!fileExtensions) {
                    ElMessage.error({
                        message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
                        duration: 2000
                    });
                }
                if (!max2M) {
                    ElMessage.error({
                        message: "上传文件大小不能超过 2MB!",
                        duration: 2000
                    });
                }
                return fileExtensions && max2M;
            };
            let handleUploads=(option)=> {
                // 生成的文件名，保留文件后缀名，进行拼接
                let objName ="GoodsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
                // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
                putToOSS(`flieName/${objName}`, option.file).then(res => {
                    //console.log(res,'res')
                    // 上传成功之后，转换真实的地址
                    //signatureUrl(`flieName/${objName}`).then(res => {
                    //console.log(res)
                    ruleForm.shareImage=res.url;
                    ruleForm.imageDivs =true;
                    //})
                })
            };
            let handleUpload=(option)=> {
                // 生成的文件名，保留文件后缀名，进行拼接
                let objName ="BannerManagementsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
                // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
                putToOSS(`flieName/${objName}`, option.file).then(res => {
                    // 上传成功之后，转换真实的地址
                    ruleForm.bannerImgURL = res.url;
                })
            };
            //上传开屏广告图
            let handleUploadKp=(option)=> {
                // 生成的文件名，保留文件后缀名，进行拼接
                let objName ="BannerManagementsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
                // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
                putToOSS(`flieName/${objName}`, option.file).then(res => {
                    // 上传成功之后，转换真实的地址
                    ruleForm.bannerImgURLKp = res.url;
                })
            };
            //上传广告详情图
            let handleUploadDetail=(option)=> {
                // 生成的文件名，保留文件后缀名，进行拼接
                let objName ="BannerManagementsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
                // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
                putToOSS(`flieName/${objName}`, option.file).then(res => {
                    // 上传成功之后，转换真实的地址
                    ruleForm.bannerDetails = res.url;
                })
            };
            //预览图片
            let handlePreview = () =>{
             reData.dialogVisible = true;
            };
            let handlePreviews=(file)=>{
                console.log(file);
            };
            let handleRemoves=(file, fileLists)=> {
                console.log(file, fileLists);
            };
            //预览开屏广告图
            let handlePreviewKp = () =>{
                reData.dialogVisibleKp = true;
            };
            //广告详情
            let handlePreviewDetail = () =>{
                reData.dialogVisibleDetail = true;
            };
            //初始化会员管理
            let queryManageListFun = async (obj) => {
                let arr = await getvipmanage(obj);
                reData.vipManageList = arr;
                if (reData.vipManageList == null || reData.vipManageList.length < 1) {
                    //会员管理为空时，会员管理模块隐藏
                    reData.vipManageFlag = false;
                }else{
                    reData.vipManageFlag = true;
                }
            };
            //全选
            let handleCheckAllChange = (val) => {
                ruleForm.applicationTasks = val ? ["1","2","3","4"] : [];
                reData.isIndeterminate = false;
            };
            let handleCheckedCitiesChange=(value) =>{
                let checkedCount = value.length;
                reData.checkAll = checkedCount === reData.applicationTasks.length;
                reData.isIndeterminate = checkedCount > 0 && checkedCount < reData.applicationTasks.length;
            };
            //领券规则number校验
            let isNumber = (value,index) =>{
                let regExp = /^\d+$/;
                if (!regExp.test(value)) {
                    if(index==1){
                        ruleForm.peopleDayMaxCount = null
                    }else if(index==2){
                        ruleForm.dayMaxCount = null
                    }else if(index==3){
                        ruleForm.peopleMaxCount = null
                    }else if(index==4){
                        ruleForm.accumulatePointsOne = null
                    }else if(index==6){
                        ruleForm.inviteNumber = null
                    }
                    else {
                        ruleForm.accumulatePointsTwo = null
                    }
                    ElMessage.error({
                        message: "请输入非负整数！",
                        duration: 2000
                    });
                }
            };
            //适用城市全国不能和其他城市并存
            let bannerCityChange = (value) => {
               if(value.length > 0){
                    if(value.indexOf("0") > -1){
                        ruleForm.bannerCity = ["0"];
                    }
               }
            };
            //表单重置
            let resetForm = () => {
                ruleForm.dialogFormVisible = false;
                reForm();
                nextTick(() => {
                    quillEditorRef.value.$refs['quill-editor'].setHTML('');
                })
            };
            //提示信息表单重置
            let resetFormMessage= () => {
                ruleFormMessage.dialogFormVisibleMessage = false;
                ruleFormMessage.evalContent = null;
            };
            //领券数量重置
            let resetFormVip= () => {
                reDataForm.dialogFormVisibleVip = false;
                reDataForm.tableVip = [];
            };
            let reForm = () =>{
                ruleFormRef.value.resetFields();
                //广告上传的广告el-u消除
                reData.bannerPositionListData = [
                    //广告位置（1：开屏广告，2：三屏广告A，3：三屏广告B，4：三屏广告C）
                    {id:1,name:'开屏广告'},
                    {id:2,name:'四屏广告'},
                   /* {id:3,name:'三屏广告B'},
                    {id:4,name:'三屏广告C'}*/
                ];
                reData.timeFlag = false;
                reData.pickerOptions = [];
                reData.dialogVisible = false;
                reData.dialogVisibleKp = false;
                reData.dialogVisibleDetail = false;
                if(reData.vipManageList != null && reData.vipManageList.length>0){
                    reData.vipManageFlag = true;
                }else{
                    reData.vipManageFlag = false;
                }
                reData.isIndeterminate = false;
                reData.checkAll = false;
                reData.radioOne = null;
                reData.radioTwo = null;
                ruleForm.fileList = [];
                ruleForm.fileLists = [];
                ruleForm.fileListKp = [];
                ruleForm.fileListDetail = [];
                ruleForm.dialogFormVisible = false;
                ruleForm.coupon = [];
                reData.couponListData = [];
                ruleForm.peopleDayMaxCount = null;
                ruleForm.dayMaxCount = null;
                ruleForm.peopleMaxCount = null;
                ruleForm.bannerType=null;
                ruleForm.bannerName="";
                ruleForm.bannerCity=[];
                ruleForm.bannerPosition=null;
                /*ruleForm.taskTime = null;*/
                ruleForm.bannerStartTime = null;
                ruleForm.bannerImgURL = null;
                ruleForm.applicationTasks = [];
                ruleForm.accumulatePointsOne = null;
                ruleForm.inviteNumber = null;
                ruleForm.accumulatePointsTwo = null;
                ruleForm.couponInformationOne = [];
                ruleForm.couponInformationTwo = [];
                ruleForm.bannerDetails = null;
                ruleForm.status = null;
            };
            //查看明细
            let ViewDetail = (obj) => {
                if(obj["bannerType"] == 1){
                    router.push({name: "UserCoupons", params: {bannerManagementId: obj.id}})
                }else if(obj["bannerType"] == 2){
                    router.push({name: "ApplicationProject", params: {bannerManagementId: obj.id}})
                }else if(obj["bannerType"] == 3){
                    router.push({name: "RecommendFriend", params: {bannerManagementId: obj.id}})
                }
            };
            queryManageListFun();
            //获取项目列表方法
            let getCouponsRuleListFun = async (obj) => {
                let arr = await getCouponsRule(obj);
                reData.couponListData = arr.data.coupon_rule_list;
                if(ruleForm.addOrUpdate == 1 && ruleForm.bannerType == 1){
                let vipCoupon = await getVipCouponByIdK({ "bannerManagementId": ruleForm.id});
                if(reData.vipManageList != null && reData.vipManageList.length>0) {
                    reData.vipManageFlag = true;
                    if(vipCoupon != null && vipCoupon.length >0){
                        /*for(let idx = 0;idx < vipCoupon.length;idx++){*/
                            for (let index = 0; index < reData.vipManageList.length;index++){
                                ruleForm.coupon[index] = vipCoupon.filter(item => reData.vipManageList[index].id == item.vipManageId).map(item => item.couponInformation).map(Number);
                                /*if(vipCoupon[idx].vipManageId == reData.vipManageList[index].id) {
                                    ruleForm.coupon[index] = vipCoupon[idx].couponInformation.split(',').map(Number);
                                    //将string数组转为int数组
                                    break;
                                }*/
                            /*}*/
                        }
                    }else{
                        ruleForm.coupon = [];
                    }
                }else{
                    //会员和优惠券
                    ruleForm.coupon = [];
                }
                }
            };
            let couponFocus = async () => {
                if(ruleForm.taskTime == null || ruleForm.taskTime.length <1){
                    ElMessage.error({
                        message: "请先选择活动时间！",
                        duration: 2000
                    });
                }
            };
            let countDetail = async (event) => {
                //弹窗并且显示数据
                reDataForm.dialogFormVisibleVip = true; //显示出表单
                optionVip.bannerManagementId = event.id;
                getCounponName(optionVip);
            };
            //声明查询条件
            let optionVip = reactive({
                bannerManagementId: null,
                page: 1,
                pageSize: 5
            });
            let getCounponName = async (obj) => {
                let tableVip = await getVipCouponById(obj);
                /*data.total = arr.totalCount;*/
                reDataForm.tableVip = tableVip.items;
                reDataForm.totalvip = tableVip.totalCount;
            };
            return {
                data,
                reData,
                reForm,
                handleCurrentChange,
                handleCurrentChangeVip,
                handleCheckAllChange,
                handleCheckedCitiesChange,
                isNumber,
                imageClose,
                getTabList,
                handlePreviews,
                handlePreviewDetail,
                option,
                optionVip,
                getCounponName,
                ViewDetail,
                handleImgDelete,
                UpdateClick,
                handleUploads,
                AddClick,
                indexMethod,
                deleteClick,
                positionFocus,
                positionChange,
                closeStatus,
                getCityListDataFun,
                timeFocus,
                timeChange,
                taskChange,
                handlePreview,
                baPickerOptions,
                taskTimeOptions,
                handleRemoves,
                bannerCityChange,
                reDataForm,
                ruleForm,
                ruleFormMessage,
                rules,
                ruleFormRef,
                ruleFormRefMessage,
                beforeUpload,
                validatePositiveInteger,
                handleUpload,
                submitForm,
                submitFormMessage,
                resetForm,
                resetFormMessage,
                quillEditorRef,
                resetFormVip,
                couponFocus,
                submitFormVip,
                handlePreviewKp,
                handleImgDeleteKp,
                handleImgDeleteDetail,
                handleUploadKp,
                handleUploadDetail,
                setMessage,
                countDetail
            };
        }
    };
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
    .box{

    }
    .el-image-viewer__close {
        top: 40px;
        right: 40px;
        width: 40px;
        height: 40px;
        font-size: 24px;
        color: #fff;
        background-color:rgba(255, 255, 255, 0)!important;
    }
    .select-box {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;
        > div {
            width: 15%;
            display: flex;
            align-items: center;
            span {
                width: 100px;
            }
            margin-right: 25px;
        }
    }
    .fenye {
        margin-top: 20px;
    }
</style>
