import $http from "./index";
import qs from "qs";
import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;


////////服务评价列表////////
export const getServiceEvaluation = (data) => {
    return $http.get(
        `${base}/app/info/serviceevaluation/back/pagelist`,
        {
            params: data,
            timeout: 100000,
        }
    );
};
export const getEvaluationdetail = (data) => {
    return $http.get(
        `${base}/app/info/serviceevaluation/back/getdetail`,
        {
            params: data,
            timeout: 100000,
        }
    );
};
export const ExportEvaluation = (data) => {
    return $http.get(
        `${base}/app/info/serviceevaluation/back/export`,
        {
            params:data
        }
    );
};
//重推服务评价
export const uploadEvalConfig = (data) => {
    return $http.get(
        `${base}/app/info/serviceevaluation/uploadEvalConfig`,
        {
            params: data,
            timeout: 100000,
        }
    );
};



