import $http from "./index";
import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;

export const createGoods = (data) => {
    return $http.post(
        `${base}/app/business/goods/back/create`,
        data
    );
};
export const getGoodsList = (data) => {
    return $http.post(
    `${base}/app/business/goods/back/pagelist`,
        data
    );
};
export const updateVirtualamount = (data) => {
    return $http.get(
        `${base}/app/business/goods/back/update-virtualamount`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
export const setGoodsListPic = (data) => {
    return $http.post(
        `${base}/app/business/goods/back/setlistpic`,
        data
    );
};
export const setGoodsDescPic = (data) => {
    return $http.post(
        `${base}/app/business/goods/back/setdescpic`,
        data
    );
};
export const getGoodsImg = (data) => {
    return $http.get(
        `${base}/app/business/goods/back/getgoodsimg`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
export const setGoodsActive = (data) => {
    return $http.get(
        `${base}/app/business/goods/back/setactive`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
export const getCategoryList = (data) => {
    return $http.get(
        `${base}/app/business/category/getall`,
        {
            params: data,
            timeout: 1000,
        }
    );
};