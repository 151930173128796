<template>
  <div>
    <div class="select-box">
      <!-- 1.筛选条件 -->
      <div>
        <span>会员ID:</span>
        <el-input v-model="option.memberID" clearable placeholder="请输入会员ID"></el-input>
      </div>
      <div>
        <span>会员微信手机:</span>
        <el-input v-model="option.userPhone" clearable placeholder="请输入会员微信手机"></el-input>
      </div>
      <div>
        <span>会员昵称:</span>
        <el-input v-model="option.nickName" clearable placeholder="请输入会员昵称"></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查询</el-button>
        <el-button @click="deleteClick" type="primary">解绑微信</el-button>
<!--        <el-button @click="openidClick" type="primary">获取公众号用户</el-button>-->
      </div>
    </div>

    <!--2. table表格的内容-->
    <el-table id="table" border stripe :data="data.table" style="width: 100%" @selection-change="handleSelectionChange">
      <!-- <el-table-column prop="id" label="ID" v-if="false"> </el-table-column> 隐藏列 -->
      <!--        <el-table-column
                type="index" :index="indexMethod"
                width="50" style="text-align: center;">
              </el-table-column>-->
      <el-table-column width="55" align="center" type="selection"></el-table-column>

      <el-table-column prop="memberID" label="会员ID"></el-table-column>
      <el-table-column prop="nickName" label="会员昵称"></el-table-column>
      <el-table-column prop="userPhone" label="会员微信手机号"></el-table-column>
      <el-table-column prop="memberPhone" label="会员绑定手机号"></el-table-column>
      <el-table-column prop="lastModificationTime" label="绑定时间"></el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
        class="fenye"
        @current-change="handleCurrentChange"
        :current-page="option.page"
        :page-size="10"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="total, prev, pager, next, jumper"
        :total="data.total"
    ></el-pagination>

    <el-dialog title="温馨提示" v-model="data.deleteDialogVisible" width="30%" center>
      <div style="text-align: center;margin-bottom: 50px;">确定要清空吗？</div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="cancelDelete">取消</el-button>
        <el-button type="primary" @click="confirmDelete">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive } from "vue";
// eslint-disable-next-line no-unused-vars
import {delBackUser, getMemberList, getopenidList} from "../http/api";
import { FullTimeFormat, PhoneNumberFormat } from "../utils/common";
import { ElMessage } from "element-plus";
import { Deletephone } from "../http/api";

export default {
  name: "Complaint",
  setup() {
    // 声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      srcList: [""],
      selectedItems: [], // 选中的数据
      deleteDialogVisible: false, // 对话框可见性
    });
    // 声明查询条件
    let option = reactive({
      // ClientName: '',
      // ClientPhone: '',
      page: 1,
      pageSize: 10,
      nickName: "",
      userPhone: "",
      memberID: "",
    });
    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    }
    // 获取会员列表方法
    let getComplaintListFun = async (obj) => {
      let arr = await getMemberList(obj);
      arr.items.forEach((item) => {
        item.lastModificationTime = FullTimeFormat(item.lastModificationTime);
        item.userPhone = PhoneNumberFormat(item.userPhone);
        item.memberPhone = PhoneNumberFormat(item.memberPhone);
      });
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    let getopenidListFun = async (obj) => {
      await getopenidList(obj);
    };

    // 初始化时，获取初始化数据
    getComplaintListFun({
      page: option.page,
      pageSize: option.pageSize,
      nickName: option.nickName,
      userPhone: option.userPhone,
      memberID: option.memberID,
    });
    // 点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getComplaintListFun(option);
    };
    /** 图片查看 */
    let getImgList=(workPhoto, index) =>{
      let arr = []
      if (workPhoto.length == 1) {
        arr.push(workPhoto[0])
      } else if (workPhoto.length == 0)  {
        return arr;
      } else {
        for(let i = 0;i < workPhoto.length;i++){
          arr.push(workPhoto[i+index])
          if(i+index >= workPhoto.length-1){
            index = 0-(i+1);
          }
        }
      }
      return arr;
    };
    // 复选框选中事件
    let handleSelectionChange = (selectedItems) => {
      data.selectedItems = selectedItems;
    };
    // 查询方法,此处需要根据条件到后台进行筛选
    let getTabList = () => {
      option.page = 1;
      getComplaintListFun(option);
    };
    let openidClick= () => {
      let option1 = reactive({
        cs:"1"
      });
      getopenidListFun(option1);
    };
    // 删除事件
    // eslint-disable-next-line no-unused-vars
    let deleteObj = null;
    let deleteClick = (obj) => {
      deleteObj = obj;
      if (data.selectedItems.length === 0) {
        console.log(data.selectedItems.length)
        ElMessage.warning("请选择要解绑微信的会员");
        return;
      }
      data.deleteDialogVisible = true;
    };
    let cancelDelete = () => {
      deleteObj = null;
      data.deleteDialogVisible = false;
    };
    let confirmDelete = async () => {
      let ids = data.selectedItems.map((item) => item.id);
      let res = await Deletephone(ids);
      ElMessage({
        showClose: true,
        message: res ? "解绑成功！" : "解绑失败！请重试",
        type: res ? "success" : "error",
      });
      cancelDelete();
      getTabList();
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      getImgList,
      indexMethod,
      handleSelectionChange,
      deleteClick,
      openidClick,
      cancelDelete,
      confirmDelete,
    };
  },
};
</script>
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 20%;
    display: flex;
    align-items: center;

    span {
      width: auto;
      min-width: 120px;
      max-width: 180px;
    }

    margin-right: 20px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
