<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>权益名称:</span>
        <el-input
            v-model="option.equity"
            clearable
            placeholder="请输入权益名称"
        ></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
        >查 询</el-button
        >
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">
          新增
        </el-button>
        <el-button icon="el-icon-document-add" @click="FanUserDescConfigClick()" type="primary">
          粉丝用户说明配置
        </el-button>
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page]  -->
    <el-table :row-style="setRowStyle" border stripe :data="data.table" style="width:100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column
          type="index" :index="indexMethod"
          width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="shopID" label="shopID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column  prop="benefitTitle" label="权益标题"> </el-table-column>
      <el-table-column  prop="equity" label="权益名称"> </el-table-column>
      <el-table-column prop="sort" label="序号"> </el-table-column>
      <el-table-column label="权益图片" prop="picture" align="center" width="100px">
        <template v-slot="scope">
          <el-image @click="bigImg(scope.row.picture)" style="width: 50px; height: 50px; margin-bottom:-4px"
                    :src=scope.row.picture
                    :preview-src-list="data.srcList">
          </el-image>
          <el-popover placement="top-" tstartrigger="click"> <!--trigger属性值：hover、click、focus 和 manual-->
            <a :href="scope.row.picture" target="_blank" title="查看最大化图片">
              <img :src="scope.row.picture" style="width: 300px;height: 300px">
            </a>
            <img :src="scope.row.picture" style="width: 50px;height: 50px; cursor:pointer">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="creationTime"
          label="创建时间"
          :formatter="formatCreationTime"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
              @click="UpdateClick(scope.row)"
              size="small"
              icon="el-icon-edit"
              type="primary"
          >修改</el-button>
          <el-popconfirm
              title="您确定要删除吗？"
              confirm-button-text='是'
              cancel-button-text='否'
              iconColor="red"
              @confirm="deleteClick(scope.row)"
          >
            <template #reference>
              <el-button size="small" icon="el-icon-delete" type="danger">
                删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 3.分页 -->
    <el-pagination
        class="fenye"
        @current-change="handleCurrentChange"
        :current-page="option.page"
        :page-size="10"
        prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
        next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
        background
        layout="total, prev, pager, next, jumper"
        :total="data.total"
    >
    </el-pagination>
    <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="32%" title="会员权益信息" :show-close=true v-model="ruleForm.dialogFormVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="权益标题" prop="benefitTitle">
          <el-input v-model="ruleForm.benefitTitle" placeholder="请输入权益标题" maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="权益名称" prop="equity"  required>
          <el-input v-model="ruleForm.equity" placeholder="请输入权益名称" maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="序号"  prop="Sort" required>
          <el-input v-model="ruleForm.Sort" type="number" placeholder="数字（从小到大）" autocomplete="off" maxlength="5"></el-input>
        </el-form-item>
        <el-upload ref="myupload"
                   action=""
                   :file-list="ruleForm.fileList"
                   :on-preview="handlePreview"
                   :on-remove="handleRemove"
                   :beforeUpload="beforeUpload"
                   :http-request="handleUpload"
                   :multiple="false"
                   :limit=1
                   accept=".png,.jpg"
        >
          <el-button size="small" type="primary">上传权益图片</el-button>
        </el-upload>
        <div v-if="ruleForm.imageDiv">
          <el-image :src="ruleForm.picture" :fit="fit"></el-image>
          <el-button @click="imageClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
        </div>
        <el-col>
          <p style="color: red;padding-left: 0px ;padding-bottom: 20px">* 图片格式 宽：50px；高：50px；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
        </el-col>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 5.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="60%" title="粉丝用户说明配置" :show-close=true @close="resetForm2('ruleForm2')" v-model="ruleForm2.dialogFormVisibles">
      <el-form :model="ruleForm2" :rules="rules" ref="ruleFormRef2" label-width="100px" class="demo-ruleForm">
        <el-row>
          <el-col :span="24">
            <div id="div1">
              <QuillEditor ref="quillEditorRef" v-model="ruleForm2.evalContent"></QuillEditor>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm2('ruleForm2')">取 消</el-button>
          <el-button type="primary" @click="submitForm2('ruleForm2')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
  import {ref, reactive, onMounted, nextTick} from "vue";
import QuillEditor from "../components/QuillEditor";
import {
  vipequitylist,
  vipequityid,
  vipequityupdate,
  vipequityadd, vipequitydelete
} from "../http/api";
import {ElMessage} from "element-plus";
import {FullTimeFormat} from "../utils/common";
import { putToOSS, getFileNameUUID,getFileExtensions } from '@/http/oss'
  import {getRights, UpdateEvalContent} from "../http/noteconfiguration";
export default {
  components: {
    QuillEditor
  },
  methods: {
    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    }
  },
  name: "Category",
  setup() {
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    const ruleFormRef2 = ref(null);//表单不能用this.$,取一个重名的
    const quillEditorRef = ref(null);
    onMounted(() => {
    });
    //声明数据table表格的内容
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      srcList:[""]
    });
    //声明查询条件
    let option = reactive({
      equity: "",
      page: 1,
      pageSize: 10,
    });
    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    }
    let deleteClick = async (obj) => {
      let data = {"id": obj.id};
      let delResult = await vipequitydelete(data);
      getCategoryListFun(option);
      if(delResult){
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      }else{
        ElMessage.error({
          message: "删除失败！",
          duration: 2000
        });
      }
    };
    //删除上传的图片
    let imageClose = ()=>{
      ruleForm.picture="";
      ruleForm.imageDiv = false;
    }
    let getCategoryListFun = async (obj) => {
      let arr = await vipequitylist(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    getCategoryListFun({ Page: 1, PageSize: 10 });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getCategoryListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await getCategoryListFun(option);
    };
    //修改的表单参数
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      benefitTitle: "",
      equity: "",
      Sort:"",
      picture:"",
      addOrUpdate:0 //0新增，1更新
    });
    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let Category = await vipequityid(obj.id);
      //console.log(Category);
      ruleForm.benefitTitle = Category.benefitTitle;
      ruleForm.equity = Category.equity;
      ruleForm.Sort = Category.sort;
      ruleForm.picture = Category.picture;
      ruleForm.dialogFormVisible = true; //显示出表单
    };
    //点击新增按钮
    let AddClick = async () => {
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleFormRef.value.resetFields();
      ruleForm.addOrUpdate=0;
      ruleForm.benefitTitle = "";
      ruleForm.equity = "";
      ruleForm.Sort = "";
      ruleForm.picture = "";
      //清空照片和文件
       ruleForm.imageDiv = false;
       ruleForm.fileList = [];
    };





    //修改的表单验证
    let rules = reactive({
      equity: [
        { required: true, message: "请输入分类名称" }
      ],
      Sort: [
        { required: true, message: "请输入分类名称" }
      ],
      picture: [{ required: true, message: "请输入序号" }]
    });
    //提交表单,新增或修改项目信息
    let submitForm = async () => {
      let con = false;
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          return false;
        }
      });
      if (con) {
        return false;
      }
      try{
      if(ruleForm.addOrUpdate==1){//更新
        let data={
          "benefitTitle":ruleForm.benefitTitle,
          "equity":ruleForm.equity,
          "Sort":ruleForm.Sort,
          "picture":ruleForm.picture,
          "ID":ruleForm.id
        };
        await vipequityupdate(data);
        ElMessage.success({
          message: "修改成功！",
          duration: 2000,
          type: "success",
        });
      }else{//新增
        let data= {
          "benefitTitle": ruleForm.benefitTitle,
          "equity": ruleForm.equity,
          "Sort":ruleForm.Sort,
          "picture": ruleForm.picture,
        };
        await vipequityadd(data);
        ElMessage.success({
          message: "新增成功！",
          duration: 2000,
          type: "success",
        });
      }
      await getCategoryListFun(option);//重新加载数据
      ruleForm.dialogFormVisible = false;
    }catch (error) {
        ElMessage.error({
          message: "操作失败，请重试！", // 可以根据实际情况修改失败消息
          duration: 2000
        });
        console.error(error); // 输出错误信息到控制台，方便调试
      }
    };
    //表单重置
    let resetForm = () => {
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };


    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
    };
    //大图
    let bigImg=(url)=> {
      data.srcList[0] = url;
    };
    let beforeUpload=(file) =>{
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000
        });
      }
      return fileExtensions && max2M;
    };
    let handleUpload=(option)=> {
      let objName ="GoodsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
      putToOSS(`flieName/${objName}`, option.file).then(res => {
        ruleForm.picture=res.url;
        ruleForm.imageDiv =true;
      })
    };
    let submitUpload=()=>{
      this.$refs.upload.submit();
    };
    let handleRemove=(file, fileList)=> {
      console.log(file, fileList);
    };
    let handlePreview=(file)=>{
      console.log(file);
    };



    //点击粉丝用户说明配置
    let FanUserDescConfigClick = async (option) => {
      ruleForm2.dialogFormVisibles = true; //显示出表单
      let EvalContent = await getRights(option);
      ruleForm2.evalContent = EvalContent[0].evalContent;
      ruleForm2.sort = EvalContent[0].sort;
      nextTick(() => {
        quillEditorRef.value.$refs['quill-editor'].setHTML(ruleForm2.evalContent);
      })
    };

    let ruleForm2 = reactive({
      evalContent: "",
      evalType: ""
    });

    const submitForm2 = async () => {
      if (ruleForm2.evalContent == "" || ruleForm2.evalContent == null || ruleForm2.evalContent == undefined) {
        ElMessage.error({
          message: '内容不能为空！',
          type: "error",
          offset: 130,
          duration: 1500
        });
        return false;
      }
      let content = quillEditorRef.value.entity.content;
      ruleForm2.evalContent = content;
      let data = {
        "evalContent": ruleForm2.evalContent,
        "evalType": 11

      }
      await UpdateEvalContent(data);
      ElMessage.success({
        message: "保存成功！",
        type: "success",
        offset: 130,
        duration: 2000
      });
      await getCategoryListFun(option);//重新加载数据
      ruleForm2.dialogFormVisibles = false;
    };
    //表单重置
    let resetForm2 = () => {
      ruleFormRef2.value.resetFields();
      ruleForm2.dialogFormVisibles = false;
      nextTick(() => {
        quillEditorRef.value.$refs['quill-editor'].setHTML('');
      })
    };



    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      deleteClick,
      ruleForm,
      ruleForm2,
      btnSubmit,
      imageClose,
      rules,
      beforeUpload,
      submitForm,
      submitForm2,
      resetForm,
      resetForm2,
      ruleFormRef,
      ruleFormRef2,
      AddClick,
      FanUserDescConfigClick,
      submitUpload,
      handleRemove,
      handlePreview,
      indexMethod,
      bigImg,
      handleUpload,
      quillEditorRef,
    };
  },
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
/*#div1 {*/
/*  position: relative;*/
/*  width: 1000px;*/
/*  top: 50px;*/

/*}*/
</style>
