<template>
  <div>
    
    <div class="select-box">
      <!-- 1.筛选条件 -->
      <div>
        <span>会员姓名:</span>
        <el-input v-model="option.ClientName" clearable placeholder="请输入姓名"></el-input>
      </div>
      <div>
        <span>会员手机:</span>
        <el-input v-model="option.ClientPhone" clearable placeholder="请输入手机号"></el-input>
      </div>
      <div>
        <span>门店名称:</span>
        <el-input v-model="option.ShopName" clearable placeholder="请输入门店名称"></el-input>
      </div>
      <div>
        <span>开始时间:</span>
          <el-date-picker
          v-model="option.StartTime"
          type="date"
          value-format="YYYY-MM-DD 00:00:00"
          placeholder="开始日期">
        </el-date-picker>
      </div>
      <div>
        <span>结束时间:</span>
          <el-date-picker
          v-model="option.EndTime"
          value-format="YYYY-MM-DD 23:59:59"
          type="date"
          placeholder="结束日期">
        </el-date-picker>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
      </div>
    </div>
    <!--2. table表格的内容-->
    <el-table id="table" border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="clientName" label="会员姓名"> </el-table-column>
      <el-table-column prop="clientPhone" label="会员手机"> </el-table-column>
      <el-table-column prop="shopName" label="门店"> </el-table-column>
      <el-table-column prop="types" label="投诉类型"> </el-table-column>
      <el-table-column prop="problem" label="投诉问题"> </el-table-column>
      <el-table-column prop="creationTime" label="投诉时间"> </el-table-column>
      <el-table-column label="图片" prop="headImgURL" align="center" width="400">
        <template v-slot="scope">
          <el-image v-for="(item, index) in scope.row.complaintImg" :key="index" style="width: 50px; height: 50px; margin-bottom:-4px;margin-right: 10px;"
                    :src=item
                    :preview-src-list="scope.row.complaintImg">
          </el-image>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="8"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;" 
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;" 
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive } from "vue";
import {getComplaintList} from "../http/complaint";

//import { ElMessage } from "element-plus";
export default {
  name: "Complaint",
  setup() {
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      srcList:[""]
    });

    //声明查询条件
    let option = reactive({
      ClientName: '',
      ClientPhone: '',
      page: 1,
      pageSize: 8,
      ShopName:'',
      StartTime:'',
      EndTime:''
    });

    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    } 

    //获取订单列表方法
    let getComplaintListFun = async (obj) => {
      let arr = await getComplaintList(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    getComplaintListFun({ Page: 1, PageSize: 8,payStatus:3 });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getComplaintListFun(option);
    };
    /** 图片查看 */
    let getImgList=(workPhoto, index) =>{
      let arr = []
      if (workPhoto.length == 1) {
        arr.push(workPhoto[0])
      } else if (workPhoto.length == 0)  {
        return arr;
      } else {
        for(let i = 0;i < workPhoto.length;i++){
          arr.push(workPhoto[i+index])
          if(i+index >= workPhoto.length-1){
            index = 0-(i+1);
          }
        }
      }
      return arr;
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      option.page=1;
      //console.log(option);
      getComplaintListFun(option);
    };
    
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      getImgList,
      indexMethod
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 14%;
    display: flex;
    align-items: center;
    span {
      width: 120px;
    }
    margin-right: 20px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
