import $http from "./index";
import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;

//获取投诉列表
export const getComplaintList = (data) => {
    return $http.get(
        `${base}/app/business/Complaint/back/pagelist`,
        {
            params: data,
            timeout: 1000,
        }
    );
};

export const getComplaintTypeList = (data) => {
    return $http.get(
        `${base}/app/business/complaintyype/back/pagelist`,
        {
            params: data,
            timeout: 1000,
        }
    );
};

//删除投诉类型 /app/business/complaintyype/back/delete
export const deleteComplaintType = (data) => {
    return $http.get(
        `${base}/app/business/complaintyype/back/delete`,
        {
            params: data,
            timeout: 1000,
        }
    );
}

// 通过id获取投诉类型
export const getComplaintTypeByID = (data) => {
    return $http.get(
        `${base}/app/business/complaintyype/back/detail`,
        {
            params: data,
            timeout: 1000,
        }
    );
}

//updateComplaintType
export const updateComplaintType = (data) => {
    return $http.post(
        `${base}/app/business/complaintyype/back/update`,
        data
    );
}

//addComplaintType
export const addComplaintType = (data) => {
    return $http.post(
        `${base}/app/business/complaintyype/back/new`,
        data
    );
}
