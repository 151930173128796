<template>
  <div>
    
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>城市名称:</span>
        <el-input
            v-model="option.cityName"
            clearable
            placeholder="请输入名称"
        ></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
      </div>
    </div>

    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"></el-table-column><!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="cityID" label="cityID" v-if="false"></el-table-column><!--隐藏列-->
      <el-table-column prop="cityName" label="城市名称"></el-table-column>
      <el-table-column prop="lat" label="纬度"></el-table-column>
      <el-table-column prop="lng" label="经度"></el-table-column>
      <el-table-column prop="isDefault" label="默认"></el-table-column>
      <el-table-column fixed="right" label="操作" width="300"> <!--操作固定列 -->
        <template #default="scope">
          <el-button
              @click="AddClick(scope.row)"
              icon="el-icon-document-add"
              type="primary"
          >经纬度
          </el-button>
          <el-button
              @click="SetDefaultCity(scope.row)"
              icon="el-icon-document-add"
              type="primary"
          >默认
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
        class="fenye"
        @current-change="handleCurrentChange"
        :current-page="option.page"
        :page-size="10"
        prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
        next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
        background
        layout="total, prev, pager, next, jumper"
        :total="data.total"
    >
    </el-pagination>

    <!-- 4.新增-->
    <el-dialog
        width="45%"
        title="修改经纬度"
        :show-close=true
        v-model="ruleForm.dialogFormVisible"
    >
      <el-form
          :data="ruleForm"
          :rules="rules"
          ref="ruleFormRef"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item label="城市名称" prop="cityName">
          <el-input v-model="ruleForm.cityName"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="lat"  required>
          <el-input v-model="ruleForm.lat"></el-input>
        </el-form-item>
        <el-form-item label="经度" prop="lng" required>
          <el-input v-model="ruleForm.lng"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="温馨提示"
      v-model="data.setDefaultCityDialogVisible"
      width="30%"
      center>
      <div style="text-align: center;margin-bottom: 50px;">确定要设置默认吗？</div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="CancelSetDefalultCity">取 消</el-button>
        <el-button type="primary" @click="ConfirmSetDefaultCity">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<!-- 本页的js -->
<script>
import {ref, reactive} from "vue";
import {
  getCity,
  setlocation,
  setdefault
} from "../http/api";
import {ElMessage} from "element-plus";
import Cookies from 'js-cookie'

export default {
  name: "City",
  setup() {    
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的

    //声明数据table表格的内容///////////////////////////////////
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      setDefaultCityDialogVisible: false
    });

    //声明查询条件
    let option = reactive({
      cityName: "",
      page: 1,
      pageSize: 10,
    });

    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    } 
 
    //获取项目列表方法
    let getCityListFun = async (obj) => {
      let arr = await getCity(obj);

      arr.items.filter(item => {
        item.isDefault = item.isDefault == true? '是':'否'
      })

      data.total = arr.totalCount;
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    getCityListFun({Page: 1, PageSize: 10});

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getCityListFun(option);
    };

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      let token = Cookies.get('name');
      console.log(token);
      getCityListFun(option);
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      cityID: "",
      cityName: "",
      lat: 0,
      lng: 0,
      addOrUpdate: 0, //0新增，1更新
    });

    //点击新增按钮
    let AddClick = async (obj) => {
      ruleForm.addOrUpdate = 0;
      ruleForm.cityName = obj.cityName;
      ruleForm.cityID = obj.cityID;
      ruleForm.lat = obj.lat;
      ruleForm.lng = obj.lng;
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //修改的表单验证
    let rules = reactive({});

    //提交表单,新增或修改项目信息
    let submitForm = async (formName) => {
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          //console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }

      if (ruleForm.addOrUpdate == 1) {//设置城市
        let data = {
          "cityID": ruleForm.cityID,
        }
        await setdefault(data);
        /*        if (setdefault(data)) {
                  ElMessage.success({
                    message: "设置城市成功！",
                  });
                }*/
      } else if (ruleForm.addOrUpdate == 0) {//新增
        //ruleFormRef.value.resetFields();//表单重置
        let data = {
          "cityID": ruleForm.cityID,
          "lat": ruleForm.lat,
          "lng": ruleForm.lng,
        }
        await setlocation(data);
        /*        if (setlocation(data)) {
                  ElMessage.success({
                    message: "修改经纬度成功！",
                  });
                }*/

      }

      getCityListFun(option);//重新加载数据
      //////////////ruleFormRef.value.resetFields();
      // myupload.value.clearFiles();//上传的图片列表清除
      ruleForm.dialogFormVisible = false;
    };

    //表单重置
    let resetForm = (formName) => {
      //console.log( ruleFormRef.value);
      ruleForm.dialogFormVisible = false;
      cityForm.dialogFormVisible = false;
    };

    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
      // ElMessage.error({
      //   message: '姓名不能为空',
      //   type: "error",
      //   offset:130,
      //   duration:1500
      // });
    };


    let defaultObj = '';
    //点击设置默认按钮
    let SetDefaultCity = async (obj) => {
      defaultObj = obj;
      data.setDefaultCityDialogVisible = true;
    }

    let CancelSetDefalultCity =  async () => {
      defaultObj = '';
      data.setDefaultCityDialogVisible = false;
    }
    
    let ConfirmSetDefaultCity =  async () => {
      // defaultObj = '';
      // data.setDefaultCityDialogVisible = false;
     
      let params = {
        cityid: defaultObj.cityID 
      }
       console.log(params);
      let res = await setdefault(params);
      ElMessage({
          showClose: true,
          message: res.message,
          type: 'warning'
        });
      CancelSetDefalultCity();
      getCityListFun({Page: 1, PageSize: 10});
    }

    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      ruleForm,
      btnSubmit,
      rules,
      submitForm,
      resetForm,
      ruleFormRef,
      AddClick,
      defaultObj,
      SetDefaultCity,
      CancelSetDefalultCity,
      ConfirmSetDefaultCity,
      indexMethod
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;

  > div {
    width: 15%;
    display: flex;
    align-items: center;

    span {
      width: 110px;
    }

    margin-right: 25px;
  }
}

.fenye {
  margin-top: 20px;
}
</style>
