<template>
  <div>
    
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>城市:</span>
        <el-select v-model="option.cityid"  placeholder="请选择城市" @change="changeCity">
          <el-option v-for="item in data.cityListData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
      <div>
        <span>门店:</span>
        <el-select v-model="option.shopid" placeholder="请选择门店" @change="getTabList">
          <el-option v-for="item in data.shopListData" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">
          新增商品分类
        </el-button>
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page]  -->
    <el-table :row-style="setRowStyle" border stripe :data="data.table" style="width:100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="shopID" label="shopID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column  prop="name" label="分类名称"> </el-table-column>
       <el-table-column prop="sort" label="排序"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
            @click="UpdateClick(scope.row)"
            size="small"
            icon="el-icon-edit"
            type="primary"
            >修改</el-button>
          <el-popconfirm
              title="您确定要删除吗？"
              confirm-button-text='是'
              cancel-button-text='否'
              iconColor="red"
              @confirm="deleteClick(scope.row)"
          >
            <template #reference>
              <el-button size="small" icon="el-icon-delete" type="danger">
                删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="32%" title="商品分类信息" :show-close=true v-model="ruleForm.dialogFormVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="分类名称" prop="CategoryName" required>
          <el-input v-model="ruleForm.CategoryName"></el-input>
        </el-form-item>
        <el-form-item label="排序"  prop="Sort" required>
          <el-input v-model="ruleForm.Sort" type="number" placeholder="数字（从小到大）" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <!-- @click="btnSubmit"  ruleForm.dialogFormVisible = false-->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import {
  getCityList,
  getShopList,
  getCategoryListPage,
  getCategoryById,
  updateCategory,
  addCategory, delCategory
} from "../http/api";
import {ElMessage} from "element-plus";
export default {
  name: "Category",
  setup() {

    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    onMounted(() => {
     // console.log(ruleFormRef.value);
    });

    //声明数据table表格的内容///////////////////////////////////
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      shopListData: [],
      cityListData:[],
    });

    //声明查询条件
    let option = reactive({
      cityid:"",
      shopid: "",
      page: 1,
      pageSize: 10,
    });

    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    } 

    let deleteClick = async (obj) => {
      let data = {"id": obj.id};
      let delResult = await delCategory(data);
      getCategoryListFun(option);
      if(delResult){
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      }else{
        ElMessage.error({
          message: "删除失败！",
          duration: 2000
        });
      }
    };
    //获取城市列表方法
    let getCityListDataFun = async (obj) => {
      let cityList = await getCityList(obj);
      data.cityListData = cityList;
    };
    //城市下拉框改变时,门店下拉都要改变
    let changeCity = async (cityID)=>{
      let shopList = await getShopList({cityid:cityID});
      data.shopListData = shopList;
    }
    //获取学期列表方法
    let getCategoryListFun = async (obj) => {
      let arr = await getCategoryListPage(obj);
      data.total = arr.totalCount;
      //console.log(data.total + "总条数");
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    getCategoryListFun({ Page: 1, PageSize: 10 });
    getCityListDataFun({ });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getCategoryListFun(option);
    };

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      //let token = Cookies.get('name');
      //console.log(token);
      await getCategoryListFun(option);
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      CategoryName: "",
      Sort:0,
      addOrUpdate:0 //0新增，1更新
    });

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      //console.log(obj.id);
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let Category = await getCategoryById(obj.id);
      //console.log(Category);
      ruleForm.CategoryName = Category.name;
      ruleForm.Sort = Category.sort;
      option.shopid= Category.shopID;
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //点击新增按钮
    let AddClick = async () => {
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleFormRef.value.resetFields();
      ruleForm.addOrUpdate=0;
      ruleForm.CategoryName = "";
      ruleForm.Sort = "";
      ruleForm.id = "";
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //修改的表单验证
    let rules = reactive({
      CategoryName: [
        { required: true, message: "请输入商品分类名称" }
      ],
      Sort: [{ required: false, message: "请输入排序" }]
    });

    //提交表单,新增或修改项目信息
    let submitForm = async (formName) => {
      //console.log(formName);
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          //console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      if(ruleForm.addOrUpdate==1){//更新
        let data={
          "Name":ruleForm.CategoryName,
          "Sort":ruleForm.Sort,
          "ShopID":option.shopid,
          "ID":ruleForm.id
        }
        await updateCategory(data);
      }else{//新增
        let data={
          "Name":ruleForm.CategoryName,
          "Sort":ruleForm.Sort,
          "ShopID":option.shopid
        }
        await addCategory(data);
      }
      await getCategoryListFun(option);//重新加载数据
      ruleForm.dialogFormVisible = false;
    };

    //表单重置
    let resetForm = (formName) => {
      //console.log(formName);
      //console.log( ruleFormRef.value);
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };

    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
      // ElMessage.error({
      //   message: '姓名不能为空',
      //   type: "error",
      //   offset:130,
      //   duration:1500
      // });
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      deleteClick,
      ruleForm,
      btnSubmit,
      rules,
      submitForm,
      resetForm,
      ruleFormRef,
      AddClick,
      changeCity,
      indexMethod
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
