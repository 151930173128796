<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/PointsMall' }">返回</el-breadcrumb-item>
    <el-breadcrumb-item></el-breadcrumb-item>
  </el-breadcrumb>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>分类名称:</span>
        <el-input
            v-model="option.pointsExchangeTypeName"
            clearable
            placeholder="请输入分类名称"
        ></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
        >查 询</el-button
        >
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">
          新增
        </el-button>
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page]  -->
    <el-table :row-style="setRowStyle" border stripe :data="data.table" style="width:100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column
          type="index" :index="indexMethod"
          width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="shopID" label="shopID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column  prop="pointsExchangeTypeName" label="分类名称"> </el-table-column>
      <el-table-column prop="sort" label="序号"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template v-slot:default="scope">
          <span v-if="scope.row.status === 0">启动</span>
          <span v-else>禁用</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="creationTime"
          label="创建时间"
          :formatter="formatCreationTime"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
              @click="UpdateClick(scope.row)"
              size="small"
              icon="el-icon-edit"
              type="primary"
          >修改</el-button>
          <el-popconfirm
              title="您确定要删除吗？"
              confirm-button-text='是'
              cancel-button-text='否'
              iconColor="red"
              @confirm="deleteClick(scope.row)"
          >
            <template #reference>
              <el-button size="small" icon="el-icon-delete" type="danger">
                删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 3.分页 -->
    <el-pagination
        class="fenye"
        @current-change="handleCurrentChange"
        :current-page="option.page"
        :page-size="10"
        prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
        next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
        background
        layout="total, prev, pager, next, jumper"
        :total="data.total"
    >
    </el-pagination>
    <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="32%" title="积分兑换分类信息" :show-close=true v-model="ruleForm.dialogFormVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="分类名称" prop="pointsExchangeTypeName"  required>
          <el-input v-model="ruleForm.pointsExchangeTypeName" placeholder="请输入分类名称" maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="序号"  prop="Sort" required>
          <el-input v-model="ruleForm.Sort" type="number" placeholder="数字（从小到大）" autocomplete="off" maxlength="6"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status" required>
          <el-select v-model="ruleForm.status" placeholder="请选择状态">
            <el-option label="启动" :value="0"></el-option>
            <el-option label="禁用" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <!-- @click="btnSubmit"  ruleForm.dialogFormVisible = false-->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import {
  // getCityList,
  // getShopList,
  ListPointsExchangeType,
  PointsExchangeTypegetCategoryById,
  updatePointsExchangeType,
  CreatePointsExchangeType, Pointsexchangetype
} from "../http/api";
import {ElMessage} from "element-plus";
import {FullTimeFormat} from "../utils/common";

export default {
  methods: {
    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    }
  },
  name: "Category",
  setup() {
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    onMounted(() => {
    });
    //声明数据table表格的内容
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
    });
    //声明查询条件
    let option = reactive({
      pointsExchangeTypeName: "",
      page: 1,
      pageSize: 10,
    });
    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    }
    let deleteClick = async (obj) => {
      let data = {"id": obj.id};
      let delResult = await Pointsexchangetype(data);
      getCategoryListFun(option);
      if(delResult){
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      }else{
        ElMessage.error({
          message: "删除失败！",
          duration: 2000
        });
      }
    };
    let getCategoryListFun = async (obj) => {
      let arr = await ListPointsExchangeType(obj);
      data.total = arr.totalCount;
      // console.log(data.total + "总条数");
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    getCategoryListFun({ Page: 1, PageSize: 10 });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getCategoryListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await getCategoryListFun(option);
    };
    //修改的表单参数
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      pointsExchangeTypeName: "",
      Sort:"",
      status:"",
      addOrUpdate:0 //0新增，1更新
    });
    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      //console.log(obj.id);
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let Category = await PointsExchangeTypegetCategoryById(obj.id);
      ruleForm.pointsExchangeTypeName = Category.pointsExchangeTypeName;
      ruleForm.Sort = Category.sort;
      ruleForm.status= Category.status;
      ruleForm.dialogFormVisible = true; //显示出表单
    };
    //点击新增按钮
    let AddClick = async () => {
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleFormRef.value.resetFields();
      ruleForm.addOrUpdate=0;
      ruleForm.pointsExchangeTypeName = "";
      ruleForm.Sort = "";
      ruleForm.status = "";
    };
    //修改的表单验证
    let rules = reactive({
      pointsExchangeTypeName: [
        { required: true, message: "请输入分类名称" }
      ],
      status: [
        { required: true, message: "请输入商品状态" }
      ],
      Sort: [{ required: true, message: "请输入序号" }]
    });
    //提交表单,新增或修改项目信息
    let submitForm = async () => {
      let con = false;
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          return false;
        }
      });
      if (con) {
        return false;
      }
          try{
      if(ruleForm.addOrUpdate==1){//更新
        let data={
          "pointsExchangeTypeName":ruleForm.pointsExchangeTypeName,
          "Sort":ruleForm.Sort,
          "status":ruleForm.status,
          "ID":ruleForm.id
        }
        await updatePointsExchangeType(data);
        ElMessage.success({
          message: "修改成功！",
          duration: 2000,
          type: "success",
        });
      }else{//新增
        let data={
          "pointsExchangeTypeName":ruleForm.pointsExchangeTypeName,
          "Sort":ruleForm.Sort,
          "status":ruleForm.status
        }
        await CreatePointsExchangeType(data);
        ElMessage.success({
          message: "新增成功！",
          duration: 2000,
          type: "success",
        });
      }
      await getCategoryListFun(option);//重新加载数据
      ruleForm.dialogFormVisible = false;
    }catch (error) {
            ElMessage.error({
              message: "操作失败，请重试！",
              duration: 2000
            });
            console.error(error);
          }
    };
    //表单重置
    let resetForm = () => {
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };
    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      deleteClick,
      ruleForm,
      btnSubmit,
      rules,
      submitForm,
      resetForm,
      ruleFormRef,
      AddClick,
      indexMethod
    };
  }
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 120%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
