<template>
    <div>
        <!-- 1.筛选条件 -->
        <div class="select-box">
            <div>
                <span>商品名称:</span>
                <el-input
                        v-model="option.tradeName"
                        clearable
                        placeholder="请输入商品名称"
                ></el-input>
            </div>
            <div>
                <span>分类:</span>
                <el-select v-model="option.classification" clearable placeholder="请选择分类" >
                    <el-option v-for="item in data.ListData" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div>
                <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
<!--                <el-button @click="ToSetting" icon="el-icon-s-operation" type="primary">分类设置</el-button>-->
                <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">
                    新增商品
                </el-button>
                <el-button icon="el-icon-document-add"  @click="UpdateClickcoup()" type="primary">
                    积分说明配置
                </el-button>
            </div>
        </div>
        <!--2. table]  -->
        <el-table border stripe :data="data.table" style="width:100%">
            <el-table-column
                    type="index" :index="indexMethod"
                    width="50" style="text-align: center;">
            </el-table-column>
            <el-table-column  prop="tradeName" label="商品名称"> </el-table-column>
            <el-table-column prop="redeemPoints" label="兑换积分" width="95" > </el-table-column>
            <el-table-column prop="productStatus" label="商品状态">
                <template v-slot:default="scope">
                    <span v-if="scope.row.productStatus === '0'">启动</span>
                    <span v-else>禁用</span>
                </template>
            </el-table-column>
            <el-table-column  label="剩余库存量" width="95">
                <template #default="scope">
                {{ data.obj2[scope.row["id"]]  }}
                </template>
            </el-table-column>
            <el-table-column prop="redeemedQuantity" label="已兑换数量" width="95">
                <template #default="scope">
                    {{ data.obj[scope.row["id"]] !== undefined ? data.obj[scope.row["id"]] : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="分类">
                <template #default="scope">
                    {{scope.row["classification"] == "0" ?'积分换礼' : scope.row["classification"] == "1" ? '积分换券' :''}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="creationTime"
                    label="创建时间"
                    :formatter="formatCreationTime"
                    width="160"
            ></el-table-column>
            <el-table-column fixed="right" label="操作" width="400">
                <template #default="scope">
                    <el-button
                            @click="UpdateClick(scope.row)"
                            size="small"
                            icon="el-icon-edit"
                            type="primary"
                    >编辑</el-button>
                    <el-popconfirm
                            title="您确定要删除吗？"
                            confirm-button-text='是'
                            cancel-button-text='否'
                            iconColor="red"
                            @confirm="deleteClick(scope.row)"
                    >
                        <template #reference>
                            <el-button size="small" icon="el-icon-delete" type="danger">
                                删除</el-button>
                        </template>
                    </el-popconfirm>
                    <el-button @click="ViewMallImg(scope.row)" type="primary" size="small">详情</el-button>
                    <el-button @click="ViewExchangeDetil(scope.row)" type="primary" size="small">查看兑换明细</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 3.分页 -->
        <el-pagination
                class="fenye"
                @current-change="handleCurrentChange"
                :current-page="option.page"
                :page-size="10"
                prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
                next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
                background
                layout="total, prev, pager, next, jumper"
                :total="data.total"
        >
        </el-pagination>
        <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
        <el-dialog width="50%" title="商品信息" :show-close=true @close="resetForm('ruleForm')" v-model="ruleForm.dialogFormVisible ">
            <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="商品名称" prop="tradeName" style="width: 320px" required>
                            <el-input v-model="ruleForm.tradeName" placeholder="请输入商品名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="兑换积分" prop="redeemPoints" required style="margin-left: 20px;width: 320px">
                            <el-input v-model="ruleForm.redeemPoints" placeholder="请输入兑换积分"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="库存数量" prop="inventoryQuantity" required style="width: 320px">
                            <el-input v-model="ruleForm.inventoryQuantity" placeholder="请输入库存数量"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="虚拟兑换量" prop="virtuallyAmount" required style="margin-left: 20px;width: 320px">
                            <el-input v-model="ruleForm.virtuallyAmount" placeholder="请输入虚拟兑换量"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="分类" prop="classification" required style="width: 320px">
                            <!--<el-input v-model="ruleForm.classification"></el-input>-->
                            <el-select v-model="ruleForm.classification" clearable placeholder="请选择分类" @change="classificationChange">
                                <el-option
                                        v-for="item in data.ListData"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-row :span="12">
<!--                        <el-form-item prop="couponId" v-if="ruleForm.classification==1" label="优惠券">-->
                            <el-form-item prop="couponId" label="优惠券" required>
                                <el-select v-model="ruleForm.couponId" clearable filterable placeholder="请选择优惠券">
                                    <el-option
                                            v-for="item in data.couponListData"
                                            :key="item.coupon_id"
                                            :label="item.coupon_name"
                                            :value="item.coupon_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                    </el-row>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="商品状态" prop="productStatus" required style="margin-left: 0px;width: 320px">
                            <el-select v-model="ruleForm.productStatus" placeholder="请选择商品状态">
                                <el-option label="禁用" :value="'1'"></el-option>
                                <el-option label="启用" :value="'0'"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="每人最多兑换数量" prop="redeemQuantity" required style="width: 320px;margin-left: 20px" label-width="150px">
                            <el-input v-model="ruleForm.redeemQuantity" placeholder="请输入每人最多兑换数量"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!--<el-row>
                    <el-col :span="12" style="white-space: nowrap;">
                        <el-form-item label="自动启用时间" prop="enablingTime" >
                            <el-date-picker
                                    v-model="ruleForm.enablingTime"
                                    type="datetime"
                                    placeholder="请选择自动启用时间"
                                    :disabled-date="getDateTime"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>-->
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上传封面" prop="uploadCover">
                            <el-upload ref="myupload"
                                       action=""
                                       :file-list="ruleForm.fileList"
                                       :on-preview="handlePreview"
                                       :on-remove="handleRemove"
                                       :beforeUpload="beforeUpload"
                                       :http-request="handleUpload"
                                       :multiple="false"
                                       :limit=1
                                       accept=".png,.jpg"
                            >
                                <el-button size="small" type="primary">上传图片</el-button>
                            </el-upload>
                            <el-col>
                                <p style="color: red ;padding-bottom: 20px">* 图片格式 宽：800px；高：800px；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
                            </el-col>
                            <div v-if="ruleForm.imageDiv">
                                <el-image :src="ruleForm.uploadCover" :fit="fit"></el-image>
                                <el-button @click="imageClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row style="margin-bottom: 20px;">
                    <el-col :span="16">
                        <el-form-item label="商品提示" prop="productTips" style="margin-left: 0px;width: 640px">
                            <el-input
                                    v-model="ruleForm.productTips"
                                    type="textarea"
                                    :rows="3"
                                    clearable
                                    placeholder="请输入商品提示"
                                    autocomplete="off"
                                    :maxlength="500"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px;">
                    <el-col :span="16">
                        <el-form-item label="价值说明" prop="valueDescription" style="margin-left: 0px;width: 640px">
                            <el-input
                                    v-model="ruleForm.valueDescription"
                                    type="textarea"
                                    :rows="3"
                                    clearable
                                    placeholder="请输入价值说明"
                                    autocomplete="off"
                                    :maxlength="500"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="商品详情"  prop="productDetails">
                    <!--<el-input v-model="ruleForm.productDetails"/>-->
<!--                    <QuillEditor ref="quillEditorRef" v-model="ruleForm.productDetails"></QuillEditor>-->
                    <el-upload ref="myupload"
                               action=""
                               :file-list="ruleForm.fileList1"
                               :on-preview="handlePreview1"
                               :on-remove="handleRemove1"
                               :beforeUpload="beforeUpload1"
                               :http-request="handleUpload1"
                               :multiple="false"
                               :limit=1
                               accept=".png,.jpg"
                    >
                        <el-button size="small" type="primary">上传图片</el-button>
                    </el-upload>
                    <el-col>
                        <p style="color: red ;padding-bottom: 20px">* 大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
                    </el-col>
                    <div v-if="ruleForm.imageDiv1">
                        <el-image :src="ruleForm.productDetails" :fit="fit"></el-image>
                        <el-button @click="imageClose1" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
            </template>
        </el-dialog>

        <el-dialog width="50%" title="积分说明配置" :show-close=true @close="resetFormcoup('ruleFormcoup')" v-model="ruleFormcoup.dialogFormVisiblecoup">
            <el-form :model="ruleFormcoup"  ref="ruleFormRefcoup" label-width="100px" class="demo-ruleForm">
                <el-row style="margin-bottom: 20px;">
                    <el-col>
<!--                        <el-form-item label="积分说明配置" prop="pointsdescription" label-width="170px" >-->
                            <el-input
                                    v-model="ruleFormcoup.pointsdescription"
                                    type="textarea"
                                    :rows="6"
                                    clearable
                                    placeholder="积分说明配置"
                                    autocomplete="off"
                                    :maxlength="500"
                            ></el-input>
<!--                        </el-form-item>-->
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
            <span class="dialog-footer">
                <el-button @click="resetFormcoup('ruleFormcoup')">取 消</el-button>
                <el-button type="primary" @click="submitFormcoup('ruleFormcoup')">确 定</el-button>
             </span>
            </template>
        </el-dialog>


    </div>
</template>

<!-- 本页的js -->
<script>
import {ref, reactive, onMounted, nextTick} from "vue";
    import { ListPointsMall, updatePointsMall, CreatePointsMall, PointsMallById,DeltePointMall, GetAllListAsync,getCouponsRule,ExchangeLength} from "../http/pointsMall";
    import { putToOSS, getFileNameUUID,getFileExtensions } from '@/http/oss'
    import {
        getPointsCouponsDate,
        updatePointsCouponsDate
    } from "../http/api";

    import {FullTimeFormat} from "../utils/common";
    import {ElMessage} from "element-plus";
    import { useRouter } from "vue-router";
    import QuillEditor from "../components/QuillEditor";

    export default {
        components: {
            QuillEditor
        },
        methods: {
            formatCreationTime(row) {
                return FullTimeFormat(row.creationTime);
            },

        },
        name: "Category",
        setup() {
            const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
            //跳转到分类设置页面
            const router = useRouter();
            const ruleFormRefcoup = ref(null);//表单不能用this.$,取一个重名的
            const quillEditorRef = ref(null);
            //分类设置
            let ToSetting = () => {
                router.push({name: "Pointsexchangetype"})
            };
            //详情
            let ViewMallImg = (obj) => {
                router.push({name: "PointsMallImg", params: {id: obj.id}})
            };
            //查看兑换明细
            let ViewExchangeDetil = (obj) => {
                router.push({name: "ExchangeDetails", params: {id: obj.id}})
            };
            onMounted(async () => { // 在onMounted生命周期钩子中调用GetAllListAsync方法

            });

            //定义自动启用时间的范围
            let getDateTime= (time) => {
                return new Date(time).getTime() < Date.now() - 8.64e7;
            };
            // let getListDataFun = async (obj) => {
            //     let list = await GetAllListAsync(obj);
            //     data.ListData = list;
            // };
            // getListDataFun({ });
            //声明数据table表格的内容//
            let data = reactive({
                table: [],
                pages: 0,
                total: 0,
                table2:[],
                ListData:[
                {id:"0",name:'积分换礼'},
                {id:"1",name:'积分换券'}
                ],
                couponListData:[],
                obj:{},
                obj2:{}
            });
            //声明查询条件
            let option = reactive({
                tradeName: "",
                classification: "",
                page: 1,
                pageSize: 10,
            });
            let indexMethod =(index) => {
                return index+(option.page-1)*option.pageSize+1;
            }
            let getCategoryListFun = async (obj) => {
                let arr = await ListPointsMall(obj);
                //console.log(arr)
                data.total = arr.totalCount;
                //console.log(data.total + "总条数");
                data.table = arr.items;
                console.log("2222",arr.items)
                getExchangeLength(arr.items);
            };
            let getExchangeLength = async (obj) => {
                let arr = await ExchangeLength({});
                console.log("1111",arr)
                data.total2 = arr;
                if(obj != null && obj.length >0 ){
                data.table.filter(item => {
                    data.total2.filter(itemtwo =>{
                        if(item.id == itemtwo.pointsMallId){
                            data.obj[item.id] = itemtwo.pointsMallIdLenth;
                            data.obj2[item.id] = item.inventoryQuantity -itemtwo.pointsMallIdLenth;
                        }
                    })
                 })
            data.table.filter(item =>{
                if (! Object.keys(data.obj).includes(item.id)) {
                    data.obj2[item.id] = item.inventoryQuantity;
             }
            })
            }
            };
            let UpdateClickcoup = async (obj) => {
              ruleFormcoup.dialogFormVisiblecoup = true; //显示出表单
              //console.log(obj.id);
              let PointsCouponsDate = await getPointsCouponsDate();
                ruleFormcoup.pointsdescription = PointsCouponsDate[0].pointsdescription;
                ruleFormcoup.id = PointsCouponsDate[0].id;
            };

            let getCouponsRuleList = async (obj) => {
                let arr = await getCouponsRule(obj);
                let couponRuleList = arr.data.coupon_rule_list;
                if(!ruleForm.classification || ruleForm.classification === '0'){
                  data.couponListData = couponRuleList.filter(el => el.coupon_type === 40);
                }else{
                  data.couponListData = couponRuleList;
                }
                //console.log(reData.couponListData)
            };
            //初始化时，获取初始化数据
            getCategoryListFun({ Page: 1, PageSize: 10 });
            getCouponsRuleList({});
            getExchangeLength();
            //点击页码获取数据
            let handleCurrentChange = (index) => {
                option.page = index;
                getCategoryListFun(option);
            };
            // 分类变化,清空优惠券数据
            let classificationChange = () => {
              data.couponListData = [];
              ruleForm.couponId = '';
              getCouponsRuleList({});
            };
            //查询方法,此处需要根据条件到后台进行筛选
            let getTabList = async () => {
                await getCategoryListFun(option);
            };

            //修改的表单参数//////////////////////////////////////////////////
            let ruleForm = reactive({
                dialogFormVisible: false,
                id: "",
                tradeName: "",
                redeemPoints:"",
                productStatus:"0",
                residueQuantity:"",
                redeemedQuantity:"",
                classification:"",
                couponId:"",
                couponListData:[],
                addOrUpdate:0 ,//0新增，1更新
                imageDiv:false,
                imageDiv1:false,
                uploadCover:"",
                productDetails:"",
            });
            let ruleFormcoup = reactive({
                dialogFormVisiblecoup: false,
                id: "",
            });
            //点击修改按钮,弹框，默认把原来的数据显示出来
            let UpdateClick = async (obj) => {
              ruleForm.dialogFormVisible = true; //显示出表单
              ruleForm.addOrUpdate=1;
              //console.log(obj.id);
              ruleForm.id = obj.id;
              //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
              let Category = await PointsMallById(obj.id);
              //console.log(Category);
              ruleForm.tradeName  = Category.tradeName;
              ruleForm.redeemPoints = Category.redeemPoints;
              ruleForm.inventoryQuantity = Category.inventoryQuantity;
              ruleForm.redeemQuantity = Category.redeemQuantity;
              ruleForm.classification = Category.classification;
              ruleForm.productStatus = Category.productStatus;
              ruleForm.virtuallyAmount = Category.virtuallyAmount;
              // ruleForm.enablingTime = Category.enablingTime;
              ruleForm.uploadCover  = Category.uploadCover;
              ruleForm.productDetails = Category.productDetails;
              ruleForm.productTips = Category.productTips;
              ruleForm.valueDescription = Category.valueDescription;
              if(Category.couponId){
                ruleForm.couponId = Number(Category.couponId);
              }
              getCouponsRuleList({});
              // nextTick(() => {
              //   quillEditorRef.value.$refs['quill-editor'].setHTML(Category.productDetails);
              // })
            };
            //点击新增按钮
            let AddClick = async () => {
                ruleForm.dialogFormVisible = true; //显示出表单
                ruleFormRef.value.resetFields();
                ruleForm.addOrUpdate=0;
                ruleForm.tradeName  = "";
                ruleForm.redeemPoints = "";
                ruleForm.productStatus ="0";
                ruleForm.inventoryQuantity = "";
                ruleForm.redeemQuantity = 0;
                ruleForm.classification = "";
                ruleForm.virtuallyAmount = "";
                // ruleForm.enablingTime = "";
                ruleForm.uploadCover  = "";
                ruleForm.productDetails = "";
                ruleForm.productTips = "";
                ruleForm.valueDescription = "";
                ruleForm.couponId = "";
                ruleForm.dialogFormVisible = true; //显示出表单
            };
            //表单验证
            let rules = reactive({
                tradeName: [
                    { required: true, message: "请输入商品名称" }
                ],
                redeemPoints: [
                    { required: true, message: "请输入兑换积分" }
                ],
                inventoryQuantity: [
                    { required: true, message: "请输入库存数量" }
                ],
                redeemQuantity: [
                    { required: true, message: "请输入每人最多兑换量" }
                ],
                productStatus: [
                    { required: true, message: "请选择商品状态" }
                ],
                virtuallyAmount: [
                    { required: true, message: "请输入库存数量" }
                ],
                classification: [
                    { required: true, message: "请选择商品分类" }
                ],
                couponId: [
                    { required: true, message: "请选择优惠券" }
                ],
                /*uploadCover: [
                    { required: true, message: "请上传封面" }
                ],*/
                /*productDetails: [
                    { required: true, message: "请输入商品详情" }
                ],*/
            });
            //提交表单,新增或修改项目信息
            let submitForm = async (formName) => {
                //console.log(formName);
                // let content = quillEditorRef.value.entity.content;
                // ruleForm.productDetails = content;
                // console.log("Product Details:", ruleForm.productDetails);
                let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
                ruleFormRef.value.validate((valid) => {
                    if (!valid) {
                        con = true;
                        //console.log("下面的不执行了");
                        return false;
                    }
                });
                if (con) {
                    return false;
                }
                try {
                    if (ruleForm.addOrUpdate == 1) {//更新
                        let data = {
                            "tradeName": ruleForm.tradeName,
                            "redeemPoints": ruleForm.redeemPoints,
                            "inventoryQuantity": ruleForm.inventoryQuantity,
                            "redeemQuantity": ruleForm.redeemQuantity,
                            "classification": ruleForm.classification,
                            "productStatus": ruleForm.productStatus,
                            "virtuallyAmount": ruleForm.virtuallyAmount,
                            // "enablingTime": ruleForm.enablingTime,
                            "uploadCover": ruleForm.uploadCover,
                            "productDetails": ruleForm.productDetails,
                            "productTips": ruleForm.productTips,
                            "valueDescription": ruleForm.valueDescription,
                            "couponId":ruleForm.couponId.toString(),
                            "ID": ruleForm.id,
                        }
                        await updatePointsMall(data);
                        ruleForm.uploadCover = '';
                        ruleForm.productDetails = '';
                        ruleForm.imageDiv = false;
                        ruleForm.imageDiv1 = false;
                        ruleForm.fileList = []
                        ruleForm.fileList1 = []
                        ElMessage.success({
                            message: "修改成功！",
                            duration: 2000,
                            type: "success",
                        });
                    } else {//新增
                        let data = {
                            "tradeName": ruleForm.tradeName,
                            "redeemPoints": ruleForm.redeemPoints,
                            "inventoryQuantity": ruleForm.inventoryQuantity,
                            "redeemQuantity": ruleForm.redeemQuantity,
                            "classification": ruleForm.classification,
                            "productStatus": ruleForm.productStatus,
                            "virtuallyAmount": ruleForm.virtuallyAmount,
                            // "enablingTime": ruleForm.enablingTime,
                            "uploadCover": ruleForm.uploadCover,
                            "productDetails": ruleForm.productDetails,
                            "productTips": ruleForm.productTips,
                            "valueDescription": ruleForm.valueDescription,
                            "couponId":ruleForm.couponId.toString(),
                            "introduction": '',
                            "ID": ruleForm.id,
                        }
                        await CreatePointsMall(data);

                        ruleForm.uploadCover = '';
                        ruleForm.productDetails = '';
                        ruleForm.imageDiv = false;
                        ruleForm.imageDiv1 = false;
                        ruleForm.fileList = []
                        ruleForm.fileList1 = []
                        ElMessage.success({
                            message: "新增成功！",
                            duration: 2000,
                            type: "success",
                        });
                    }
                    await getCategoryListFun(option);//重新加载数据
                    ruleForm.dialogFormVisible = false;
                } catch (error) {
                    console.log("1111111111111",error)
                    ElMessage.error({
                        message: "操作失败，请重试！",
                        duration: 2000
                    });
                    console.error(error);
                }
            };
            let submitFormcoup = async (formName) => {
                //console.log(formName);

                /*if(ruleForm.duration < 0){
                    ElMessage.error({
                        message: '转增失效时长不能为负数！',
                        type: "error",
                        offset:130,
                        duration:1500
                    });
                    return false;
                }*
               /* if(ruleForm.daycoupons < 0){
                    ElMessage.error({
                        message: '每人每天可领取优惠券数量不能为负数！',
                        type: "error",
                        offset:130,
                        duration:1500
                    });
                    return false;
                }*
                /*if(ruleForm.maxcoupons < 0){
                    ElMessage.error({
                        message: '活动期间内最多可领优惠券数量不能为负数！',
                        type: "error",
                        offset:130,
                        duration:1500
                    });
                    return false;
                }*/
                if(ruleFormcoup.pointsdescription == "" || ruleFormcoup.pointsdescription == null || ruleFormcoup.pointsdescription == undefined ){
                    ElMessage.error({
                        message: '积分说明配置不能为空',
                        type: "error",
                        offset:130,
                        duration:1500
                    });
                    return false;
                }
                let data= {
                    "id":ruleFormcoup.id,
                    "pointsdescription":ruleFormcoup.pointsdescription
                }
                await updatePointsCouponsDate(data);
                ElMessage.success({
                    message: '保存成功！',
                    type: "success",
                    offset: 130,
                    duration: 2000
                });
                ruleFormcoup.dialogFormVisiblecoup = false;
            };
            let resetFormcoup = (formName) => {
                ruleFormcoup.dialogFormVisiblecoup = false;
            };
            //表单重置
            let resetForm = (formName) => {
                ruleFormRef.value.resetFields();
                ruleForm.couponId = "";
                nextTick(() => {
                  quillEditorRef.value.$refs['quill-editor'].setHTML('');
                })
                ruleForm.dialogFormVisible = false;
            };
            let beforeUpload=(file) =>{
                // 限制上传类型
                const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
                //限制的上限为2M
                const max2M = file.size / 1024 / 1024 < 2;
                if (!fileExtensions) {
                    ElMessage.error({
                        message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
                        duration: 2000
                    });
                }
                if (!max2M) {
                    ElMessage.error({
                        message: "上传文件大小不能超过 2MB!",
                        duration: 2000
                    });
                }
                return fileExtensions && max2M;
            };
            let beforeUpload1=(file) =>{
                // 限制上传类型
                const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
                //限制的上限为2M
                const max2M = file.size / 1024 / 1024 < 2;
                if (!fileExtensions) {
                    ElMessage.error({
                        message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
                        duration: 2000
                    });
                }
                if (!max2M) {
                    ElMessage.error({
                        message: "上传文件大小不能超过 2MB!",
                        duration: 2000
                    });
                }
                return fileExtensions && max2M;
            };
            let handleUpload=(option)=> {
                // 生成的文件名，保留文件后缀名，进行拼接
                let objName ="GoodsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
                // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
                putToOSS(`flieName/${objName}`, option.file).then(res => {
                    //console.log(res,'res')
                    // 上传成功之后，转换真实的地址
                    //signatureUrl(`flieName/${objName}`).then(res => {
                    //console.log(res)
                    ruleForm.uploadCover=res.url;
                    ruleForm.imageDiv =true;
                    //})
                })
            };
            let handleUpload1=(option)=> {
                // 生成的文件名，保留文件后缀名，进行拼接
                let objName ="GoodsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
                // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
                putToOSS(`flieName/${objName}`, option.file).then(res => {
                    //console.log(res,'res')
                    // 上传成功之后，转换真实的地址
                    //signatureUrl(`flieName/${objName}`).then(res => {
                    //console.log(res)
                    ruleForm.productDetails=res.url;
                    ruleForm.imageDiv1 =true;
                    //})
                })
            };
            let handlePreview=(file)=>{
                console.log(file);
            };
            let handlePreview1=(file)=>{
                console.log(file);
            };
            let handleRemove=(file, fileList)=> {
                console.log(file, fileList);
            };
            let handleRemove1=(file, fileList1)=> {
                console.log(file, fileList1);
            };
            //删除上传的图片
            let imageClose = ()=>{
                ruleForm.uploadCover="";
                ruleForm.imageDiv = false;
            };
            let imageClose1 = ()=>{
                ruleForm.productDetails="";
                ruleForm.imageDiv1 = false;
            };
            let deleteClick = async (obj) => {
                let data = {"id": obj.id};
                let delResult = await DeltePointMall(data);
                getCategoryListFun(option);
                if(delResult){
                    ElMessage.success({
                        message: "删除成功！",
                        duration: 2000,
                        type: "success",
                    });
                }else{
                    ElMessage.error({
                        message: "删除失败！",
                        duration: 2000
                    });
                }
            };
            return {
                data,
                handleCurrentChange,
                classificationChange,
                getTabList,
                option,
                UpdateClick,
                ruleForm,
                rules,
                submitForm,
                resetForm,
                ruleFormRef,
                UpdateClickcoup,
                ruleFormcoup,
                resetFormcoup,
                ruleFormRefcoup,
                submitFormcoup,
                AddClick,
                indexMethod,
                handlePreview,
                handleRemove,
                beforeUpload,
                handleUpload,
                imageClose,
                handlePreview1,
                beforeUpload1,
                handleRemove1,
                handleUpload1,
                imageClose1,
                deleteClick,
                ViewMallImg,
                ToSetting,
                ViewExchangeDetil,
                getDateTime,
                quillEditorRef
            };
        },
    };
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
    .select-box {
        display: flex;
        align-items: center;
        width: 120%;
        margin-bottom: 25px;
        > div {
            width: 15%;
            display: flex;
            align-items: center;
            span {
                width: 110px;
            }
            margin-right: 25px;
        }
    }
    .fenye {
        margin-top: 20px;
    }
</style>
