import $http from "./index";
import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;
//查询
export const getBannerManagementList = (data) => {
    return $http.get(
        `${base}/app/banners/bannermanagement/back/getbannermanagementlist`,
        {
            params: data,
            timeout: 10000,
        }
    );
};
//删除广告管理
export const delBannerManagement = (data) => {
    return $http.get(
        `${base}/app/banners/bannermanagement/back/delete`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
//关闭活动
export const setStatusAsync = (data) => {
    return $http.get(
        `${base}/app/banners/bannermanagement/back/setstatus`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
//校验查询城市和时间是否又交集
export const getCityTimeList = (data) => {
    return $http.get(
        `${base}/app/banners/bannermanagement/back/getcitytimelist`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
//新增广告管理
export const addBannerManagement = (data) => {
    return $http.post(
        `${base}/app/banners/bannermanagement/back/createbannermanagement`,
        data
    );
};
//新增广告会员优惠券
export const addBannerVipCoupon = (data) => {
    return $http.post(
        `${base}/app/banners/bannervipcoupon/back/createbannervipcoupon`,
        data
    );
};
//获取一条广告管理详情
export const getBannerManagementById = (data) => {
    return $http.get(
        `${base}/app/banners/bannermanagement/back/getbannermanagementinfo`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
//获取会员优惠券的信息
export const getVipCouponById = (data) => {
    return $http.get(
        `${base}/app/banners/bannervipcoupon/back/getvipcouponinfo`,
        {
            params: data,
            timeout: 2000,
        }
    );
};
//获取会员优惠券的信息
export const getVipCouponByIdK = (data) => {
    return $http.get(
        `${base}/app/banners/bannervipcoupon/back/getvipcouponk`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
//删除会员优惠券
export const delVipCoupon = (data) => {
    return $http.get(
        `${base}/app/banners/bannervipcoupon/back/deletevipcoupon`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
//修改广告管理
export const updateBannerManagement = (data) => {
    return $http.post(
        `${base}/app/banners/bannermanagement/back/updatebannermanagement`,
        data
    );
};
//修改会员优惠券
export const updateVipCoupon = (data) => {
    return $http.post(
        `${base}/app/banners/bannervipcoupon/back/updatevipcoupon`,
        data
    );
};
export const getvipmanage = (data) => {
    return $http.get(
        `${base}/app/info/vipmanage/back/getall`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
//查询报名
export const getApplicationProjectList = (data) => {
    return $http.get(
        `${base}/app/banners/applicationproject/back/getapplicationprojectlist`,
        {
            params: data,
            timeout: 10000,
        }
    );
};
//查询推荐好友
export const getRecommendFriendList = (data) => {
    return $http.get(
        `${base}/app/banners/recommendfriend/back/getrecommendfriendlist`,
        {
            params: data,
            timeout: 10000,
        }
    );
};
//查询优惠券
export const getCouponsRule = (data) => {
    return $http.post(
        `${base}/app/info/crmInterface/getCouponsRule`,
        data
    );
};
//修改优惠券数量
export const updateVipCouponCount = (data) => {
    return $http.post(
        `${base}/app/banners/bannervipcoupon/back/updatevipcouponcount`,
        data
    );
};
//查询领券
export const getUserCouponsList = (data) => {
    return $http.get(
        `${base}/app/banners/usercoupons/back/getusercouponslist`,
        {
            params: data,
            timeout: 2000,
        }
    );
};
