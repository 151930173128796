<template>
  <div>

    <!-- 1.筛选条件 -->
    <div class="select-box">
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">
          新增投诉分类
        </el-button>
    </div>
    <!--2. table表格的内容 data.table[option.page]  -->
    <el-table :row-style="setRowStyle" border stripe :data="data.table" style="width:100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column><!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column  prop="name" label="分类名称"> </el-table-column>
       <el-table-column prop="sort" label="排序"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
            @click="UpdateClick(scope.row)"
            size="small"
            icon="el-icon-edit"
            type="primary"
            >修改</el-button>

            <el-button
            @click="DeleteClick(scope.row)"
            size="small"
            icon="el-icon-edit"
            type="danger"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="32%" title="投诉分类信息" :show-close=true v-model="ruleForm.dialogFormVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="分类名称" prop="ComplaintType" required>
          <el-input v-model="ruleForm.ComplaintType"></el-input>
        </el-form-item>
        <el-form-item label="排序"  prop="Sort" required>
          <el-input v-model="ruleForm.Sort" type="number" placeholder="数字（从小到大）" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <!-- @click="btnSubmit"  ruleForm.dialogFormVisible = false-->
        </span>
      </template>
    </el-dialog>

    <!-- <el-dialog
      title="提示"
      v-model="data.deleteDialogVisible"
      width="30%"
      center
      :before-close="handleClose">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="data.deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="data.deleteDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->
<!--  :visible.sync="data.deleteDialogVisible" -->
    <el-dialog
      title="温馨提示"
      v-model="data.deleteDialogVisible"
      width="30%"
      center>
      <div style="text-align: center;margin-bottom: 50px;">确定要删除吗？</div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="CancelDelete">取 消</el-button>
        <el-button type="primary" @click="ConfirmDelete">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import {addComplaintType, getComplaintTypeByID, getComplaintTypeList, updateComplaintType, deleteComplaintType} from "../http/complaint";
import {ElMessage} from "element-plus";
export default {
  name: "ComplaintType",
  setup() {
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    onMounted(() => {
     // console.log(ruleFormRef.value);
    });
    //声明数据table表格的内容///////////////////////////////////
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      deleteDialogVisible: false
    });
    //声明查询条件
    let option = reactive({
      page: 1,
      pageSize: 10,
    });

    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    } 

    let getComplaintTypeListFun = async (obj) => {
      let arr = await getComplaintTypeList(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    getComplaintTypeListFun({ Page: 1, PageSize: 10 });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getComplaintTypeListFun(option);
    };

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      //let token = Cookies.get('name');
      //console.log(token);
      await getComplaintTypeListFun(option);
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      ComplaintType: "",
      Sort:0,
      addOrUpdate:0 //0新增，1更新
    });

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      //console.log(obj.id);
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
       let Category = await getComplaintTypeByID({id: obj.id});
       //console.log(Category);
      ruleForm.ComplaintType = Category.name;
      ruleForm.Sort = Category.sort;
      option.shopid= Category.shopID;
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //点击新增按钮
    let AddClick = async () => {
    //  ruleFormRef.value.resetFields();
      ruleForm.addOrUpdate=0;
      ruleForm.ComplaintType = "";
      ruleForm.Sort = "";
      ruleForm.id = "";
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //修改的表单验证
    let rules = reactive({
      ComplaintType: [
        { required: true, message: "请输入商品分类名称" }
      ],
      Sort: [{ required: false, message: "请输入排序" }]
    });

    //提交表单,新增或修改项目信息
    let submitForm = async (formName) => {
      //console.log(formName);
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          //console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      if(ruleForm.addOrUpdate==1){//更新
        let data={
          "name":ruleForm.ComplaintType,
          "sort":ruleForm.Sort,
          "ShopID":option.shopid,
          "id":ruleForm.id
        }
        let res = await updateComplaintType(data);
        ElMessage({
          showClose: true,
          message: res?'修改成功！':'修改失败！请重试',
          type: 'warning'
        });
      }else{//新增
        let data={
          "name":ruleForm.ComplaintType,
          "sort":ruleForm.Sort,
          "ShopID":option.shopid
        }
        let res = await addComplaintType(data);
        ElMessage({
          showClose: true,
          message: res?'添加成功！':'添加失败！请重试',
          type: 'warning'
        });
      }
     let res = await getComplaintTypeListFun(option);//重新加载数据
     
      ruleForm.dialogFormVisible = false;
    };

    let deleteObj = '';

    //点击删除按钮
    let DeleteClick = async (obj) => {
      console.log(obj);
      deleteObj = obj;
      data.deleteDialogVisible  = true;
    }

    let CancelDelete = async () => {
      deleteObj = '';
      data.deleteDialogVisible  = false;
    }

    //确认删除
    let ConfirmDelete = async () => { //deleteComplaintType
      console.log('ConfirmDelete');
      let params = {
        id: deleteObj.id
      }
      
      let res = await deleteComplaintType(params);

      ElMessage({
          showClose: true,
          message: res==true?'删除成功！':'删除失败！请重试',
          type: 'warning'
        });
      CancelDelete();
      getTabList();

    }

    //表单重置
    let resetForm = (formName) => {
      //console.log(formName);
      //console.log( ruleFormRef.value);
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };

    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
      // ElMessage.error({
      //   message: '姓名不能为空',
      //   type: "error",
      //   offset:130,
      //   duration:1500
      // });
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      ruleForm,
      btnSubmit,
      rules,
      submitForm,
      resetForm,
      ruleFormRef,
      AddClick,
      DeleteClick,
      deleteObj,
      CancelDelete,
      ConfirmDelete,
      indexMethod
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
