<template>
  <div>

    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>城市:</span>
        <el-select v-model="option.CityID" clearable placeholder="请选择城市" @change="changeCity">
          <el-option v-for="item in data.cityListData" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div>
        <span>门店:</span>
        <el-select v-model="option.ShopName"  clearable placeholder="请选择门店">
          <el-option v-for="item in data.shopListData" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
        <!-- <el-input v-model="option.ShopName" clearable placeholder="请输入门店名称"></el-input> -->
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
        <!--<el-button icon="el-icon-document-add" @click="AddClick()" type="primary">新增城市广告</el-button>-->
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="id" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="shopID" label="shopID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column prop="regionID" label="regionID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column prop="cityID" label="cityID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column prop="shopName" label="门店名称"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="merchantNo" label="商户号"> </el-table-column>
      <el-table-column prop="termNo" label="终端号"> </el-table-column>
      <el-table-column prop="status" label="是否启用"> </el-table-column>
      <el-table-column label="图片" prop="headImgURL" align="center" width="100px">
        <template v-slot="scope">
          <el-image @click="bigImg(scope.row.headImgURL)" style="width: 50px; height: 50px; margin-bottom:-4px"
                    :src=scope.row.headImgURL
                    :preview-src-list="data.srcList">
          </el-image>
          <el-popover placement="top-start" trigger="click"> <!--trigger属性值：hover、click、focus 和 manual-->
            <a :href="scope.row.headImgURL" target="_blank" title="查看最大化图片">
              <img :src="scope.row.headImgURL" style="width: 300px;height: 300px">
            </a>
            <img :src="scope.row.headImgURL" style="width: 50px;height: 50px; cursor:pointer">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="250"> <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small" icon="el-icon-edit" @click="UpdateClick(scope.row)" type="primary">更改图片</el-button><!--@click="UpdateClick(scope.row)" -->
          <el-button size="small" icon="el-icon-edit" @click="UpdateClick1(scope.row)" type="primary">修改商终号</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
            class="fenye"
            @current-change="handleCurrentChange"
            :current-page="option.page"
            :page-size="8"
            prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
            next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
            background
            layout="total, prev, pager, next, jumper"
            :total="data.total"
    >
    </el-pagination>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog
            width="52%"
            title="上传图片"
            v-model="ruleForm.dialogFormVisible"
            :show-close=true
    >
      <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleFormRef"
              label-width="100px"
              class="demo-ruleForm"
      >

         <!-- <el-form-item prop="id" label="id" v-if="false">  <el-form-item> &lt;!&ndash;隐藏列&ndash;&gt;
          <el-table-column prop="shopID" label="shopID" v-if="false"> </el-table-column> &lt;!&ndash;隐藏列&ndash;&gt;
          <el-table-column prop="regionID" label="regionID" v-if="false"> </el-table-column> &lt;!&ndash;隐藏列&ndash;&gt;
          <el-table-column prop="cityID" label="cityID" v-if="false"> </el-table-column> &lt;!&ndash;隐藏列&ndash;&gt;
          <el-table-column prop="shopName" label="门店名称"> </el-table-column>
          <el-table-column prop="address" label="地址"> </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column prop="status" label="是否启用"> </el-table-column>-->
          <el-form-item label="广告图片">
            <el-upload ref="myupload"
                       action=""
                       :file-list="ruleForm.fileList"
                       :on-preview="handlePreview"
                       :on-remove="handleRemove"
                       :beforeUpload="beforeUpload"
                       :http-request="handleUpload"
                       :multiple="false"
                       :limit=1
                       accept=".png,.jpg"
            >
              <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
          </el-form-item>
        <div v-if="ruleForm.imageDiv">
          <el-image :src="ruleForm.headImgURL" :fit="fit"></el-image>
          <el-button @click="imageClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
        width="52%"
        title="修改商终号"
        v-model="ruleForm.dialogFormVisible1"
        :show-close=true
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleFormRef"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item prop="merchantNo" label="商户号:">
              <el-input v-model="ruleForm.merchantNo" placeholder="请输入商户号"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="termNo" label="终端号:">
              <el-input v-model="ruleForm.termNo" placeholder="请输入终端号"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm1('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm1('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
  import { reactive,ref} from "vue";
  import {
    PageShopList,
    getCityList,
    UploadHeadImg,
    getShopList, UpdateTermNo
  } from "../http/api";
  import { ElMessage } from "element-plus";
  import { putToOSS, getFileNameUUID,getFileExtensions } from '@/http/oss'
  export default {
    name: "CityBanner",
    setup() {
      const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的

      //删除上传的图片
      let imageClose = ()=>{
        ruleForm.headImgURL ="";
        ruleForm.imageDiv = false;
      }
      //修改的表单参数//////////////////////////////////////////////////
      let ruleForm = reactive({
        dialogFormVisible: false,
        dialogFormVisible1:false,
        id: "",
        shopName:"",
        DisplayDate:"",
        CityID:"",
        shopID:"",
        address:"",
        bannerMemo:"",
        addOrUpdate:1, //0新增，1更新
        imageDiv:false,
        headImgURL:"",
        merchantNo:"",
        termNo:""
      });

          //城市下拉框改变时,门店下拉都要改变
      let changeCity = async (cityID)=>{
        let shopList = await getShopList({cityid:cityID});
        data.shopListData = shopList;
      }


     /* //点击新增按钮
      let AddClick = async () => {
        ruleForm.addOrUpdate=0;
        ruleForm.dialogFormVisible = true; //显示出表单
        ruleForm.id="";
        ruleForm.bannerTitle = "";
        ruleForm.DisplayDate = "";
        ruleForm.bannerSort = "";
        ruleForm.bannerMemo = "";
      };*/

      //点击修改按钮,弹框，默认把原来的数据显示出来
      let UpdateClick = async (obj) => {
        console.log(obj,"obj")
        ruleForm.addOrUpdate=1;
        ruleForm.id = obj.id;
        ruleForm.DisplayDate = obj.displayDate;
        ruleForm.CityID = obj.cityID;
        ruleForm.shopID = obj.shopID;
        ruleForm.address = obj.address;
        ruleForm.bannerMemo = obj.memo;
        //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
        /*try {
          let banner = await getShopById({ id: obj.id });

        }catch (e) {

        }*/
        ruleForm.dialogFormVisible = true; //显示出表单
      };
      let UpdateClick1= async (obj) => {
        console.log(obj,"obj")
        ruleForm.addOrUpdate=1;
        ruleForm.id = obj.id;
        ruleForm.DisplayDate = obj.displayDate;
        ruleForm.CityID = obj.cityID;
        ruleForm.shopID = obj.shopID;
        ruleForm.address = obj.address;
        ruleForm.bannerMemo = obj.memo;
        ruleForm.merchantNo = obj.merchantNo;
        ruleForm.termNo = obj.termNo;
        //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
        /*try {
          let banner = await getShopById({ id: obj.id });

        }catch (e) {

        }*/
        ruleForm.dialogFormVisible1 = true; //显示出表单
      };

      //修改的表单验证
      let rules = reactive({

      });

      //提交表单,修改学生信息
      let submitForm = async (formName) => {
        //console.log(formName);
        let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
        ruleFormRef.value.validate((valid) => {
          if (!valid) {
            con = true;
            console.log("下面的不执行了");
            return false;
          }
        });
        if (con) {
          return false;
        }

        if(ruleForm.addOrUpdate==1){ //更新
          let data={
            "shopID":ruleForm.shopID,
            "headImgURL": ruleForm.headImgURL,
          }
          await UploadHeadImg(data);
        }/*else {//新增
          let data={
            "cityId": option.cityId,
            "shopId": "",
            "title": ruleForm.bannerTitle,
            "sort": ruleForm.bannerSort,
            "displayDate": ruleForm.DisplayDate,
            "memo":  ruleForm.bannerMemo,
            "imageUrl": ruleForm.imageUrl,
            "isCity":true
          }
          await addBanner(data);
        }*/
        queryShopListFun(option);
        ruleFormRef.value.resetFields();
        //setTimeout(function () {
        ruleForm.dialogFormVisible = false;
        //}, 500);
      };
      //提交表单,修改学生信息
      let submitForm1 = async (formName) => {
        //console.log(formName);
        let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
        ruleFormRef.value.validate((valid) => {
          if (!valid) {
            con = true;
            console.log("下面的不执行了");
            return false;
          }
        });
        if (con) {
          return false;
        }

        if(ruleForm.addOrUpdate==1){ //更新
          let data={
            "shopID":ruleForm.shopID,
            "merchantNo": ruleForm.merchantNo,
            "termNo": ruleForm.termNo,
          }
          await UpdateTermNo(data);
        }
        queryShopListFun(option);
        //ruleFormRef.value.resetFields();
        //setTimeout(function () {
        ruleForm.dialogFormVisible1 = false;
        //}, 500);
      };

      //表单重置
      let resetForm = (formName) => {
        //console.log(formName);
        //console.log( ruleFormRef.value);
        ///////ruleFormRef.value.resetFields();

        //setTimeout(function () {
        ruleForm.dialogFormVisible = false;
        //}, 500);
      };
      //表单重置
      let resetForm1 = (formName) => {
        //console.log(formName);
        //console.log( ruleFormRef.value);
        ///////ruleFormRef.value.resetFields();

        //setTimeout(function () {
        ruleForm.dialogFormVisible1 = false;
        //}, 500);
      };
      //声明数据
      let data = reactive({
        table: [],
        pages: 0,
        total: 0,
        shopListData: [],
        cityListData:[],
        srcList:[""]
      });

      //声明查询条件
      let option = reactive({
        CityID: "",
        /*shopId: "",*/
        ShopName:"",
        /*iscity:true,
        isshop:false,*/
        page: 1,
        pageSize: 8,
      });

        
      let indexMethod =(index) => {
        return index+(option.page-1)*option.pageSize+1;
      } 


      let queryShopListFun = async (obj) => {
        let arr = await PageShopList(obj);
        data.total = arr.totalCount;
        
        arr.items.filter(item => {
          item.status = item.status == 1? '是':'否';
        })

        data.table = arr.items;
      };
      //获取城市列表方法
      let getCityListDataFun = async (obj) => {
        let cityList = await getCityList(obj);
        data.cityListData = cityList;
      };
      //初始化时，获取初始化数据
      queryShopListFun(option);
      getCityListDataFun({ });
      //点击页码获取数据
      let handleCurrentChange = (index) => {
        //接口是接收的guid,这样写不报错
        if (option.CityID == "") {
          option.CityID = null;
        }
        if (option.shopID == "") {
          option.shopID = null;
        }
        option.page = index;
        queryShopListFun(option);
      };
      //查询方法,此处需要根据条件到后台进行筛选
      let getTabList = async () => {
        if (option.CityID == "") {
          option.CityID = null;
        }
        if (option.shopID == "") {
          option.shopID = null;
        }
        queryShopListFun(option);
      };
      //大图
      let bigImg=(url)=> {
        data.srcList[0] = url;
      };
      let beforeUpload=(file) =>{
        // 限制上传类型
        const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
        //限制的上限为2M
        const max2M = file.size / 1024 / 1024 < 2;
        if (!fileExtensions) {
          ElMessage.error({
            message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
            duration: 2000
          });
        }
        if (!max2M) {
          ElMessage.error({
            message: "上传文件大小不能超过 2MB!",
            duration: 2000
          });
        }
        return fileExtensions && max2M;
      };
      let handleUpload =(option)=> {
        ruleForm.addOrUpdate=1;
        // 生成的文件名，保留文件后缀名，进行拼接
        let objName ="GoodsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)

        // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
        putToOSS(`flieName/${objName}`, option.file).then(res => {
          //console.log(res,'res')
          // 上传成功之后，转换真实的地址
          //signatureUrl(`flieName/${objName}`).then(res => {
          //console.log(res)
          ruleForm.headImgURL=res.url;
          ruleForm.imageDiv =true;
          //})
        })
      };
      let submitUpload=()=>{
        this.$refs.upload.submit();
      };
      let handleRemove=(file, fileList)=> {
        console.log(file, fileList);
      };
      let handlePreview=(file)=>{
        console.log(file);
      };

      return {
        data,
        handleCurrentChange,
        getTabList,
        option,
        UpdateClick,
        UpdateClick1,
        ruleForm,
        ruleFormRef,
        rules,
        submitForm,
        resetForm,
        submitForm1,
        resetForm1,
        // AddClick,
        imageClose,
        getFileExtensions,
        beforeUpload,
        handleUpload,
        submitUpload,
        handleRemove,
        handlePreview,
        bigImg,
        changeCity,
        indexMethod
      };
    },
  };
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
  .box{

  }
  .el-image-viewer__close {
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color:rgba(255, 255, 255, 0)!important;
  }
  .select-box {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    > div {
      width: 15%;
      display: flex;
      align-items: center;
      span {
        width: 50px;
      }
      margin-right: 25px;
    }
  }
  .fenye {
    margin-top: 20px;
  }
</style>
