<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>城市:</span>
          <el-select v-model="option.cityId" clearable placeholder="请选择城市" @change="getTabList">
            <el-option v-for="item in data.cityListData" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">新增城市广告</el-button>
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="title" label="广告标题"> </el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column> <!--可以格式化-->
      <el-table-column prop="isActive" label="是否启用"> </el-table-column>
      <el-table-column prop="displayDate" label="显示时间"> </el-table-column>
      <el-table-column label="图片" prop="imageUrl" align="center" width="100px">
        <template v-slot="scope">
          <el-image @click="bigImg(scope.row.imageUrl)" style="width: 50px; height: 50px; margin-bottom:-4px"
              :src=scope.row.imageUrl
              :preview-src-list="data.srcList">
          </el-image>
          <el-popover placement="top-start" trigger="click"> <!--trigger属性值：hover、click、focus 和 manual-->
            <a :href="scope.row.imageUrl" target="_blank" title="查看最大化图片">
              <img :src="scope.row.imageUrl" style="width: 300px;height: 300px">
            </a>
            <img :src="scope.row.imageUrl" style="width: 50px;height: 50px; cursor:pointer">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="300"> <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small" icon="el-icon-edit" @click="UpdateClick(scope.row)" type="primary">修改</el-button>  <!--@click="UpdateClick(scope.row)" -->
            <el-popconfirm title="您确定要删除吗？"    confirm-button-text='是'
            cancel-button-text='否' iconColor="red" @confirm="deleteClick(scope.row)">
            <template #reference>
              <el-button size="small" icon="el-icon-delete"  type="danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button @click="ViewDescImg(scope.row)" type="primary" size="small">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="8"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog
      width="52%"
      title="城市广告信息"
      v-model="ruleForm.dialogFormVisible"
      :show-close=true
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题"  required>
            <el-col :span="11">
              <el-form-item prop="bannerTitle">
                <el-input v-model="ruleForm.bannerTitle" clearable  placeholder="标题如（新年大促）" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4"> &nbsp;&nbsp;&nbsp;&nbsp;排序&nbsp;&nbsp;&nbsp;</el-col>
            <el-col :span="11">
              <el-form-item prop="bannerSort">
                <el-input v-model="ruleForm.bannerSort" type="number" placeholder="数字（从小到大）" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
        </el-form-item>
        <el-form-item label="时间" required>
          <el-col :span="11">
            <el-form-item prop="DisplayDate">
              <el-date-picker
                  v-model="ruleForm.DisplayDate"
                  type="datetime"
                  value-format="YYYY-MM-DDTHH:mm:ss"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="备注"  >
            <el-col :span="11">
              <el-form-item prop="bannerMemo">
                <el-input v-model="ruleForm.bannerMemo" clearable  placeholder="备注信息" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
        </el-form-item>
        <el-upload ref="myupload"
           action=""
           :file-list="ruleForm.fileList"
           :on-preview="handlePreview"
           :on-remove="handleRemove"
           :beforeUpload="beforeUpload"
           :http-request="handleUpload"
           :multiple="false"
           :limit=1
           accept=".png,.jpg"
          >
          <el-button size="small" type="primary">上传广告图片</el-button>
        </el-upload>
        <el-col>
          <p style="color: red;padding-bottom: 20px">* 图片格式 宽：720px；高：1092px；要展示的内容需放在距离图片顶端670高度内；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
        </el-col>
        <div v-if="ruleForm.imageDiv">
          <el-image :src="ruleForm.imageUrl" :fit="fit"></el-image>
          <el-button @click="imageClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive,ref} from "vue";
import {
  getBannerList,
  getCityList,
  updateBanner,
  addBanner,
  delBanner,
  getBannerById
} from "../http/api";
import { ElMessage } from "element-plus";
import {FullTimeFormat} from '../utils/common'
import { putToOSS, getFileNameUUID,getFileExtensions } from '@/http/oss'
import { useRouter } from "vue-router";
export default {
  name: "CityBanner",
  setup() {
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    const router = useRouter();
    let ViewDescImg = (obj) => {
      router.push({name: "BannerDescImg", params: {id: obj.id}})
      //router.push({ path: `/GoodsDescImg/${obj.id}` });
    };
    //删除事件
    let deleteClick = async (obj) => {
      let data = {"id": obj.id};
      let delResult = await delBanner(data);
      queryBannerListFun(option);
      if(delResult){
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      }else{
          ElMessage.error({
          message: "删除失败！",
          duration: 2000
        });
      }
    };
    //删除上传的图片
    let imageClose = ()=>{
      ruleForm.imageUrl="";
      ruleForm.imageDiv = false;
    }
    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      bannerTitle:"",
      DisplayDate:"",
      cityId:"",
      shopId:"",
      bannerSort:"0",
      bannerMemo:"",
      addOrUpdate:0, //0新增，1更新
      imageDiv:false,
      imageUrl:"",
    });

   //点击新增按钮
    let AddClick = async () => {
      ruleForm.addOrUpdate=0;
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.id="";
      ruleForm.bannerTitle = "";
      ruleForm.DisplayDate = "";
      ruleForm.bannerSort = "0";
      ruleForm.bannerMemo = "";
    };

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let banner = await getBannerById({ id: obj.id });
      ruleForm.bannerTitle = banner.title;
      ruleForm.DisplayDate = banner.displayDate;
      ruleForm.cityId = banner.cityID;
      ruleForm.shopId = banner.shopID;
      ruleForm.bannerSort = banner.sort;
      ruleForm.bannerMemo = banner.memo;
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //修改的表单验证
    let rules = reactive({
      bannerTitle: [
        { required: true, message: "请输入标题" }
      ],
      DisplayDate: [
        { required: true, message: "请输入开始显示时间" }
      ],
      bannerSort: [{ required: true, message: "请输入排序" }],
      bannerMemo: [{ required: false, message: "请输入备注" }],
    });

    //提交表单,修改学生信息
    let submitForm = async (formName) => {
      //console.log(formName);
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }

      if(ruleForm.addOrUpdate==1){ //更新
        let data={
          "id":ruleForm.id,
          "cityId": ruleForm.cityId,
          "shopId": "",
          "title": ruleForm.bannerTitle,
          "sort": ruleForm.bannerSort,
          "displayDate": ruleForm.DisplayDate,
          "memo":  ruleForm.bannerMemo,
          "imageUrl": ruleForm.imageUrl,
          "isCity":true,
          "detail":'',
          "isActive":true
        }
        await updateBanner(data);
      }else {//新增
        let data={
          "cityId": option.cityId,
          "shopId": "",
          "title": ruleForm.bannerTitle,
          "sort": ruleForm.bannerSort,
          "displayDate": ruleForm.DisplayDate,
          "memo":  ruleForm.bannerMemo,
          "imageUrl": ruleForm.imageUrl,
          "isCity":true,
          "detail":'',
          "isActive":true
        }
        await addBanner(data);

        ruleForm.imageUrl = '';
        ruleForm.imageDiv = false;
        ruleForm.fileList = []
      }
      queryBannerListFun(option);
      ruleFormRef.value.resetFields();
      //setTimeout(function () {
      ruleForm.dialogFormVisible = false;
      //}, 500);
    };

    //表单重置
    let resetForm = (formName) => {
      //console.log(formName);
      //console.log( ruleFormRef.value);
      ///////ruleFormRef.value.resetFields();

    //setTimeout(function () {
      ruleForm.dialogFormVisible = false;
    //}, 500);
    };
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      cityListData:[],
      srcList:[""]
    });

    //声明查询条件
    let option = reactive({
      cityId: "",
      shopId: "",
      iscity:true,
      isshop:false,
      page: 1,
      pageSize: 8,
    });


    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    }

    let queryBannerListFun = async (obj) => {
      let arr = await getBannerList(obj);
      data.total = arr.totalCount;

      //格式化日期
      arr.items.filter(item => {
        item.displayDate = FullTimeFormat(item.displayDate);
        item.isActive = item.isActive == true? '是':'否';
      })

      data.table = arr.items;
    };
    //获取城市列表方法
    let getCityListDataFun = async (obj) => {
      let cityList = await getCityList(obj);
      data.cityListData = cityList;
    };
    //初始化时，获取初始化数据
    queryBannerListFun(option);
    getCityListDataFun({ });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      //接口是接收的guid,这样写不报错
      if (option.cityId == "") {
        option.cityId = null;
      }
      if (option.shopId == "") {
        option.shopId = null;
      }
      option.page = index;
      queryBannerListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      if (option.cityId == "") {
        option.cityId = null;
      }
      if (option.shopId == "") {
        option.shopId = null;
      }
      queryBannerListFun(option);
    };
    //大图
    let bigImg=(url)=> {
      data.srcList[0] = url;
    };
    let beforeUpload=(file) =>{
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) ==='.jpeg'
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000
        });
      }
      return fileExtensions && max2M;
    };
    let handleUpload=(option)=> {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName ="GoodsImg/"+ getFileNameUUID() + getFileExtensions(option.file.name)
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then(res => {
        //console.log(res,'res')
        // 上传成功之后，转换真实的地址
        //signatureUrl(`flieName/${objName}`).then(res => {
          //console.log(res)
          ruleForm.imageUrl=res.url;
          ruleForm.imageDiv =true;
        //})
      })
    };
    let submitUpload=()=>{
      this.$refs.upload.submit();
    };
    let handleRemove=(file, fileList)=> {
      console.log(file, fileList);
    };
    let handlePreview=(file)=>{
      console.log(file);
    };

    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      ruleForm,
      ruleFormRef,
      rules,
      submitForm,
      resetForm,
      AddClick,
      deleteClick,
      imageClose,
      getFileExtensions,
      beforeUpload,
      handleUpload,
      submitUpload,
      handleRemove,
      handlePreview,
      bigImg,
      indexMethod,
      ViewDescImg
    };
  }
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.box{

}
.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color:rgba(255, 255, 255, 0)!important;
}
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 50px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
