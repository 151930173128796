<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>用户名:</span>
        <el-input
          v-model="option.userName"
          clearable
          placeholder="请输入用户名"
        ></el-input>
      </div>

      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
        <el-button
            @click="AddClick()"
            icon="el-icon-document-add"
            type="primary"
            >新增</el-button
          >
      </div>
    </div>

    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column>
      <!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column  prop="userName" label="用户名"> </el-table-column>
      <el-table-column prop="realName" label="姓名"></el-table-column>

      <el-table-column fixed="right" width="200px" label="操作">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
            @click="UpdateClick(scope.row)"
            size="small"
            icon="el-icon-edit"
            type="primary"
            >修改</el-button
          >

            <el-popconfirm
            title="您确定要删除吗？"
            iconColor="red"
            confirm-button-text='是'
            cancel-button-text='否'
            @confirm="deleteClick(scope.row)"
          >
            <template #reference>
              <el-button size="small" icon="el-icon-delete"  type="danger">
                删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>

      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog
      width="40%"
      title="用户信息"
      v-model="ruleForm.dialogFormVisible"
      :show-close=true
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名"  prop="userName" required>
          <el-input :disabled="ruleForm.addOrUpdate==1"  v-model="ruleForm.userName"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="passWord">
          <el-input type="password"  :placeholder="ruleForm.placePwd" v-model="ruleForm.passWord"></el-input>
        </el-form-item>

        <el-form-item label="姓名"  prop="realName" required>
          <el-input   v-model="ruleForm.realName" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
          <!-- @click="btnSubmit"  ruleForm.dialogFormVisible = false-->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import {
  getBackUserListPage,
  getBackUserById,
  updateBackUser,
  addBackUser,
  repeatUserName,
  delBackUser
} from "../http/api";

import { ElMessage } from "element-plus";
import Cookies from 'js-cookie'

export default {
  name: "BackUserList",
  setup() {

    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    onMounted(() => {
      console.log(ruleFormRef.value);
    });

    //声明数据table表格的内容///////////////////////////////////
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
    });

    //声明查询条件
    let option = reactive({
      userName: "",
      page: 1,
      pageSize: 10,
    }); 

    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    } 

    //获取后台用户的列表方法
    let getBackUserListFun = async (obj) => {
      let arr = await getBackUserListPage(obj);
      data.total = arr.totalCount;
      console.log(data.total + "总条数");
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    getBackUserListFun({ Page: 1, PageSize: 10 });

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getBackUserListFun(option);
    };

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      let token = Cookies.get('name');
      console.log(token);
      getBackUserListFun(option);
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      userName: "",
      realName: "",
      passWord:"",
      addOrUpdate:0 ,//0新增，1更新
      placePwd:"请输入密码"
    });

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      ruleForm.placePwd="不输入就是原来的密码";
      console.log(obj.id);
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let backUser = await getBackUserById({ id: obj.id });
      console.log(backUser);
      ruleForm.userName = backUser.userName;
      ruleForm.realName = backUser.realName;
      ruleForm.passWord = backUser.passWord;

      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //点击新增按钮
    let AddClick = async () => {
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleFormRef.value.resetFields();
      ruleForm.addOrUpdate=0;
      ruleForm.placePwd="请输入密码";
      ruleForm.userName = "";
      ruleForm.realName = "";
      ruleForm.passWord = "";
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //修改的表单验证
    let rules = reactive({
      userName: [
        { required: true, message: "请输入用户名" }
      ],
      realName: [
        { required: true, message: "请输入姓名" } 
      ],
    //   passWord: [
    //     { required: true, message: "密码至少包含大小写字母，数字，特殊字符" } 
    //   ]
 
    });

    //提交表单,新增或修改后台用户信息
    let submitForm = async (formName) => {
      console.log(formName);
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      if(ruleForm.addOrUpdate==1){//更新
        let data = new FormData();
        data.append("userName", ruleForm.userName);
        if(typeof(ruleForm.passWord) == "undefined"){
          data.append("passWord", "old");
        }else{
          data.append("passWord", ruleForm.passWord);
        }
        
        data.append("realName", ruleForm.realName);
        data.append("backTokens",Cookies.get('name'));
        //不填密码的话，就用原来的密码

        if(typeof(ruleForm.passWord) == "undefined"){
            await updateBackUser(data);
        }else{ //填写密码就要判断密码格式
            const regexWidthCharRange = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[`~!@#$%^&*()_\-+=<>.?:"{}].*).{6,20}$/; 
            let testResult = regexWidthCharRange.test(ruleForm.passWord); 
            if(testResult)
            {
                await updateBackUser(data);
            }else{
                ElMessage.error({
                    message: '密码必须包含大小写字母，数字，特殊字符，长度在6到20位',
                    type: "error",
                    offset:130,
                    duration:3000
                });
                return;
            }
        }
        
      }else{//新增
        //判断用户名是否重复
        var isRepeat = await repeatUserName({userName:ruleForm.userName});
        if(isRepeat){
            ElMessage.error({
                message: '用户名重复！',
                type: "error",
                offset:130,
                duration:2000
            });
            return;
        }
        let data = new FormData();
        data.append("userName", ruleForm.userName);
        data.append("passWord", ruleForm.passWord);
        data.append("realName", ruleForm.realName);
        data.append("backTokens",Cookies.get('name'));
        const regexWidthCharRange = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[`~!@#$%^&*()_\-+=<>.?:"{}].*).{6,20}$/; 
            let testResult = regexWidthCharRange.test(ruleForm.passWord); 
            if(testResult)
            {
                await addBackUser(data);
            }else{
                ElMessage.error({
                    message: '密码必须包含大小写字母，数字，特殊字符，长度在6到20位',
                    type: "error",
                    offset:130,
                    duration:3000
                });
                return;
            }
        
      }
      getBackUserListFun(option);//重新加载数据
      /////////////ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };

    //删除事件
    let deleteClick = async (obj) => {
      
      let data = new FormData();
      data.append("id", obj.id);
      data.append("token", Cookies.get('name'));
      await delBackUser(data);
      getBackUserListFun(option);

      ElMessage.success({
        message: "删除成功！",
        duration: 2000,
        type: "success",
      });
    };


    //表单重置
    let resetForm = (formName) => {
      console.log(formName);
      //console.log( ruleFormRef.value);
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };

    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
      // ElMessage.error({
      //   message: '姓名不能为空',
      //   type: "error",
      //   offset:130,
      //   duration:1500
      // });
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      ruleForm,
      btnSubmit,
      rules,
      submitForm,
      resetForm,
      ruleFormRef,
      AddClick,
      deleteClick,
      indexMethod
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
