<template>
  <div>

    <div class="select-box">
      <!-- 1.筛选条件 -->
      <div >
        <span>卡号:</span>
        <el-input v-model="option.CardID"  clearable placeholder="请输入卡号"></el-input>
      </div>
      <div>
        <span>卡名:</span>
        <el-input v-model="option.CardName"  clearable placeholder="请输入卡名"></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
      </div>
    </div>
    <!--2. table表格的内容-->
    <el-table id="table" border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="cardID" label="卡号"> </el-table-column>
      <el-table-column prop="cardName" label="卡名"> </el-table-column>
      <el-table-column prop="code" label="编码"> </el-table-column>
      <el-table-column prop="standardCost" label="标准价(元)"> </el-table-column>
      <el-table-column prop="lowestPrice" label="最低价(元)"></el-table-column>
      <el-table-column prop="validMonth" label="有效期(月)"> </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template #default="scope">
          <el-button @click="showForm(scope.row)"  size="small" icon="el-icon-edit" type="primary">发布</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="8"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 弹窗 -->
    <el-dialog
      width="52%"
      title="卡次信息"
      v-model="ruleForm.dialogFormVisible"
      :show-close=true
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="*城市" >
          <el-col  :span="11">
            <el-select v-model="ruleForm.cityId"  clearable placeholder="请选择城市" @change="changeCity">
              <el-option v-for="item in data.cityListData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="4">  *门店  </el-col>
          <el-col :span="11">
            <el-select
                v-model="ruleForm.shopId"
                multiple
                collapse-tags
                v-bind="$attrs"
                v-on="$listeners"
            >
              <el-checkbox
                  v-model="check"
                  class="m-l-20"
                  :indeterminate="ruleForm.shopId.length !== data.shopListData.length"
                  @change="selectAll"
              >
                全选
              </el-checkbox>
              <el-option
                  v-for="item in data.shopListData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="*商品分类" >
            <el-col  :span="11">
              <el-select v-model="ruleForm.categoryID" placeholder="请选择商品分类">
                <el-option v-for="item in data.categoryListData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-col>
            <el-col class="line" :span="4"> &nbsp;&nbsp;&nbsp;&nbsp;虚拟数量&nbsp;&nbsp;&nbsp;</el-col>
            <el-col :span="11">
              <el-form-item prop="bannerSort">
                <el-input v-model="ruleForm.virtualAmount" type="number" placeholder="数字" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
        </el-form-item>

        <el-form-item label="备注"  >
            <el-col :span="11">
              <el-form-item prop="bannerMemo">
                <el-input v-model="ruleForm.remark" clearable  placeholder="备注信息" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
        </el-form-item>
        <div v-if="ruleForm.imageDiv">
          <el-image :src="ruleForm.imageUrl" :fit="fit"></el-image>
          <el-button @click="imageClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="ToGoods('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive,computed } from "vue";
import { getNumberCardList } from "@/http/numbercard";
import { createGoods} from "@/http/goods";
import { getCategoryList } from "@/http/goods";
import {
  getCityList,
  getShopList
} from "../http/api";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
export default {
  name: "NumberCard",
  setup() {
    const router = useRouter();
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      shopListData: [],
      cityListData:[],
      categoryListData:[]
    });
    let ViewListImg = (obj) => {
      router.push({name: "NumberCardImg", params: {id: obj.id}})
      //router.push({ path: `/GoodsImg/${obj.id}` });
    };
    let ViewDescImg = (obj) => {
      router.push({name: "NumberCardDescImg", params: {id: obj.id}})
      //router.push({ path: `/GoodsDescImg/${obj.id}` });
    };
    //声明查询条件
    let option = reactive({
      page: 1,
      pageSize: 8,
      ShopName:'',
      startDay:'',
      endDay:'',
      visible: true
    });
    let check=computed({
      get() {
        if (ruleForm.shopId.length === data.shopListData.length) {
          return true
        }
        return false
      },
      set() {}
    });
    let selectAll=  (checked)=> {
      if (checked) {
        ruleForm.shopId = data.shopListData.map(d => d.id);
      } else {
        ruleForm.shopId = [];
      }
    };
    let changeCity = async (cityID)=>{
      let shopList = await getShopList({cityid:cityID});
      data.shopListData = shopList;
      ruleForm.shopId=[];
    }
    //获取城市列表方法
    let getCityListDataFun = async (obj) => {
      var cityList = await getCityList(obj);
      data.cityListData = cityList;
      //console.log(data.cityListData);
      //getCurrentSemesterFun();
    };
    getCityListDataFun({ });
    let getCategoryListDataFun = async (obj) => {
      let categoryList = await getCategoryList(obj);
      data.categoryListData = categoryList;
    };

    //获取订单列表方法
    let getNumberCardListFun = async (obj) => {
      let arr = await getNumberCardList(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    getNumberCardListFun({ Page: 1, PageSize: 8,payStatus:3 });
    getCategoryListDataFun({ });

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getNumberCardListFun(option);
    };

    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    }

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      option.page=1;
      getNumberCardListFun(option);
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      categoryID:"",
      virtualAmount: 0,
      remark: "",
      cityId:"",
      shopId:[],
      isFixGroupCard: false
    });

    //表单重置
    let resetForm = (formName) => {
      ruleForm.dialogFormVisible = false;
      ruleForm.virtualAmount = '';
      ruleForm.remark = '';
      ruleForm.categoryID = '';
      ruleForm.shopId = [];
    };

    let showForm = (obj) => {
      ruleForm.dialogFormVisible = true;
      ruleForm.cardID = obj.cardID;
      ruleForm.cardName = obj.cardName;
      ruleForm.totalCountCard = obj.totalCountCard;
      ruleForm.standardCost = obj.standardCost;
      ruleForm.isFixGroupCard = obj.isFixGroupCard;
      ruleForm.ruleForm = obj.limitItemFlag;
      ruleForm.lowestPrice = obj.lowestPrice;
    }

    let ToGoods = async (formName) => {
      let params={
          shopID: ruleForm.shopId.join(","),
          categoryID: ruleForm.categoryID,
          itemID: ruleForm.cardID,//
          goodsType: 2,
          goodsName: ruleForm.cardName,//
          totalCountCard: ruleForm.totalCountCard,
          summary: "",
          description: "",
          detailTopPic: "",
          listPic: "",
          price: ruleForm.standardCost,//
          virtualAmount: ruleForm.virtualAmount,
          isFixGroupCard: ruleForm.isFixGroupCard,
          limitItemFlag: ruleForm.ruleForm,
          remark: ruleForm.remark,
          lowestPrice:ruleForm.lowestPrice
      };
      console.log(params);

      let result=await createGoods(params);
      ElMessage.error({
        message: result.message,
        duration: 2000
      });
      resetForm();
      //console.log(result);
    };


    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      ToGoods,
      ruleForm,
      ViewListImg,
      ViewDescImg,
      resetForm,
      showForm,
      indexMethod,
      changeCity,
      selectAll,
      check
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 20%;
    display: flex;
    align-items: center;
    span {
      width: 120px;
    }
    margin-right: 20px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
