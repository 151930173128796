<template>
  <div>
    <div class="select-box">
  
        <!-- <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleFormRef"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="客服电话" prop="phoneNumber" >
            <el-input v-model="ruleForm.phoneNumber" style="margin-right: 80px"></el-input>
            <el-button type="primary" @click="submitForm('ruleForm')" >保存修改</el-button>
          </el-form-item>

          
        </el-form> -->
        <div>
          <span>客服电话：</span>
          <el-input v-model="ruleForm.phoneNumber" style="margin-right: 80px" ></el-input>
        </div>
        <div>
          <el-button type="primary" @click="submitForm('ruleForm')" >保存修改</el-button>
        </div>
    </div>
  </div>
     
</template>

<!-- 本页的js -->
<script>
import { ref, reactive } from "vue";
import {
  getServicePhoneDate,
  updateServicePhoneDate
} from "../http/api";

import { ElMessage } from "element-plus";

export default {
  name: "ServicePhone",
  setup() {
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    // onMounted(() => {
    //   console.log(ruleFormRef.value);
    // });

    //修改的表单参数         
    let ruleForm = reactive({
      id:"",
      phoneNumber: ""
    });
    let getServicePhoneDateFun  = async () => {
        let ServicePhoneDate = await getServicePhoneDate();
        ruleForm.phoneNumber = ServicePhoneDate[0].phoneNumber;
        ruleForm.id = ServicePhoneDate[0].id;
    };
    getServicePhoneDateFun(); //加载客服电话

    //提交表单,修改机构信息
    let submitForm = async (formName) => {
        //console.log(formName);
        if(ruleForm.phoneNumber == "" || ruleForm.phoneNumber == null || ruleForm.phoneNumber == undefined){
            ElMessage.error({
            message: '客服电话不能为空！',
            type: "error",
            offset:130,
            duration:1500
          });
          return false;
        }
        let data= {
          "id":ruleForm.id,
          "phoneNumber":ruleForm.phoneNumber
        }
        await updateServicePhoneDate(data);
        ElMessage.success({
        message: '保存成功！',
        type: "success",
        offset: 130,
        duration: 2000
      });
    };


    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
      // ElMessage.error({
      //   message: '姓名不能为空',
      //   type: "error",
      //   offset:130,
      //   duration:1500
      // });
    };
    return {
      ruleForm,
      btnSubmit,
      submitForm,
      ruleFormRef,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width:27%;
    display: flex;
    align-items: center;
    span {
      width: 170px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}

</style>
